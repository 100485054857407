import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { AlertCircle, ArrowLeft, Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useAuth } from "@/contexts/AuthContext";
import AgendeDivaLogo from "@/components/AgendeDivaLogo";

const carouselItems = [
  {
    title: "Transforme seu negócio de beleza",
    description: "Com o AgendeDiva, você terá acesso a ferramentas completas para organizar agendamentos, gerenciar clientes e aumentar seus resultados."
  },
  {
    title: "Organize seus agendamentos",
    description: "Tenha controle total da sua agenda, evite conflitos de horários e nunca mais perca um compromisso."
  },
  {
    title: "Fidelize seus clientes",
    description: "Mantenha um histórico completo dos seus clientes, preferências e serviços realizados para um atendimento personalizado."
  }
];

const Login = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Função para avançar para o próximo slide
  useEffect(() => {
    // Avança automaticamente o slide a cada 5 segundos
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
    }, 5000);
    
    // Limpar o intervalo quando o componente é desmontado
    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    
    // Validação básica
    if (!email.trim() || !password) {
      setErrorMessage("Por favor, preencha todos os campos.");
      return;
    }
    
    try {
      setLoading(true);
      await signIn(email, password);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("Erro ao fazer login. Tente novamente.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Lado esquerdo - Formulário */}
      <div className="w-full md:w-1/2 bg-white p-8 flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">
          <div className="flex items-center mb-8">
            <Link to="/" className="flex items-center text-gray-600 hover:text-diva-pink transition-colors">
              <ArrowLeft className="h-4 w-4 mr-1" />
              Voltar para página inicial
            </Link>
          </div>

          <div className="flex items-center gap-2 mb-8">
            <AgendeDivaLogo />
            <h1 className="text-xl font-bold text-gray-800">AgendeDiva</h1>
          </div>

          <h2 className="text-2xl font-bold text-gray-900 mb-2">Bem-vinda de volta</h2>
          <p className="text-gray-600 mb-6">
            Entre para acessar seu painel e gerenciar seu negócio
          </p>

          {errorMessage && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Falha no login</AlertTitle>
              <AlertDescription>
                {errorMessage}
              </AlertDescription>
            </Alert>
          )}
          
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="seu@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1"
              />
            </div>

            <div>
              <div className="flex items-center justify-between">
                <Label htmlFor="password">Senha</Label>
                <Link to="/forgot-password" className="text-sm text-diva-purple hover:text-diva-pink">
                  Esqueceu a senha?
                </Link>
              </div>
              <Input
                id="password"
                type="password"
                placeholder="Sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1"
              />
            </div>

            <div className="flex items-center">
              <Checkbox
                id="remember"
                checked={rememberMe}
                onCheckedChange={(checked) => setRememberMe(!!checked)}
                className="mr-2"
              />
              <Label htmlFor="remember" className="text-sm font-normal cursor-pointer">
                Lembrar meus dados
              </Label>
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-diva-pink to-diva-purple text-white py-2"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Entrando...
                </>
              ) : (
                'Entrar'
              )}
            </Button>

            <div className="text-center text-sm text-gray-600 mt-4">
              Ainda não tem uma conta?{" "}
              <Link to="/signup" className="text-diva-purple hover:text-diva-pink font-medium">
                Crie uma agora
              </Link>
            </div>
          </form>
        </div>
      </div>

      {/* Lado direito - Banner */}
      <div className="hidden md:block md:w-1/2 bg-gradient-to-br from-diva-pink to-diva-purple">
        <div className="h-full flex items-center justify-center p-8">
          <div className="text-white max-w-lg">
            <h2 className="text-3xl font-bold mb-4">Bem-vinda ao seu assistente de beleza digital</h2>
            <p className="text-lg mb-6">
              Gerencie seus agendamentos, clientes e finanças em um só lugar.
              Tudo o que você precisa para fazer seu negócio crescer.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
