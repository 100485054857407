import React from 'react';

interface AgendeDivaLogoProps {
  className?: string;
}

const AgendeDivaLogo: React.FC<AgendeDivaLogoProps> = ({ className }) => {
  return (
    <div className={`relative w-10 h-10 ${className || ''}`}>
      <div className="absolute inset-0 bg-gradient-to-r from-diva-pink to-diva-purple rounded-full opacity-80"></div>
      <div className="absolute inset-1 bg-white rounded-full flex items-center justify-center">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple font-serif font-bold text-xl">D</span>
      </div>
    </div>
  );
};

export default AgendeDivaLogo;
