import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import AgendeDivaLogo from "@/components/AgendeDivaLogo";
import { ArrowLeft } from "lucide-react";
import { authService } from "@/services/authService";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await authService.sendPasswordResetEmail(email);
      setIsEmailSent(true);
      toast({
        title: "Email enviado com sucesso!",
        description: "Verifique sua caixa de entrada para redefinir sua senha.",
      });
    } catch (error) {
      toast({
        title: "Erro ao enviar email",
        description: "Não foi possível enviar o email de redefinição. Tente novamente.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Left side - Form */}
      <div className="w-full md:w-1/2 p-6 md:p-10 flex items-center justify-center">
        <div className="w-full max-w-md">
          {/* Botão Voltar */}
          <Link
            to="/login" 
            className="inline-flex items-center text-diva-dark/70 hover:text-diva-pink mb-8 transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-1" />
            Voltar para login
          </Link>

          <div className="flex items-center gap-2 mb-10">
            <AgendeDivaLogo />
            <h1 className="text-2xl font-bold text-diva-dark font-serif">AgendeDiva</h1>
          </div>

          <div className="mb-10">
            <h1 className="text-3xl font-bold font-serif text-diva-dark mb-2">
              Esqueceu sua senha?
            </h1>
            <p className="text-diva-dark/70">
              Digite seu email para receber um link de redefinição de senha
            </p>
          </div>

          {isEmailSent ? (
            <div className="text-center space-y-4">
              <div className="p-4 bg-green-50 text-green-800 rounded-lg">
                <h3 className="font-medium">Email enviado!</h3>
                <p className="text-sm">
                  Verifique sua caixa de entrada e siga as instruções para redefinir sua senha.
                </p>
              </div>
              <Button
                type="button"
                variant="outline"
                className="w-full"
                onClick={() => setIsEmailSent(false)}
              >
                Enviar novamente
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="seu@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="border-diva-pink/20 focus:border-diva-pink"
                />
              </div>

              <Button
                type="submit"
                className="w-full bg-gradient-to-r from-diva-pink to-diva-purple"
                disabled={isLoading}
              >
                {isLoading ? "Enviando..." : "Enviar link de redefinição"}
              </Button>
            </form>
          )}
        </div>
      </div>

      {/* Right side - Image */}
      <div className="hidden md:block md:w-1/2 bg-gradient-to-br from-diva-pink to-diva-purple relative overflow-hidden">
        <div className="absolute inset-0 flex items-center justify-center p-10">
          <div className="bg-white/5 backdrop-blur-sm rounded-[20px] p-8 w-[500px] text-center shadow-lg">
            <h2 className="text-4xl font-bold text-white mb-4 font-serif">
              Recupere seu acesso
            </h2>
            <p className="text-white text-lg mb-6">
              Não se preocupe! Vamos te ajudar a recuperar o acesso à sua conta para que você possa
              voltar a gerenciar seus agendamentos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword; 