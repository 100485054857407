import { useState } from 'react';
import { Service } from '@/types/appointment';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import { serviceService } from '@/services/serviceService';
import { CurrencyInput } from '@/components/ui/currency-input';
import { X } from 'lucide-react';

interface ServiceFormDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  service?: Service;
  onSuccess: (service: Service) => void;
  professionalId: string;
  lastCreatedService?: Partial<Service> | null;
}

export function ServiceFormDialog({
  open,
  onOpenChange,
  service,
  onSuccess,
  professionalId,
  lastCreatedService
}: ServiceFormDialogProps) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  
  // Usar dados do último serviço criado se disponível e não estiver editando
  const [formData, setFormData] = useState<Partial<Service>>(() => {
    if (service) {
      // Garantir que todos os campos do serviço existente são copiados corretamente
      return {
        ...service,
        duration_minutes: service.duration_minutes || 30, // Garantir que duration_minutes está presente
      };
    } else if (lastCreatedService) {
      return {
        name: '',
        description: lastCreatedService.description || '',
        duration_minutes: lastCreatedService.duration_minutes || 30,
        price: lastCreatedService.price || 0,
        active: true,
        professional_id: professionalId
      };
    } else {
      return {
        name: '',
        description: '',
        duration_minutes: 30,
        price: 0,
        active: true,
        professional_id: professionalId
      };
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Validação dos dados
      if (!formData.name?.trim()) {
        throw new Error('O nome do serviço é obrigatório');
      }

      if (!formData.duration_minutes || formData.duration_minutes < 1) {
        throw new Error('A duração deve ser maior que 0');
      }

      if (formData.price === undefined || formData.price < 0) {
        throw new Error('O preço deve ser maior ou igual a 0');
      }

      // Prepara os dados para envio
      const serviceData = {
        name: formData.name.trim(),
        description: formData.description?.trim() || '',
        duration_minutes: Math.round(formData.duration_minutes), // Garante que seja um número inteiro
        price: Number(formData.price?.toFixed(2) || 0), // Garante 2 casas decimais
        active: formData.active !== undefined ? formData.active : true,
        professional_id: professionalId
      };

      console.log('Enviando dados:', serviceData);

      if (service) {
        // Ao editar, enviamos apenas o ID e os dados modificados
        await serviceService.update(service.id, serviceData);
        toast({
          title: 'Serviço atualizado',
          description: 'O serviço foi atualizado com sucesso!'
        });
      } else {
        await serviceService.create(serviceData);
        toast({
          title: 'Serviço criado',
          description: 'O serviço foi criado com sucesso!'
        });
      }
      
      // Para garantir que temos todos os dados para passar para o callback de sucesso
      const resultService = {
        id: service?.id || 'temp-id',
        ...serviceData,
        created_at: service?.created_at || new Date().toISOString(),
        updated_at: new Date().toISOString()
      } as Service;
      
      onSuccess(resultService);
      onOpenChange(false);
    } catch (error) {
      console.error('Erro ao salvar serviço:', error);
      toast({
        title: 'Erro',
        description: error instanceof Error ? error.message : 'Ocorreu um erro ao salvar o serviço.',
        variant: 'destructive'
      });
    } finally {
      setLoading(false);
    }
  };

  // Função auxiliar para formatar a duração sem zeros à esquerda
  const formatDuration = (value: string) => {
    // Remove zeros à esquerda
    return value.replace(/^0+/, '');
  }

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remova o zero à esquerda se existir
    const inputValue = e.target.value;
    const cleanedValue = formatDuration(inputValue);
    
    // Se o campo estiver vazio, defina como vazio em vez de 0
    const numericValue = cleanedValue === '' ? '' : Number(cleanedValue);
    
    setFormData({ ...formData, duration_minutes: numericValue as unknown as number });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[520px] p-0 overflow-hidden rounded-xl bg-white shadow-lg border-0">
        <div className="flex justify-between items-center p-6 pb-4 border-b bg-gradient-to-r from-diva-pink-light to-diva-purple-light/50">
          <div>
            <DialogTitle className="text-2xl font-bold text-diva-dark">
              {service ? 'Editar Serviço' : 'Novo Serviço'}
            </DialogTitle>
            <DialogDescription className="text-diva-purple mt-1">
              {service 
                ? 'Atualize as informações do serviço conforme necessário.'
                : 'Preencha as informações para criar um novo serviço.'}
            </DialogDescription>
          </div>
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={() => onOpenChange(false)} 
            className="h-8 w-8 rounded-full hover:bg-white/50 text-diva-pink"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6 bg-diva-light/30">
          <div className="space-y-2">
            <Label htmlFor="name" className="text-sm font-medium text-diva-dark">Nome do Serviço</Label>
            <Input
              id="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              placeholder="Ex: Corte de Cabelo"
              className="bg-white border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20"
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="description" className="text-sm font-medium text-diva-dark">Descrição</Label>
            <Textarea
              id="description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              placeholder="Descreva os detalhes do serviço..."
              className="bg-white border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20 min-h-[100px] resize-none"
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="space-y-2">
              <Label htmlFor="duration_minutes" className="text-sm font-medium text-diva-dark">Duração (minutos)</Label>
              <Input
                id="duration_minutes"
                type="number"
                min="1"
                value={formData.duration_minutes === 0 ? '' : formData.duration_minutes}
                onChange={handleDurationChange}
                className="bg-white border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20"
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="price" className="text-sm font-medium text-diva-dark">Preço</Label>
              <CurrencyInput
                id="price"
                value={formData.price || 0}
                onChange={(value) => setFormData({ ...formData, price: value })}
                required
                className="border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20"
              />
            </div>
          </div>

          <div className="flex gap-3 justify-end pt-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
              disabled={loading}
              className="border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20 hover:text-diva-pink hover:border-diva-pink"
            >
              Cancelar
            </Button>
            <Button 
              type="submit" 
              disabled={loading}
              className="bg-gradient-to-r from-diva-pink to-diva-purple hover:opacity-90 text-white font-medium px-5"
            >
              {loading ? (
                <>
                  <span className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin mr-2"></span>
                  {service ? 'Salvando...' : 'Criando...'}
                </>
              ) : service ? 'Salvar' : 'Criar'}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
} 