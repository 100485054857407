import { jsxDEV as _jsxDEV } from "react/jsx-dev-runtime";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Twitter, Mail } from "lucide-react";
import AgendeDivaLogo from "./AgendeDivaLogo";

const Footer = () => {
  return (
    <footer className="bg-diva-dark text-white">
      <div className="container mx-auto px-4 pt-16 pb-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <AgendeDivaLogo />
              <span className="text-2xl font-bold font-serif">AgendeDiva</span>
            </div>
            <p className="text-sm text-gray-300">
              A plataforma completa para profissionais da beleza gerenciarem seu negócio com um link personalizado.
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://www.facebook.com/agendediva" 
                className="text-white hover:text-diva-pink transition-colors"
                title="Siga-nos no Facebook"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook size={20} />
              </a>
              <a 
                href="https://www.instagram.com/agendediva" 
                className="text-white hover:text-diva-pink transition-colors"
                title="Siga-nos no Instagram"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram size={20} />
              </a>
              <a 
                href="https://twitter.com/agendediva" 
                className="text-white hover:text-diva-pink transition-colors"
                title="Siga-nos no Twitter"
                aria-label="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter size={20} />
              </a>
              <a 
                href="mailto:contato@agendediva.com.br" 
                className="text-white hover:text-diva-pink transition-colors"
                title="Entre em contato por email"
                aria-label="Email"
              >
                <Mail size={20} />
              </a>
            </div>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Empresa</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/sobre" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Conheça mais sobre o AgendeDiva"
                >
                  Sobre nós
                </Link>
              </li>
              <li>
                <Link 
                  to="/contato" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Entre em contato conosco"
                >
                  Contato
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Recursos</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/features" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Conheça nossos recursos"
                >
                  Recursos
                </Link>
              </li>
              <li>
                <Link 
                  to="/pricing" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Veja nossos planos e preços"
                >
                  Preços
                </Link>
              </li>
              <li>
                <Link 
                  to="/support" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Obtenha ajuda"
                >
                  Suporte
                </Link>
              </li>
              <li>
                <Link 
                  to="/tutorials" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Aprenda a usar a plataforma"
                >
                  Tutoriais
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/termos-de-servico" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Leia nossos termos de serviço"
                >
                  Termos de Serviço
                </Link>
              </li>
              <li>
                <Link 
                  to="/politica-de-privacidade" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Leia nossa política de privacidade"
                >
                  Política de Privacidade
                </Link>
              </li>
              <li>
                <Link 
                  to="/politica-de-cookies" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Leia nossa política de cookies"
                >
                  Política de Cookies
                </Link>
              </li>
              <li>
                <Link 
                  to="/politica-de-reembolso" 
                  className="text-gray-300 hover:text-diva-pink transition-colors"
                  title="Leia nossa política de reembolso"
                >
                  Política de Reembolso
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-12 pt-8 text-sm text-gray-400 text-center">
          <p>&copy; {new Date().getFullYear()} AgendeDiva. Todos os direitos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
