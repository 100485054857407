import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

const CTASection = () => {
  return (
    <section className="section-padding bg-diva-dark relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-diva-pink/20 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 w-80 h-80 bg-diva-purple/20 rounded-full blur-3xl translate-x-1/3 translate-y-1/3"></div>
      
      <div className="container mx-auto relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6">
            Pronta para transformar seu negócio de beleza?
          </h2>
          
          <p className="text-lg md:text-xl text-white/80 mb-8 max-w-2xl mx-auto">
            Junte-se a milhares de profissionais que já estão economizando tempo e 
            aumentando seus lucros com o AgendeDiva.
          </p>
          
          <div className="bg-gradient-to-r from-diva-pink to-diva-purple p-[1px] rounded-xl inline-block">
            <div className="glass-card bg-diva-dark/70 backdrop-blur-xl p-6 sm:p-8 rounded-xl">
              <div className="text-center">
                <div className="inline-block mb-4 text-white">
                  <p className="text-lg font-medium">Por apenas</p>
                  <div className="flex items-center justify-center">
                    <span className="text-4xl font-bold">R$19</span>
                    <span className="text-2xl">,90</span>
                    <span className="ml-2 text-white/70">/mês</span>
                  </div>
                </div>
                
                <ul className="text-white/80 space-y-2 mb-6 text-left max-w-xs mx-auto">
                  <li className="flex items-center">
                    <span className="mr-2 text-diva-pink">✓</span>
                    Link personalizado exclusivo
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2 text-diva-pink">✓</span>
                    Sistema completo de agendamentos
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2 text-diva-pink">✓</span>
                    Gestão de clientes e serviços
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2 text-diva-pink">✓</span>
                    Controle financeiro simplificado
                  </li>
                </ul>
                
                <Link to="/signup">
                  <Button className="w-full bg-white hover:bg-diva-pink-light text-diva-dark hover:text-diva-dark font-medium transition-all">
                    Comece seu teste gratuito
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </Link>
                <p className="text-white/60 text-sm mt-3">
                  15 dias grátis, cancele quando quiser
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
