import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import AgendeDivaLogo from "@/components/AgendeDivaLogo";
import { Mail, AlertTriangle } from "lucide-react";
import { resendConfirmationEmail } from "@/lib/auth";
import { useToast } from "@/components/ui/use-toast";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const SignupConfirm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const location = useLocation();
  const email = location.state?.email;
  const isEmergencySignup = location.state?.isEmergencySignup;

  const handleResendEmail = async () => {
    if (!email) {
      toast({
        title: "Erro ao reenviar email",
        description: "Email não encontrado. Por favor, tente se cadastrar novamente.",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsLoading(true);
      const { error } = await resendConfirmationEmail(email);

      if (error) throw error;

      toast({
        title: "Email reenviado",
        description: "Um novo email de confirmação foi enviado para você.",
      });
    } catch (error) {
      toast({
        title: "Erro ao reenviar email",
        description: "Ocorreu um erro ao reenviar o email. Por favor, tente novamente.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full text-center space-y-8">
        <div className="flex flex-col items-center gap-4">
          <AgendeDivaLogo className="w-16 h-16" />
          <h1 className="text-3xl font-bold font-serif text-diva-dark">
            Confirme seu email
          </h1>
        </div>

        <div className="bg-white p-8 rounded-xl shadow-lg border border-diva-pink/10">
          <div className="flex justify-center mb-6">
            <div className="w-16 h-16 bg-diva-pink/10 rounded-full flex items-center justify-center">
              <Mail className="w-8 h-8 text-diva-pink" />
            </div>
          </div>

          <p className="text-diva-dark/80 mb-4">
            Enviamos um link de confirmação para {email ? <strong>{email}</strong> : "seu email"}. 
            Por favor, clique no link para ativar sua conta.
          </p>

          <Alert variant="default" className="mb-6 bg-amber-50 border-amber-200">
            <AlertTriangle className="h-4 w-4 text-amber-600" />
            <AlertTitle className="text-amber-700">Não recebeu o email?</AlertTitle>
            <AlertDescription className="text-amber-600 text-sm">
              <p>Por favor, verifique sua pasta de spam ou lixo eletrônico.</p>
              <p className="mt-2">Se você não receber o email em 5 minutos, clique no botão abaixo para reenviar.</p>
            </AlertDescription>
          </Alert>

          {isEmergencySignup && (
            <Alert variant="default" className="mb-6 bg-amber-50 border-amber-200">
              <AlertTriangle className="h-4 w-4 text-amber-600" />
              <AlertTitle className="text-amber-700">Cadastro de emergência realizado</AlertTitle>
              <AlertDescription className="text-amber-600 text-sm">
                <p>Sua conta foi criada com um link personalizado provisório devido a problemas técnicos.</p>
                <p className="mt-2">Após confirmar seu email e entrar no sistema, você poderá mudar seu link personalizado nas configurações do seu negócio.</p>
              </AlertDescription>
            </Alert>
          )}

          <div className="space-y-4">
            <Button 
              variant="outline" 
              className="w-full border-diva-pink/20 text-diva-dark hover:bg-diva-pink/5"
              onClick={handleResendEmail}
              disabled={isLoading}
            >
              {isLoading ? "Reenviando..." : "Reenviar email de confirmação"}
            </Button>

            <div className="border-t border-gray-200 pt-4 mt-4">
              <p className="text-sm text-diva-dark/60 mb-2">
                Não consegue receber emails no endereço informado?
              </p>
              <Link to="/signup">
                <Button 
                  variant="secondary"
                  className="w-full"
                >
                  Voltar e tentar com outro email
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p className="text-sm text-diva-dark/70">
            Já confirmou seu email?{" "}
            <Link to="/login" className="text-diva-purple hover:text-diva-pink font-medium">
              Faça login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupConfirm; 