import React from 'react';
import LegalPageLayout from '@/components/LegalPageLayout';

const TermsOfService = () => {
  return (
    <LegalPageLayout title="Termos de Serviço" lastUpdated="5 de Abril de 2024">
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            1. Aceitação dos Termos
          </h2>
          <p>
            Ao acessar e utilizar a plataforma AgendeDiva, você concorda em cumprir e estar vinculado aos seguintes termos e condições de uso. Se você não concordar com qualquer parte destes termos, solicitamos que não utilize nossos serviços.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            2. Descrição dos Serviços
          </h2>
          <p>
            O AgendeDiva é uma plataforma online que permite aos profissionais da beleza gerenciar seus negócios através de um link personalizado, sistema de agendamentos, gestão de clientes e controle financeiro simplificado.
          </p>
          <p className="mt-3">
            Oferecemos os seguintes serviços:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Link personalizado para divulgação</li>
            <li>Sistema de agendamentos online</li>
            <li>Gestão de clientes e histórico de serviços</li>
            <li>Notificações automáticas via WhatsApp</li>
            <li>Controle financeiro simplificado</li>
            <li>Relatórios de desempenho</li>
            <li>Personalização da página</li>
            <li>Integrações com WhatsApp</li>
            <li>Pagamentos online (PIX, cartão)</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            3. Conta e Registro
          </h2>
          <p>
            Para utilizar o AgendeDiva, é necessário criar uma conta. Você é responsável por manter a confidencialidade de suas credenciais de acesso e por todas as atividades realizadas em sua conta.
          </p>
          <p className="mt-3">
            Ao se registrar, você concorda em fornecer informações verdadeiras, precisas, atuais e completas. O AgendeDiva reserva-se o direito de suspender ou encerrar sua conta se qualquer informação fornecida for falsa, imprecisa ou enganosa.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            4. Planos e Pagamentos
          </h2>
          <p>
            O AgendeDiva oferece um plano de assinatura mensal no valor de R$19,90, cobrado mensalmente. Você pode testar a plataforma gratuitamente por 15 dias, sem necessidade de cartão de crédito.
          </p>
          <p className="mt-3">
            Ao assinar o plano, você autoriza o AgendeDiva a cobrar automaticamente a taxa correspondente no início de cada período de cobrança. Não há reembolso para períodos parciais de uso.
          </p>
          <p className="mt-3">
            Aceitamos pagamentos via cartão de crédito e PIX. Todas as transações são processadas por gateways de pagamento seguros e criptografados.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            5. Cancelamento e Rescisão
          </h2>
          <p>
            Você pode cancelar sua assinatura a qualquer momento sem multas ou taxas adicionais. Seu acesso permanecerá ativo até o fim do período já pago.
          </p>
          <p className="mt-3">
            O AgendeDiva reserva-se o direito de rescindir ou suspender sua conta, a nosso critério exclusivo, sem aviso prévio, por violação destes Termos de Serviço ou por qualquer atividade que considerarmos prejudicial à plataforma ou a outros usuários.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            6. Propriedade Intelectual
          </h2>
          <p>
            Todo o conteúdo apresentado no AgendeDiva, incluindo, mas não se limitando a, textos, gráficos, logotipos, ícones, imagens, clipes de áudio, downloads digitais e compilações de dados, é propriedade do AgendeDiva ou de seus fornecedores de conteúdo e está protegido pelas leis brasileiras e internacionais de direitos autorais.
          </p>
          <p className="mt-3">
            É estritamente proibida a reprodução, distribuição, modificação, exibição pública, transmissão ou uso do conteúdo do AgendeDiva para qualquer finalidade não expressamente autorizada por escrito.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            7. Limitação de Responsabilidade
          </h2>
          <p>
            O AgendeDiva não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, consequenciais, especiais ou punitivos resultantes do uso ou incapacidade de usar nossos serviços.
          </p>
          <p className="mt-3">
            Não garantimos que nossos serviços estarão sempre disponíveis, acessíveis, ininterruptos, oportunos, seguros, precisos, completos ou livres de erros.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            8. Confidencialidade dos Dados
          </h2>
          <p>
            O AgendeDiva se compromete a manter a confidencialidade de todos os dados inseridos pelos usuários, incluindo informações de clientes, agendamentos e transações financeiras. Não compartilhamos, vendemos ou alugamos suas informações pessoais a terceiros para fins de marketing.
          </p>
          <p className="mt-3">
            Para mais informações sobre como tratamos seus dados, consulte nossa <a href="/politica-de-privacidade" className="text-diva-pink hover:underline">Política de Privacidade</a>.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            9. Modificações nos Termos de Serviço
          </h2>
          <p>
            O AgendeDiva reserva-se o direito de modificar estes Termos de Serviço a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação dos termos revisados no site. É sua responsabilidade verificar periodicamente se há alterações.
          </p>
          <p className="mt-3">
            O uso contínuo da plataforma após a publicação de alterações constitui aceitação dessas alterações.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            10. Lei Aplicável
          </h2>
          <p>
            Estes Termos de Serviço serão regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa decorrente destes termos será submetida à jurisdição exclusiva dos tribunais brasileiros.
          </p>
        </section>
      </div>
    </LegalPageLayout>
  );
};

export default TermsOfService; 