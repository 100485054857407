import React, { forwardRef } from 'react';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

export interface CurrencyInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: number;
  onChange: (value: number) => void;
  className?: string;
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ value, onChange, className, ...props }, ref) => {
    const formatToCurrency = (value: number): string => {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
        .format(value)
        .replace('R$', '')
        .trim();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      
      // Remove tudo que não é número
      value = value.replace(/\D/g, '');
      
      // Converte para número e divide por 100 para ter os centavos
      const numericValue = Number(value) / 100;
      
      onChange(numericValue);
    };

    return (
      <div className="relative">
        <div className="absolute left-3 top-1/2 -translate-y-1/2 text-diva-pink font-medium">
          R$
        </div>
        <Input
          {...props}
          ref={ref}
          value={formatToCurrency(value)}
          onChange={handleChange}
          className={cn(
            "pl-9 bg-white font-medium text-diva-dark",
            className
          )}
        />
      </div>
    );
  }
);

CurrencyInput.displayName = 'CurrencyInput';

export { CurrencyInput }; 