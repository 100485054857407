import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '../lib/supabase';
import { User } from '@supabase/supabase-js';
import { useToast } from '../components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { settingsService } from '../services/settingsService';

interface UserMetadata {
  nome_negocio?: string;
  link_personalizado?: string;
  phone?: string;
  [key: string]: string | undefined;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string, userMetadata?: UserMetadata) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar sessão atual
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session?.user) {
        setUser(session.user);
      }
      setLoading(false);
    });

    // Escutar mudanças de autenticação
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user) {
        setUser(session.user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      console.log('Tentando fazer login com:', email);
      
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      
      if (error) {
        console.error('Erro ao fazer login:', error);
        
        if (error.message.includes('Invalid login credentials')) {
          throw new Error('Email ou senha incorretos. Por favor, tente novamente.');
        }
        
        throw error;
      }
      
      // Obter o nome do usuário dos metadados
      const userData = data.user?.user_metadata as { nome_completo?: string } | undefined;
      const userName = userData?.nome_completo || '';
      const saudacao = userName ? `Bem-vindo(a) de volta, ${userName}!` : 'Bem-vindo(a) de volta!';
      
      toast({
        title: 'Login realizado com sucesso!',
        description: saudacao,
      });
      navigate('/servicos');
    } catch (error) {
      console.error('Erro completo no login:', error);
      if (error instanceof Error) {
        toast({
          title: 'Falha no login',
          description: error.message,
          variant: 'destructive',
        });
      } else {
        toast({
          title: 'Falha no login',
          description: 'Ocorreu um erro ao tentar fazer login',
          variant: 'destructive',
        });
      }
      throw error;
    }
  };

  const signUp = async (email: string, password: string, name: string, userMetadata?: UserMetadata) => {
    try {
      console.log('Tentando criar conta para:', email);
      
      // SOLUÇÃO EXTREMA: Tentando signup com mínimo de dados possível
      const { data, error } = await supabase.auth.signUp({
        email: email.trim(),
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/login`,
          data: {
            nome_completo: name.trim()
          }
        }
      });
      
      if (error) {
        console.error('Erro ao criar conta:', error);
        throw new Error(`Erro ao criar conta: ${error.message}`);
      }
      
      if (!data.user) {
        throw new Error('Não foi possível criar a conta. Tente novamente.');
      }

      // Se chegou aqui, a conta foi criada com sucesso
      // Vamos agora tentar atualizar os metadados separadamente (isso não vai impedir o sucesso do cadastro)
      if (userMetadata) {
        try {
          await supabase.auth.updateUser({
            data: {
              nome_negocio: userMetadata.nome_negocio,
              link_personalizado: userMetadata.link_personalizado,
              phone: userMetadata.phone
            }
          });
          console.log('Metadados do usuário atualizados com sucesso!');
        } catch (updateError) {
          console.error('Erro ao atualizar metadados (não crítico):', updateError);
          // Não vamos falhar o cadastro se apenas os metadados adicionais falharem
        }
      }

      toast({
        title: 'Conta criada com sucesso!',
        description: 'Verifique seu email para confirmar sua conta',
      });
      
      navigate('/signup/confirm', { state: { email } });
    } catch (error) {
      console.error('Erro completo na criação de conta:', error);
      
      // NOVA ESTRATÉGIA: Se falhar, tentar uma segunda abordagem mais extrema
      if (error instanceof Error && (
        error.message.includes('database') || 
        error.message.includes('new user') ||
        error.message.includes('profiles')
      )) {
        try {
          console.log('Tentando método alternativo para criação de conta...');
          const { data, error: error2 } = await supabase.auth.signUp({
            email,
            password
          });
          
          if (error2) throw error2;
          
          if (data.user) {
            toast({
              title: 'Conta criada com sucesso!',
              description: 'Verifique seu email para confirmar sua conta',
            });
            
            navigate('/signup/confirm', { state: { email } });
            return;
          }
        } catch (secondAttemptError) {
          console.error('Erro na segunda tentativa de criação:', secondAttemptError);
          // Seguir para o tratamento de erro padrão
        }
      }
      
      // Tratamento de erro padrão
      if (error instanceof Error) {
        toast({
          title: 'Falha na criação da conta',
          description: error.message,
          variant: 'destructive',
        });
      } else {
        toast({
          title: 'Falha na criação da conta',
          description: 'Ocorreu um erro ao tentar criar sua conta',
          variant: 'destructive',
        });
      }
      throw error;
    }
  };

  const signOut = async () => {
    try {
      // Obter o nome do usuário antes de fazer logout
      const userData = user?.user_metadata as { nome_completo?: string } | undefined;
      const userName = userData?.nome_completo || '';
      const mensagemDespedida = userName ? `Até logo, ${userName}!` : 'Até logo!';
      
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      toast({
        title: 'Logout realizado com sucesso!',
        description: mensagemDespedida,
      });
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
      toast({
        title: 'Erro ao fazer logout',
        description: 'Ocorreu um erro ao tentar sair',
        variant: 'destructive',
      });
    }
  };

  const resetPassword = async (email: string) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      toast({
        title: 'Email enviado!',
        description: 'Verifique seu email para redefinir sua senha',
      });
    } catch (error) {
      console.error('Erro ao enviar email de redefinição:', error);
      toast({
        title: 'Erro ao enviar email',
        description: 'Não foi possível enviar o email de redefinição',
        variant: 'destructive',
      });
    }
  };

  const value = {
    user,
    loading,
    signIn,
    signUp,
    signOut,
    resetPassword
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
} 