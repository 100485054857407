import { supabase } from '@/lib/supabase'
import { User, SignUpData } from '@/types/user'

export async function signUp(userData: SignUpData) {
  console.log('====== INÍCIO DO PROCESSO DE CADASTRO ======');
  try {
    console.log('Dados recebidos:', { 
      email: userData.email,
      nome_completo: userData.nome_completo,
      nome_negocio: userData.nome_negocio,
      link_personalizado: userData.link_personalizado
    });
    
    // Simplificar o processo - primeiro criar o usuário na autenticação
    console.log('Criando usuário na autenticação do Supabase...');
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email: userData.email,
      password: userData.password,
      options: {
        emailRedirectTo: `${window.location.origin}/login`,
        data: {
          nome_completo: userData.nome_completo,
          nome_negocio: userData.nome_negocio,
          link_personalizado: userData.link_personalizado
        }
      }
    });

    if (authError) {
      console.error('Erro na etapa de autenticação:', authError);
      return { data: null, error: authError };
    }

    if (!authData.user) {
      console.error('Usuário não foi criado na autenticação');
      return { data: null, error: new Error('Usuário não foi criado na autenticação') };
    }
    
    console.log('Usuário criado com sucesso na autenticação:', authData.user.id);
    console.log('====== FIM DO PROCESSO DE CADASTRO ======');
    
    // Retornar os dados do usuário
    return { 
      data: {
        id: authData.user.id,
        email: userData.email,
        nome_completo: userData.nome_completo,
        nome_negocio: userData.nome_negocio,
        link_personalizado: userData.link_personalizado,
        created_at: new Date().toISOString()
      }, 
      error: null 
    };
  } catch (error) {
    console.error('Erro não tratado no cadastro:', error);
    return { data: null, error };
  }
}

export async function signIn(email: string, password: string) {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;

    return { data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;

    return { error: null };
  } catch (error) {
    return { error };
  }
}

export async function resendConfirmationEmail(email: string) {
  console.log('Tentando reenviar email de confirmação para:', email);
  try {
    // Primeiro método: utilizar a API do Supabase
    console.log('Método 1: Utilizando supabase.auth.resend()');
    const { data, error } = await supabase.auth.resend({
      type: 'signup',
      email: email,
      options: {
        emailRedirectTo: `${window.location.origin}/login`
      }
    });

    if (error) {
      console.error('Erro ao reenviar email pelo método 1:', error);
      
      // Segundo método: tentar via API REST direta
      console.log('Método 2: Utilizando API REST direta');
      try {
        const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/auth/v1/signup/resend`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'apikey': import.meta.env.VITE_SUPABASE_ANON_KEY,
          },
          body: JSON.stringify({
            email: email,
            type: 'signup'
          })
        });
        
        const result = await response.json();
        console.log('Resultado do método 2:', result);
        
        if (result.error) {
          throw result.error;
        }
        
        return { data: result, error: null };
      } catch (restError) {
        console.error('Erro ao reenviar email pelo método 2:', restError);
        throw error; // Retorna o erro original se o segundo método também falhar
      }
    }

    console.log('Email de confirmação reenviado com sucesso!');
    return { data, error: null };
  } catch (error) {
    console.error('Erro final ao reenviar email de confirmação:', error);
    return { data: null, error };
  }
} 