const testimonials = [
  {
    name: "Ana Luiza",
    role: "Designer de Sobrancelhas",
    image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=150&q=80",
    quote: "O AgendeDiva transformou meu negócio! Agora consigo gerenciar meus agendamentos e clientes com facilidade, além de ter meu próprio link personalizado para divulgação."
  },
  {
    name: "Juliana Mendes",
    role: "Especialista em Bronzeamento",
    image: "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=150&q=80",
    quote: "Desde que comecei a usar o AgendeDiva, minhas clientes podem fazer agendamentos a qualquer hora do dia. Minha agenda está muito mais organizada e meu faturamento aumentou em 30%!"
  },
  {
    name: "Carla Rodrigues",
    role: "Nail Designer",
    image: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=150&q=80",
    quote: "O sistema de lembretes automáticos reduziu drasticamente as faltas em meu salão. As ferramentas de controle financeiro me ajudam a ter uma visão clara do meu negócio. Imprescindível!"
  }
];

const TestimonialSection = () => {
  return (
    <section id="testimonials" className="section-padding bg-gradient-to-br from-diva-purple/5 to-diva-pink/5">
      <div className="container mx-auto">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-diva-dark mb-4">
            O que nossas clientes estão dizendo
          </h2>
          <p className="text-diva-dark/70 text-lg">
            Centenas de empreendedoras da beleza já transformaram seus negócios com o AgendeDiva
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="glass-card p-6 card-hover flex flex-col">
              <div className="flex-1">
                <div className="text-diva-purple text-4xl font-serif mb-4">"</div>
                <p className="text-diva-dark/80 italic mb-6">{testimonial.quote}</p>
              </div>
              
              <div className="flex items-center mt-4">
                <img 
                  src={testimonial.image} 
                  alt={testimonial.name} 
                  className="w-12 h-12 rounded-full object-cover border-2 border-diva-pink"
                />
                <div className="ml-4">
                  <h4 className="font-bold text-diva-dark">{testimonial.name}</h4>
                  <p className="text-sm text-diva-dark/70">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
