import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { toast } from 'sonner';
import { Mail, MapPin, Phone, Send, MessageSquare } from 'lucide-react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validação básica
    if (!formData.name || !formData.email || !formData.message) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
    
    // Validar formato de email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Por favor, informe um email válido');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Simulação de envio - aqui você implementaria a integração real com um serviço de email
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      toast.success('Mensagem enviada com sucesso! Entraremos em contato em breve.');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar sua mensagem. Tente novamente mais tarde.');
      console.error('Erro ao enviar formulário:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <div className="container mx-auto px-4">
          {/* Header com gradiente */}
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
              Entre em Contato
            </h1>
            <p className="text-lg text-gray-600 max-w-xl mx-auto">
              Estamos aqui para ajudar. Envie-nos uma mensagem e responderemos o mais breve possível.
            </p>
          </div>
          
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Informações de contato */}
              <div className="bg-white rounded-xl shadow-md overflow-hidden md:col-span-1">
                <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
                <div className="p-6 md:p-8">
                  <h2 className="text-2xl font-bold text-diva-dark mb-6">
                    Informações de Contato
                  </h2>
                  
                  <div className="space-y-6">
                    <div className="flex items-start">
                      <div className="mt-1 bg-purple-100 p-2 rounded-full">
                        <Mail className="h-5 w-5 text-diva-purple" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-800">Email</h3>
                        <a href="mailto:contato@agendediva.com.br" className="text-gray-600 hover:text-diva-pink transition-colors">
                          contato@agendediva.com.br
                        </a>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="mt-1 bg-pink-100 p-2 rounded-full">
                        <Phone className="h-5 w-5 text-diva-pink" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-800">Telefone</h3>
                        <a href="tel:+5511999999999" className="text-gray-600 hover:text-diva-pink transition-colors">
                          +55 (11) 99999-9999
                        </a>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="mt-1 bg-blue-100 p-2 rounded-full">
                        <MapPin className="h-5 w-5 text-blue-600" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-800">Endereço</h3>
                        <p className="text-gray-600">
                          Av. Paulista, 1000<br />
                          Bela Vista, São Paulo - SP<br />
                          CEP: 01310-100
                        </p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="mt-1 bg-green-100 p-2 rounded-full">
                        <MessageSquare className="h-5 w-5 text-green-600" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-800">Suporte</h3>
                        <p className="text-gray-600">
                          Horário de atendimento:<br />
                          Segunda a Sexta: 9h às 18h
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="mt-8 pt-6 border-t border-gray-100">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">Siga-nos</h3>
                    <div className="flex space-x-4">
                      <a 
                        href="https://www.facebook.com/agendediva" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="bg-gray-100 p-2 rounded-full text-gray-600 hover:bg-diva-pink hover:text-white transition-colors"
                        title="Facebook AgendeDiva"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                        </svg>
                      </a>
                      <a 
                        href="https://www.instagram.com/agendediva" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="bg-gray-100 p-2 rounded-full text-gray-600 hover:bg-diva-pink hover:text-white transition-colors"
                        title="Instagram AgendeDiva"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                        </svg>
                      </a>
                      <a 
                        href="https://twitter.com/agendediva" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="bg-gray-100 p-2 rounded-full text-gray-600 hover:bg-diva-pink hover:text-white transition-colors"
                        title="Twitter AgendeDiva"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                        </svg>
                      </a>
                      <a 
                        href="https://www.linkedin.com/company/agendediva" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="bg-gray-100 p-2 rounded-full text-gray-600 hover:bg-diva-pink hover:text-white transition-colors"
                        title="LinkedIn AgendeDiva"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Formulário de contato */}
              <div className="bg-white rounded-xl shadow-md overflow-hidden md:col-span-2">
                <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
                <div className="p-6 md:p-8">
                  <h2 className="text-2xl font-bold text-diva-dark mb-6">
                    Envie-nos uma mensagem
                  </h2>
                  
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                          Nome Completo <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                          placeholder="Seu nome"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                          E-mail <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                          placeholder="seu@email.com"
                        />
                      </div>
                    </div>
                    
                    <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                        Assunto
                      </label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                        placeholder="Assunto da mensagem"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                        Mensagem <span className="text-red-500">*</span>
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows={6}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                        placeholder="Como podemos ajudar?"
                      ></textarea>
                    </div>
                    
                    <div className="text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-6 py-3 bg-gradient-to-r from-diva-pink to-diva-purple text-white font-medium rounded-md shadow-md hover:shadow-lg transition-all flex items-center justify-center"
                      >
                        {isSubmitting ? (
                          <>
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Enviando...
                          </>
                        ) : (
                          <>
                            <Send className="h-4 w-4 mr-2" />
                            Enviar Mensagem
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
            {/* Mapa ou informações adicionais */}
            <div className="mt-12 bg-white rounded-xl shadow-md overflow-hidden">
              <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
              <div className="p-6 md:p-8">
                <h2 className="text-2xl font-bold text-diva-dark mb-6">
                  Perguntas Frequentes
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="border border-gray-100 rounded-lg p-5">
                    <h3 className="text-lg font-medium text-diva-dark mb-2">Quanto custa usar o AgendeDiva?</h3>
                    <p className="text-gray-600">O AgendeDiva tem um plano único de R$19,90 por mês, com acesso a todas as funcionalidades. Você pode testar gratuitamente por 15 dias antes de decidir assinar.</p>
                  </div>
                  
                  <div className="border border-gray-100 rounded-lg p-5">
                    <h3 className="text-lg font-medium text-diva-dark mb-2">Como funciona o período de teste?</h3>
                    <p className="text-gray-600">Ao se cadastrar, você terá acesso a todos os recursos por 15 dias sem nenhum custo ou compromisso. Não solicitamos dados de cartão de crédito durante o período de teste.</p>
                  </div>
                  
                  <div className="border border-gray-100 rounded-lg p-5">
                    <h3 className="text-lg font-medium text-diva-dark mb-2">É possível cancelar a assinatura?</h3>
                    <p className="text-gray-600">Sim, você pode cancelar sua assinatura a qualquer momento na seção "Configurações" do seu painel, sem taxas de cancelamento ou multas.</p>
                  </div>
                  
                  <div className="border border-gray-100 rounded-lg p-5">
                    <h3 className="text-lg font-medium text-diva-dark mb-2">Oferecem suporte técnico?</h3>
                    <p className="text-gray-600">Sim, oferecemos suporte técnico por email e WhatsApp de segunda a sexta, das 9h às 18h, para todos os clientes ativos.</p>
                  </div>
                </div>
                
                <div className="mt-8 text-center">
                  <a 
                    href="/support" 
                    className="text-diva-pink font-medium hover:underline inline-flex items-center"
                    title="Ver todas as perguntas frequentes"
                  >
                    Ver todas as perguntas frequentes
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact; 