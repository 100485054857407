import { Calendar, Link, UserCheck, CreditCard, PieChart, Bell, Palette } from "lucide-react";

const features = [
  {
    icon: <Link className="w-10 h-10 text-diva-pink" />,
    title: "Link Personalizado",
    description: "Crie seu domínio exclusivo para compartilhar com clientes e mostrar seus serviços e horários disponíveis.",
  },
  {
    icon: <Calendar className="w-10 h-10 text-diva-pink" />,
    title: "Agendamento Online",
    description: "Sistema completo de agendamentos com notificações automáticas para confirmação de horários marcados.",
  },
  {
    icon: <UserCheck className="w-10 h-10 text-diva-pink" />,
    title: "Gestão de Clientes",
    description: "Mantenha um cadastro completo de clientes com histórico de serviços, preferências e contatos.",
  },
  {
    icon: <CreditCard className="w-10 h-10 text-diva-pink" />,
    title: "Pagamentos Integrados",
    description: "Aceite pagamentos online via PIX, cartão e boleto, com controle total das transações.",
  },
  {
    icon: <PieChart className="w-10 h-10 text-diva-pink" />,
    title: "Controle Financeiro",
    description: "Acompanhe receitas, despesas e lucro com gráficos intuitivos e relatórios detalhados.",
  },
  {
    icon: <Bell className="w-10 h-10 text-diva-pink" />,
    title: "Lembretes Automáticos",
    description: "Envie lembretes de agendamento e promoções personalizadas para seus clientes.",
  },
  {
    icon: <Palette className="w-10 h-10 text-diva-pink" />,
    title: "Personalização Completa",
    description: "Adapte as cores, logo e estilo da sua página para combinar com a identidade do seu negócio.",
  }
];

const FeatureSection = () => {
  return (
    <section id="features" className="section-padding bg-diva-light">
      <div className="container mx-auto">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-diva-dark mb-4">
            Tudo o que você precisa para gerenciar seu negócio de beleza
          </h2>
          <p className="text-diva-dark/70 text-lg">
            Com o AgendeDiva, você tem acesso a ferramentas completas para fazer seu negócio crescer, 
            enquanto economiza tempo com tarefas administrativas.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="glass-card p-6 card-hover"
            >
              <div className="bg-diva-pink/10 p-3 rounded-2xl inline-block mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-bold text-diva-dark mb-3">{feature.title}</h3>
              <p className="text-diva-dark/70">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
