import { supabase } from '@/lib/supabase';
import { WorkingHours } from '@/types/appointment';
import { toast } from 'sonner';

let tableSetupAttempted = false; // Flag para controlar tentativas de configuração

export const workingHoursService = {
  // Obter todos os horários de funcionamento de um profissional
  async getAllByProfessional(professionalId: string): Promise<WorkingHours[]> {
    try {
      // Verificar se a tabela existe antes de fazer a consulta
      if (!tableSetupAttempted) {
        const tableExists = await this.checkTableExists();
        if (!tableExists) {
          console.log('Tabela não existe, tentando criar...');
          await this.createWorkingHoursTable();
        }
        tableSetupAttempted = true;
      }

      console.log('Buscando horários de funcionamento para o profissional:', professionalId);
      
      const { data, error } = await supabase
        .from('working_hours')
        .select('*')
        .eq('professional_id', professionalId)
        .order('day_of_week', { ascending: true });

      if (error) {
        console.error('Erro ao buscar horários de funcionamento:', error);
        return []; // Retorna array vazio em vez de lançar erro
      }

      console.log('Horários encontrados:', data);
      return data || [];
    } catch (error) {
      console.error('Erro ao buscar horários de funcionamento:', error);
      return []; // Retorna array vazio em caso de erro
    }
  },

  // Obter horário de funcionamento para um dia específico
  async getByDay(professionalId: string, dayOfWeek: number): Promise<WorkingHours | null> {
    try {
      console.log(`Buscando horário para dia ${dayOfWeek} do profissional ${professionalId}`);
      
      const { data, error } = await supabase
        .from('working_hours')
        .select('*')
        .eq('professional_id', professionalId)
        .eq('day_of_week', dayOfWeek)
        .single();

      if (error) {
        // Se o erro for "No rows found", retornamos null
        if (error.code === 'PGRST116') {
          console.log(`Nenhum horário configurado para o dia ${dayOfWeek}`);
          return null;
        }
        console.error('Erro ao buscar horário de funcionamento por dia:', error);
        return null; // Retorna null em vez de lançar erro
      }

      console.log(`Horário encontrado para dia ${dayOfWeek}:`, data);
      return data;
    } catch (error) {
      console.error('Erro ao buscar horário de funcionamento por dia:', error);
      return null; // Retorna null em caso de erro
    }
  },

  // Criar ou atualizar horário de funcionamento para um dia específico
  async upsert(workingHours: Omit<WorkingHours, 'id' | 'created_at' | 'updated_at'>): Promise<WorkingHours> {
    try {
      console.log('Salvando horário de funcionamento:', workingHours);
      
      // Verificar se já existe um registro para esse profissional e dia
      const { data: existingData, error: checkError } = await supabase
        .from('working_hours')
        .select('id')
        .eq('professional_id', workingHours.professional_id)
        .eq('day_of_week', workingHours.day_of_week)
        .maybeSingle();
      
      if (checkError) {
        console.error('Erro ao verificar horário existente:', checkError);
        throw checkError;
      }

      let result;
      
      if (existingData?.id) {
        // Atualizar registro existente
        console.log(`Atualizando registro existente (id: ${existingData.id}):`, workingHours);
        const { data, error } = await supabase
          .from('working_hours')
          .update({
            start_time: workingHours.start_time,
            end_time: workingHours.end_time,
            is_available: workingHours.is_available
          })
          .eq('id', existingData.id)
          .select()
          .single();
          
        if (error) {
          console.error('Erro ao atualizar horário:', error);
          throw error;
        }
        
        result = data;
        console.log('Registro atualizado com sucesso:', data);
      } else {
        // Criar novo registro
        console.log('Criando novo registro:', workingHours);
        const { data, error } = await supabase
          .from('working_hours')
          .insert([workingHours])
          .select()
          .single();
          
        if (error) {
          console.error('Erro ao criar horário:', error);
          throw error;
        }
        
        result = data;
        console.log('Registro criado com sucesso:', data);
      }

      return result;
    } catch (error) {
      console.error('Erro ao criar/atualizar horário de funcionamento:', error);
      toast.error('Erro ao salvar horário de funcionamento. Tente novamente.');
      throw error;
    }
  },

  // Excluir horário de funcionamento para um dia específico
  async remove(professionalId: string, dayOfWeek: number): Promise<void> {
    try {
      console.log(`Removendo horário do dia ${dayOfWeek} para o profissional ${professionalId}`);
      
      const { error } = await supabase
        .from('working_hours')
        .delete()
        .eq('professional_id', professionalId)
        .eq('day_of_week', dayOfWeek);

      if (error) {
        console.error('Erro ao remover horário de funcionamento:', error);
        throw error;
      }
      
      console.log('Horário removido com sucesso');
    } catch (error) {
      console.error('Erro ao remover horário de funcionamento:', error);
      throw error;
    }
  },

  // Definir um dia como indisponível
  async setDayUnavailable(professionalId: string, dayOfWeek: number): Promise<void> {
    try {
      console.log(`Definindo dia ${dayOfWeek} como indisponível para o profissional ${professionalId}`);
      
      const existingHours = await this.getByDay(professionalId, dayOfWeek);
      
      if (existingHours) {
        // Se já existe, apenas atualizamos para indisponível
        await this.upsert({
          ...existingHours,
          is_available: false
        });
      } else {
        // Se não existe, criamos um registro padrão mas indisponível
        await this.upsert({
          professional_id: professionalId,
          day_of_week: dayOfWeek,
          start_time: '09:00',
          end_time: '18:00',
          is_available: false
        });
      }
      
      console.log(`Dia ${dayOfWeek} definido como indisponível com sucesso`);
    } catch (error) {
      console.error('Erro ao definir dia como indisponível:', error);
      throw error;
    }
  },

  // Salvar a duração padrão de agendamentos para um profissional
  async saveDuration(professionalId: string, duration: string): Promise<void> {
    try {
      console.log(`Salvando duração padrão de ${duration} minutos para o profissional ${professionalId}`);
      
      // Verificar se já existe uma configuração para este profissional
      const { data: existingConfig, error: checkError } = await supabase
        .from('professional_settings')
        .select('id')
        .eq('professional_id', professionalId)
        .maybeSingle();
      
      if (checkError && checkError.code !== 'PGRST116') {
        console.error('Erro ao verificar configurações existentes:', checkError);
        throw checkError;
      }
      
      if (existingConfig?.id) {
        // Atualizar configuração existente
        const { error } = await supabase
          .from('professional_settings')
          .update({
            default_slot_duration: duration,
            updated_at: new Date().toISOString()
          })
          .eq('id', existingConfig.id);
          
        if (error) {
          console.error('Erro ao atualizar duração padrão:', error);
          throw error;
        }
      } else {
        // Criar nova configuração
        const { error } = await supabase
          .from('professional_settings')
          .insert([{
            professional_id: professionalId,
            default_slot_duration: duration,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }]);
          
        if (error) {
          // Se a tabela não existe, tenta criar
          if (error.code === '42P01') {
            await this.createSettingsTable();
            return this.saveDuration(professionalId, duration); // Tentar novamente após criar a tabela
          }
          console.error('Erro ao criar configuração de duração padrão:', error);
          throw error;
        }
      }
      
      console.log(`Duração padrão de ${duration} minutos salva com sucesso.`);
    } catch (error) {
      console.error('Erro ao salvar duração padrão:', error);
      toast.error('Erro ao salvar duração padrão. Tente novamente.');
      throw error;
    }
  },
  
  // Obter a duração padrão de agendamentos para um profissional
  async getDuration(professionalId: string): Promise<string> {
    try {
      console.log(`Buscando duração padrão para o profissional ${professionalId}`);
      
      const { data, error } = await supabase
        .from('professional_settings')
        .select('default_slot_duration')
        .eq('professional_id', professionalId)
        .maybeSingle();
      
      if (error) {
        // Se a tabela não existe, retorna o valor padrão
        if (error.code === '42P01') {
          console.log('Tabela de configurações não existe. Retornando duração padrão.');
          return '30'; // 30 minutos como padrão
        }
        console.error('Erro ao buscar duração padrão:', error);
        return '30'; // Retorna valor padrão em vez de lançar erro
      }
      
      return data?.default_slot_duration || '30';
    } catch (error) {
      console.error('Erro ao buscar duração padrão:', error);
      return '30'; // Retorna valor padrão em caso de erro
    }
  },
  
  // Criar tabela de configurações se não existir
  async createSettingsTable(): Promise<boolean> {
    try {
      console.log('Criando tabela professional_settings...');
      
      const { error } = await supabase.rpc('create_professional_settings_table');
      
      if (error) {
        console.error('Erro ao criar tabela professional_settings:', error);
        
        // Tentativa direta de criação da tabela usando SQL
        try {
          // Utilizamos o método .from('') com um SQL bruto para criar a tabela
          const { error: sqlError } = await supabase
            .from('_')
            .select(`
              create_table(
                'professional_settings',
                'id uuid primary key default uuid_generate_v4(),
                professional_id uuid not null references auth.users(id) on delete cascade,
                default_slot_duration varchar(10) not null default ''30'',
                created_at timestamp with time zone default now(),
                updated_at timestamp with time zone default now()'
              )
            `);
          
          if (sqlError) {
            console.error('Erro na segunda tentativa de criar tabela:', sqlError);
            return false;
          }
          
          console.log('Tabela professional_settings criada com SQL alternativo');
          return true;
        } catch (sqlError) {
          console.error('Erro na segunda tentativa de criar tabela:', sqlError);
          
          // Se todas as tentativas falharem, informamos o usuário
          toast.error('Não foi possível criar a tabela de configurações. Entre em contato com o suporte.');
          return false;
        }
      }
      
      console.log('Tabela professional_settings criada com sucesso');
      return true;
    } catch (error) {
      console.error('Erro ao criar tabela professional_settings:', error);
      return false;
    }
  },

  // Inicializar horários de funcionamento padrão para um profissional
  async initializeDefault(professionalId: string): Promise<void> {
    if (!professionalId) {
      console.error('Erro ao inicializar horários padrão: ID do profissional não fornecido');
      return; // Retornar void em vez de lançar erro
    }
    
    try {
      console.log(`Inicializando horários padrão para o profissional ${professionalId}`);
      
      // Verificar se já existem configurações para este profissional
      const existingHours = await this.getAllByProfessional(professionalId);
      
      if (existingHours.length > 0) {
        console.log(`Profissional ${professionalId} já possui ${existingHours.length} configurações de horário. Pulando inicialização.`);
        return;
      }
      
      // Horários padrão para dias de semana (segunda a sexta)
      const weekdayHours = {
        start_time: '09:00',
        end_time: '18:00',
        is_available: true
      };

      // Dias de semana: 1 a 5 (segunda a sexta)
      console.log(`Configurando dias de semana (1-5) como disponíveis...`);
      for (let day = 1; day <= 5; day++) {
        console.log(`Configurando dia ${day} como disponível`);
        try {
          await this.upsert({
            professional_id: professionalId,
            day_of_week: day,
            ...weekdayHours
          });
        } catch (error) {
          console.error(`Erro ao configurar dia ${day}:`, error);
          // Continua para os próximos dias mesmo se houver erro
        }
      }

      // Definir fim de semana como indisponível
      console.log('Configurando final de semana como indisponível');
      try {
        await this.setDayUnavailable(professionalId, 0); // Domingo
      } catch (error) {
        console.error('Erro ao configurar domingo como indisponível:', error);
      }
      
      try {
        await this.setDayUnavailable(professionalId, 6); // Sábado
      } catch (error) {
        console.error('Erro ao configurar sábado como indisponível:', error);
      }
      
      console.log('Horários padrão inicializados com sucesso');
    } catch (error) {
      console.error('Erro ao inicializar horários padrão:', error);
      // Não lança erro, apenas loga
    }
  },
  
  // Verificar se a tabela working_hours existe
  async checkTableExists(): Promise<boolean> {
    try {
      // Primeiro verificamos se a tabela está acessível através de uma consulta simples
      const { data, error } = await supabase
        .from('working_hours')
        .select('count(*)', { count: 'exact', head: true });
      
      // Se não houver erro, a tabela existe
      if (!error) {
        console.log('Tabela working_hours existe e está acessível');
        return true;
      }
      
      // Verificar se o erro indica que a tabela não existe
      if (error.code === '42P01' || // PostgreSQL: tabela não existe
          error.message?.includes('relation "working_hours" does not exist') ||
          error.message?.includes('não existe') ||
          error.message?.includes('does not exist')) {
        console.log('Tabela working_hours não existe:', error.message);
        return false;
      }
      
      // Para outros tipos de erro (permissão, etc), assumimos que existe para evitar tentativas repetidas
      console.error('Erro ao verificar tabela working_hours:', error);
      return true; // Assumir que existe para prevenir tentativas repetidas
    } catch (error) {
      console.error('Exceção ao verificar tabela working_hours:', error);
      return true; // Assumir que existe para prevenir tentativas repetidas
    }
  },

  // Criar tabela working_hours diretamente
  async createWorkingHoursTable(): Promise<boolean> {
    try {
      // Verificar primeiro se a tabela já existe
      const tableExists = await this.checkTableExists();
      if (tableExists) {
        console.log('Tabela working_hours já existe');
        return true;
      }
      
      console.log('Tentando criar a tabela working_hours...');
      
      // Primeiro tenta usar a função RPC
      try {
        const { error: rpcError } = await supabase.rpc('create_working_hours_table');
        
        if (!rpcError) {
          // Verificar se a tabela foi criada
          const tableCreated = await this.checkTableExists();
          if (tableCreated) {
            console.log('Tabela working_hours criada com sucesso via função RPC');
            return true;
          }
        }
      } catch (error) {
        console.warn('Erro ao chamar função RPC para criar tabela:', error);
        // Continua para tentar outros métodos
      }
      
      // Se a função RPC falhar, tenta usar exec_sql
      try {
        const sql = `
        DO $$
        BEGIN
          IF NOT EXISTS (SELECT FROM pg_tables WHERE schemaname = 'public' AND tablename = 'working_hours') THEN
            -- Criar tabela de horários de funcionamento
            CREATE TABLE public.working_hours (
              id UUID PRIMARY KEY DEFAULT gen_random_uuid(),
              professional_id UUID NOT NULL REFERENCES auth.users(id) ON DELETE CASCADE,
              day_of_week INTEGER NOT NULL CHECK (day_of_week BETWEEN 0 AND 6),
              start_time TIME NOT NULL,
              end_time TIME NOT NULL,
              is_available BOOLEAN DEFAULT true,
              created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc'::text, now()) NOT NULL,
              updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc'::text, now()) NOT NULL,
              UNIQUE (professional_id, day_of_week)
            );

            -- Configurar políticas de acesso RLS (Row Level Security)
            ALTER TABLE public.working_hours ENABLE ROW LEVEL SECURITY;

            -- Permitir ao proprietário gerenciar seus próprios horários
            CREATE POLICY "Usuários podem gerenciar seus próprios horários"
            ON public.working_hours
            FOR ALL
            USING (auth.uid() = professional_id);

            -- Permitir leitura pública para qualquer pessoa (para o link público de agendamento)
            CREATE POLICY "Horários são visíveis publicamente"
            ON public.working_hours
            FOR SELECT
            USING (true);
          END IF;
        END
        $$;`;
        
        const { error: sqlError } = await supabase.rpc('exec_sql', { sql });
        
        if (!sqlError) {
          // Verificar se a tabela foi criada
          const tableCreated = await this.checkTableExists();
          if (tableCreated) {
            console.log('Tabela working_hours criada com sucesso via exec_sql');
            return true;
          }
        }
      } catch (error) {
        console.warn('Erro ao chamar exec_sql para criar tabela:', error);
        // Falha, mas não lança exceção
      }
      
      console.error('Não foi possível criar a tabela working_hours automaticamente');
      return false;
    } catch (error) {
      console.error('Erro ao criar tabela working_hours:', error);
      return false;
    }
  }
}; 