import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Support = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState('faq');
  
  // Verificar se há uma âncora na URL ao carregar a página
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#contact') {
      setActiveTab('contact');
    }
  }, []);

  // Atualizar a URL quando mudar a tab
  useEffect(() => {
    if (activeTab === 'contact') {
      window.history.replaceState(null, document.title, window.location.pathname + '#contact');
    } else {
      window.history.replaceState(null, document.title, window.location.pathname);
    }
  }, [activeTab]);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Aqui você implementaria a lógica para enviar o formulário
    console.log('Formulário enviado:', formData);
    setFormSubmitted(true);
    // Resetar formulário
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    
    // Simular retorno à visualização normal após 3 segundos
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };
  
  const faqs = [
    {
      question: 'Como eu começo a usar o AgendeDiva?',
      answer: 'Para começar a usar o AgendeDiva, basta criar uma conta gratuita em nosso site e você terá acesso a todos os recursos por 15 dias sem custo. Após esse período, você pode escolher assinar nosso plano mensal.'
    },
    {
      question: 'Posso personalizar minha página de agendamento?',
      answer: 'Sim! Você pode personalizar completamente sua página de agendamento com suas cores, logotipo e informações específicas do seu negócio.'
    },
    {
      question: 'Como meus clientes podem agendar serviços comigo?',
      answer: 'Seus clientes podem agendar serviços através do seu link personalizado, onde terão acesso a todos os seus serviços disponíveis e horários.'
    },
    {
      question: 'Quanto custa usar o AgendeDiva?',
      answer: 'O AgendeDiva tem um plano único de R$19,90 por mês, com acesso a todas as funcionalidades. Você pode testar gratuitamente por 15 dias antes de decidir assinar.'
    },
    {
      question: 'Como funciona o sistema de notificações?',
      answer: 'O AgendeDiva envia notificações automáticas via WhatsApp para você e seus clientes sobre agendamentos, confirmações, lembretes e cancelamentos.'
    },
    {
      question: 'Posso acessar o AgendeDiva pelo celular?',
      answer: 'Sim, o AgendeDiva é completamente responsivo e pode ser acessado de qualquer dispositivo, incluindo celulares e tablets.'
    },
    {
      question: 'Como cancelo minha assinatura?',
      answer: 'Você pode cancelar sua assinatura a qualquer momento através da seção "Configurações" e depois "Faturamento" da sua conta, sem taxas adicionais.'
    },
    {
      question: 'Existe algum limite de agendamentos ou clientes?',
      answer: 'Não, você pode gerenciar quantos agendamentos e clientes desejar, sem limitações, independentemente do número de profissionais.'
    }
  ];
  
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <div className="container mx-auto px-4">
          {/* Header com gradiente */}
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
              Suporte AgendeDiva
            </h1>
            <p className="text-lg text-gray-600 max-w-xl mx-auto">
              Estamos aqui para ajudar você a aproveitar ao máximo sua experiência com o AgendeDiva.
            </p>
          </div>
          
          {/* Tabs */}
          <div className="flex justify-center mb-10">
            <div className="bg-white rounded-full shadow-md p-1 flex">
              <button 
                onClick={() => setActiveTab('faq')}
                className={`px-6 py-2 rounded-full text-sm md:text-base font-medium transition-all ${
                  activeTab === 'faq' 
                    ? 'bg-gradient-to-r from-diva-pink to-diva-purple text-white shadow-md' 
                    : 'text-gray-600 hover:text-diva-purple'
                }`}
              >
                Perguntas Frequentes
              </button>
              <button 
                onClick={() => setActiveTab('contact')}
                className={`px-6 py-2 rounded-full text-sm md:text-base font-medium transition-all ${
                  activeTab === 'contact' 
                    ? 'bg-gradient-to-r from-diva-pink to-diva-purple text-white shadow-md' 
                    : 'text-gray-600 hover:text-diva-purple'
                }`}
              >
                Contato
              </button>
            </div>
          </div>
          
          {/* Conteúdo das Tabs */}
          <div className="max-w-4xl mx-auto">
            {activeTab === 'faq' && (
              <div className="bg-white rounded-xl shadow-md overflow-hidden">
                <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
                <div className="p-6 md:p-8">
                  <h2 className="text-2xl font-bold text-center text-diva-dark mb-8">
                    Perguntas Frequentes
                  </h2>
                  
                  <div className="space-y-6">
                    {faqs.map((faq, index) => (
                      <div key={index} className="border-b border-gray-100 pb-6 last:border-b-0 last:pb-0">
                        <h3 className="text-lg font-medium text-diva-dark mb-2">
                          {faq.question}
                        </h3>
                        <p className="text-gray-600">
                          {faq.answer}
                        </p>
                      </div>
                    ))}
                  </div>
                  
                  <div className="mt-8 pt-6 border-t border-gray-100 text-center">
                    <p className="text-gray-600">
                      Não encontrou o que procurava?{' '}
                      <button 
                        onClick={() => setActiveTab('contact')}
                        className="text-diva-pink font-medium hover:underline"
                      >
                        Entre em contato conosco
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            )}
            
            {activeTab === 'contact' && (
              <div id="contact" className="bg-white rounded-xl shadow-md overflow-hidden">
                <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
                <div className="p-6 md:p-8">
                  <h2 className="text-2xl font-bold text-center text-diva-dark mb-8">
                    Entre em Contato
                  </h2>
                  
                  {formSubmitted ? (
                    <div className="text-center py-8">
                      <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <h3 className="text-xl font-medium text-diva-dark mb-2">Mensagem Enviada!</h3>
                      <p className="text-gray-600">
                        Obrigado por entrar em contato. Responderemos o mais breve possível.
                      </p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                            Nome Completo
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                          />
                        </div>
                        <div>
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                            E-mail
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                          />
                        </div>
                      </div>
                      
                      <div>
                        <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                          Assunto
                        </label>
                        <select
                          id="subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                        >
                          <option value="">Selecione um assunto</option>
                          <option value="Dúvida sobre recursos">Dúvida sobre recursos</option>
                          <option value="Problema técnico">Problema técnico</option>
                          <option value="Faturamento">Faturamento</option>
                          <option value="Sugestão">Sugestão</option>
                          <option value="Outros">Outros</option>
                        </select>
                      </div>
                      
                      <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                          Mensagem
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                          rows={6}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-diva-purple focus:border-diva-purple"
                        ></textarea>
                      </div>
                      
                      <div className="text-center">
                        <button
                          type="submit"
                          className="px-8 py-3 bg-gradient-to-r from-diva-pink to-diva-purple text-white font-medium rounded-md shadow-md hover:shadow-lg transition-all"
                        >
                          Enviar Mensagem
                        </button>
                      </div>
                    </form>
                  )}
                  
                  <div className="mt-8 pt-6 border-t border-gray-100">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="text-center md:text-left">
                        <h3 className="text-lg font-medium text-diva-dark mb-2">E-mail de Contato</h3>
                        <a href="mailto:contato@agendediva.com.br" className="text-diva-pink hover:underline">
                          contato@agendediva.com.br
                        </a>
                      </div>
                      <div className="text-center md:text-right">
                        <h3 className="text-lg font-medium text-diva-dark mb-2">Horário de Atendimento</h3>
                        <p className="text-gray-600">
                          Segunda a Sexta: 9h às 18h
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Support; 