import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

interface LegalPageLayoutProps {
  title: string;
  lastUpdated: string;
  children: React.ReactNode;
}

const LegalPageLayout: React.FC<LegalPageLayoutProps> = ({ 
  title, 
  lastUpdated, 
  children 
}) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <div className="container mx-auto px-4">
          {/* Navegação das páginas legais */}
          <div className="flex flex-wrap justify-center mb-8 gap-2 md:gap-6">
            <Link 
              to="/termos-de-servico" 
              className={`text-sm md:text-base px-3 py-2 rounded-full ${title === "Termos de Serviço" 
                ? "bg-gradient-to-r from-diva-pink to-diva-purple text-white font-medium shadow-md" 
                : "text-diva-dark hover:bg-gray-100 transition-colors"}`}
            >
              Termos de Serviço
            </Link>
            <Link 
              to="/politica-de-privacidade" 
              className={`text-sm md:text-base px-3 py-2 rounded-full ${title === "Política de Privacidade" 
                ? "bg-gradient-to-r from-diva-pink to-diva-purple text-white font-medium shadow-md" 
                : "text-diva-dark hover:bg-gray-100 transition-colors"}`}
            >
              Política de Privacidade
            </Link>
            <Link 
              to="/politica-de-cookies" 
              className={`text-sm md:text-base px-3 py-2 rounded-full ${title === "Política de Cookies" 
                ? "bg-gradient-to-r from-diva-pink to-diva-purple text-white font-medium shadow-md" 
                : "text-diva-dark hover:bg-gray-100 transition-colors"}`}
            >
              Política de Cookies
            </Link>
            <Link 
              to="/politica-de-reembolso" 
              className={`text-sm md:text-base px-3 py-2 rounded-full ${title === "Política de Reembolso" 
                ? "bg-gradient-to-r from-diva-pink to-diva-purple text-white font-medium shadow-md" 
                : "text-diva-dark hover:bg-gray-100 transition-colors"}`}
            >
              Política de Reembolso
            </Link>
          </div>
          
          <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
            {/* Gradiente do topo */}
            <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
            
            <div className="p-6 md:p-8">
              <h1 className="text-2xl md:text-3xl font-bold text-center text-diva-dark mb-2">
                {title}
              </h1>
              <p className="text-center text-gray-500 mb-8 pb-4 border-b border-gray-100">
                Última atualização: {lastUpdated}
              </p>
              
              <div className="prose prose-diva max-w-none text-diva-dark/80">
                {children}
              </div>
              
              <div className="mt-12 pt-6 border-t border-gray-100">
                <p className="text-center text-gray-500 text-sm">
                  Se tiver alguma dúvida sobre este documento, entre em contato através do e-mail{' '}
                  <a href="mailto:contato@agendediva.com.br" className="text-diva-pink hover:underline">
                    contato@agendediva.com.br
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LegalPageLayout; 