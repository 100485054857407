import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Calendar, Link as LinkIcon, CreditCard, BarChart2, MessageSquare, Users, Clock } from "lucide-react";
import "../styles/features.css";

const FeatureItem = ({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) => (
  <div className="bg-white rounded-xl shadow-sm border border-diva-pink-light/20 p-6 transition-all duration-300 hover:shadow-md hover:-translate-y-1">
    <div className="bg-gradient-to-r from-diva-pink-light/30 to-diva-purple-light/30 p-3 rounded-xl inline-block mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-bold text-diva-dark mb-3">{title}</h3>
    <p className="text-diva-dark/70">{description}</p>
  </div>
);

const Features = () => {
  const features = [
    {
      icon: <LinkIcon className="w-8 h-8 text-diva-pink" />,
      title: "Link Personalizado",
      description: "Crie seu próprio link personalizado para compartilhar com clientes. Exemplo: agendediva.com/seunegocio",
      details: [
        "Link exclusivo e profissional",
        "Personalização com cores e logo",
        "Fácil compartilhamento em redes sociais",
        "Integração com Instagram e WhatsApp"
      ]
    },
    {
      icon: <Calendar className="w-8 h-8 text-diva-pink" />,
      title: "Agendamento Online",
      description: "Sistema completo de agendamentos online disponível 24/7 para seus clientes marcarem horários",
      details: [
        "Sistema de notificações automáticas",
        "Configuração de horários disponíveis",
        "Bloqueio de horários para pausa",
        "Interface amigável para os clientes"
      ]
    },
    {
      icon: <Users className="w-8 h-8 text-diva-pink" />,
      title: "Gestão de Clientes",
      description: "Cadastro completo com histórico de serviços, preferências e contatos para fidelizar sua clientela",
      details: [
        "Histórico de serviços realizados",
        "Preferências e observações por cliente",
        "Categorização e tags personalizadas",
        "Lembretes de retorno automáticos"
      ]
    },
    {
      icon: <CreditCard className="w-8 h-8 text-diva-pink" />,
      title: "Pagamentos Integrados",
      description: "Aceite pagamentos via PIX, cartão e boleto diretamente pela plataforma, com controle total",
      details: [
        "Integração com principais gateways de pagamento",
        "Recebimento via PIX instantâneo",
        "Controle de pagamentos pendentes",
        "Notificações automáticas"
      ]
    },
    {
      icon: <BarChart2 className="w-8 h-8 text-diva-pink" />,
      title: "Controle Financeiro",
      description: "Acompanhe receitas, despesas e lucro com gráficos intuitivos e relatórios detalhados",
      details: [
        "Dashboard financeiro simplificado",
        "Relatórios semanais e mensais",
        "Análise de serviços mais lucrativos",
        "Exportação para Excel e PDF"
      ]
    },
    {
      icon: <MessageSquare className="w-8 h-8 text-diva-pink" />,
      title: "Lembretes e CRM",
      description: "Envie lembretes automáticos de agendamentos e promoções personalizadas para seus clientes",
      details: [
        "Lembretes automáticos via WhatsApp",
        "Confirmação de presença automatizada",
        "Mensagens personalizadas para clientes",
        "Pesquisas de satisfação pós-atendimento"
      ]
    },
    {
      icon: <Clock className="w-8 h-8 text-diva-pink" />,
      title: "Gestão de Serviços",
      description: "Cadastre todos os seus serviços com preços, duração e descrição detalhada para seus clientes",
      details: [
        "Categorização por tipo de serviço",
        "Descrição personalizada dos serviços",
        "Definição de tempo de duração",
        "Pacotes e combos com desconto"
      ]
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />

      <main className="flex-grow pt-24">
        {/* Hero section */}
        <section className="section-padding bg-gradient-to-br from-diva-pink-light/30 via-white to-diva-purple-light/30">
          <div className="container mx-auto">
            <div className="text-center max-w-3xl mx-auto">
              <h1 className="text-4xl md:text-5xl font-bold text-diva-dark mb-6 font-serif">
                Recursos completos para seu negócio de beleza
              </h1>
              <p className="text-xl text-diva-dark/70 mb-8">
                Conheça todas as ferramentas que o AgendeDiva oferece para transformar
                a gestão do seu salão ou ateliê de beleza
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Link to="/signup">
                  <Button className="w-full sm:w-auto diva-button-primary">
                    Experimente grátis por 15 dias
                  </Button>
                </Link>
                <Link to="/pricing">
                  <Button variant="outline" className="w-full sm:w-auto border-diva-purple text-diva-purple hover:bg-diva-purple hover:text-white">
                    Ver preços
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Features grid */}
        <section className="section-padding">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <FeatureItem 
                  key={index}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Feature details */}
        <section className="py-20 bg-diva-light">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className={`container mx-auto py-16 ${index !== 0 ? 'border-t border-diva-pink-light/20' : ''}`}
            >
              <div className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center gap-12`}>
                <div className="md:w-1/2">
                  <div className="bg-gradient-to-br from-diva-pink-light/50 to-diva-purple-light/50 rounded-3xl p-1">
                    <div className="bg-white rounded-3xl p-8 shadow-lg">
                      <div className="bg-gradient-to-r from-diva-pink-light to-diva-purple-light p-4 rounded-2xl inline-block mb-6">
                        {feature.icon}
                      </div>
                      <h2 className="text-3xl font-bold text-diva-dark mb-4 font-serif">{feature.title}</h2>
                      <p className="text-lg text-diva-dark/70 mb-6">{feature.description}</p>
                      
                      <ul className="space-y-3">
                        {feature.details.map((detail, detailIndex) => (
                          <li key={detailIndex} className="flex items-start">
                            <div className="flex-shrink-0 mt-1 flex items-center justify-center w-5 h-5 rounded-full bg-diva-pink mr-3">
                              <svg className="w-3 h-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                              </svg>
                            </div>
                            <span className="text-diva-dark/80">{detail}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                
                <div className="md:w-1/2 h-64 md:h-96 bg-gradient-to-br from-diva-pink-light/10 to-diva-purple-light/10 rounded-xl flex items-center justify-center overflow-hidden">
                  <div className="relative w-full h-full flex items-center justify-center">
                    {/* Elementos decorativos específicos para cada feature */}
                    {feature.title === "Link Personalizado" && (
                      <div className="flex flex-col items-center">
                        <div className="w-16 h-16 md:w-24 md:h-24 bg-gradient-to-br from-diva-pink to-diva-purple rounded-xl flex items-center justify-center mb-4">
                          <LinkIcon className="w-10 h-10 md:w-14 md:h-14 text-white" />
                        </div>
                        <div className="text-center">
                          <div className="text-diva-dark font-medium">agendediva.com/</div>
                          <div className="text-diva-pink font-bold text-xl">seusalao</div>
                        </div>
                      </div>
                    )}
                    {feature.title === "Agendamento Online" && (
                      <div className="w-3/4 h-3/4 bg-white rounded-xl shadow-xl p-4 flex flex-col">
                        <div className="border-b border-gray-200 pb-2 mb-2 flex justify-between items-center">
                          <div className="font-bold text-diva-dark">Agendamentos</div>
                          <Calendar className="h-5 w-5 text-diva-pink" />
                        </div>
                        <div className="flex-1 grid grid-cols-3 gap-2">
                          {[...Array(9)].map((_, i) => (
                            <div
                              key={i}
                              className={`rounded p-1 text-xs text-center ${
                                i % 3 === 0
                                  ? "bg-diva-pink/10 text-diva-pink"
                                  : i % 3 === 1
                                  ? "bg-gray-100"
                                  : "bg-gray-50"
                              }`}
                            >
                              {i % 3 === 0 ? "Ocupado" : "Livre"}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {feature.title === "Gestão de Clientes" && (
                      <div className="w-3/4 h-3/4 bg-white rounded-xl shadow-xl p-4">
                        <div className="border-b border-gray-200 pb-2 mb-2 flex justify-between items-center">
                          <div className="font-bold text-diva-dark">Clientes</div>
                          <Users className="h-5 w-5 text-diva-pink" />
                        </div>
                        {[...Array(4)].map((_, i) => (
                          <div
                            key={i}
                            className="flex items-center py-2 border-b border-gray-100"
                          >
                            <div className="w-8 h-8 rounded-full bg-gradient-to-br from-diva-pink-light to-diva-purple-light mr-2 flex items-center justify-center text-white text-xs font-bold">
                              {String.fromCharCode(65 + i)}
                            </div>
                            <div className="flex-1">
                              <div className="text-sm font-medium">Cliente {i + 1}</div>
                              <div className="text-xs text-gray-500">Último atendimento: {i * 5 + 2} dias atrás</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {feature.title === "Pagamentos Integrados" && (
                      <div className="w-3/4 h-3/4 flex flex-col gap-4 items-center justify-center">
                        <div className="bg-white p-5 rounded-2xl shadow-xl w-52 flex flex-col items-center">
                          <div className="w-16 h-16 bg-gradient-to-br from-green-400 to-green-600 rounded-xl flex items-center justify-center mb-2">
                            <svg className="w-10 h-10 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21 12L9 12M21 6L9 6M21 18H9M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12ZM5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6ZM5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </div>
                          <div className="text-center">
                            <div className="font-bold text-gray-800">Pague via PIX</div>
                            <div className="text-sm text-gray-500">Instantâneo</div>
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <div className="w-5 h-5 rounded-full border-2 border-diva-pink"></div>
                          <div className="w-5 h-5 rounded-full bg-diva-pink"></div>
                          <div className="w-5 h-5 rounded-full border-2 border-diva-pink"></div>
                        </div>
                      </div>
                    )}
                    {feature.title === "Controle Financeiro" && (
                      <div className="w-3/4 h-3/4 bg-white rounded-xl shadow-xl p-4">
                        <div className="border-b border-gray-200 pb-2 mb-2 flex justify-between items-center">
                          <div className="font-bold text-diva-dark">Dashboard Financeiro</div>
                          <BarChart2 className="h-5 w-5 text-diva-pink" />
                        </div>
                        <div className="flex-1 h-32 pt-4">
                          <div className="relative h-full w-full">
                            {/* Barras de gráfico decorativas */}
                            {[...Array(7)].map((_, i) => (
                              <div
                                key={i}
                                className={`chart-bar chart-bar-${i+1}`}
                              ></div>
                            ))}
                            <div className="chart-base-line"></div>
                          </div>
                        </div>
                        <div className="flex justify-between text-xs text-gray-500 pt-2">
                          <span>Seg</span>
                          <span>Ter</span>
                          <span>Qua</span>
                          <span>Qui</span>
                          <span>Sex</span>
                          <span>Sáb</span>
                          <span>Dom</span>
                        </div>
                      </div>
                    )}
                    {feature.title === "Lembretes e CRM" && (
                      <div className="w-3/4 max-w-xs bg-green-50 rounded-xl shadow-lg p-4 border-2 border-green-200">
                        <div className="flex items-start">
                          <div className="w-10 h-10 rounded-full bg-green-500 flex items-center justify-center flex-shrink-0 mr-2">
                            <MessageSquare className="w-5 h-5 text-white" />
                          </div>
                          <div className="flex-1">
                            <div className="bg-white rounded-lg p-3 shadow-sm relative">
                              <div className="absolute left-0 top-4 transform -translate-x-2 rotate-45 w-2 h-2 bg-white"></div>
                              <p className="text-sm mb-1">🎉 <span className="font-bold">Olá, Juliana!</span></p>
                              <p className="text-xs text-gray-700">Não esqueça do seu horário de Corte com Hidratação amanhã às 14:00h.</p>
                              <div className="mt-2 text-xs text-gray-500">Confirmar? ✅ Sim | ❌ Não</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {feature.title === "Gestão de Serviços" && (
                      <div className="w-3/4 h-3/4 bg-white rounded-xl shadow-xl p-4">
                        <div className="border-b border-gray-200 pb-2 mb-2 flex justify-between items-center">
                          <div className="font-bold text-diva-dark">Serviços</div>
                          <Clock className="h-5 w-5 text-diva-pink" />
                        </div>
                        <div className="space-y-3 pt-2">
                          {["Corte", "Hidratação", "Coloração", "Manicure"].map((service, i) => (
                            <div key={i} className="flex justify-between items-center p-2 rounded bg-gray-50 hover:bg-gray-100 transition-colors">
                              <div>
                                <div className="font-medium">{service}</div>
                                <div className="text-xs text-gray-500">{30 + i * 15} min</div>
                              </div>
                              <div className="font-medium text-diva-pink">
                                R$ {(i + 1) * 35},00
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>

        {/* CTA section */}
        <section className="section-padding bg-gradient-diva text-white">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 font-serif">
              Pronta para começar?
            </h2>
            <p className="text-white/90 max-w-2xl mx-auto mb-8 text-lg">
              Junte-se a milhares de profissionais da beleza que já elevaram seus negócios 
              com o AgendeDiva. Experimente gratuitamente por 15 dias!
            </p>
            <Link to="/signup">
              <Button className="bg-white hover:bg-diva-pink-light text-diva-dark px-8 py-3 text-lg font-medium">
                Criar minha conta agora
              </Button>
            </Link>
            <p className="text-white/70 mt-4">
              Sem necessidade de cartão de crédito • Cancele quando quiser
            </p>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Features;
