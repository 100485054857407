import React, { useState } from 'react';
import { ScheduleSettings as ScheduleSettingsComponent } from '@/components/settings/ScheduleSettings';
import { Separator } from '@/components/ui/separator';
import { CalendarRange, ArrowLeft, Info, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';

export default function ScheduleSettings() {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 space-y-4 overflow-x-hidden max-w-4xl">
      {/* Cabeçalho com navegação */}
      <div className="flex items-center justify-between gap-2">
        <div className="flex-1">
          <h1 className="text-xl sm:text-2xl font-bold tracking-tight bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
            Configurações da Agenda
          </h1>
        </div>
        <Link to="/negocio" className="shrink-0">
          <Button 
            variant="outline" 
            size="sm"
            className="border-diva-pink-light text-diva-purple hover:bg-diva-pink/5"
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Voltar
          </Button>
        </Link>
      </div>

      <Separator className="bg-diva-pink/10" />

      {/* Conteúdo principal */}
      <Card className="bg-white shadow-sm rounded-lg border border-diva-pink-light/10 overflow-hidden">
        <CardContent className="p-0">
          <div className="px-4 py-3 sm:px-6 sm:py-3 bg-gradient-to-r from-diva-pink/5 to-diva-purple/5 border-b border-diva-pink-light/10 flex justify-between items-center">
            <h2 className="flex items-center text-base font-medium text-diva-dark">
              <CalendarRange className="h-5 w-5 mr-2 text-diva-pink" />
              Horários de Atendimento
            </h2>
            <Button 
              variant="ghost" 
              size="sm" 
              className="text-diva-purple hover:text-diva-pink hover:bg-transparent p-1 h-auto"
              onClick={() => setShowInfo(!showInfo)}
            >
              <div className="flex items-center text-xs">
                <Info className="h-4 w-4 mr-1" />
                {showInfo ? (
                  <>
                    <span className="mr-1">Ocultar ajuda</span>
                    <ChevronUp className="h-3 w-3" />
                  </>
                ) : (
                  <>
                    <span className="mr-1">Mostrar ajuda</span>
                    <ChevronDown className="h-3 w-3" />
                  </>
                )}
              </div>
            </Button>
          </div>
          
          {showInfo && (
            <div className="p-3 bg-diva-light/20 border-b border-diva-pink-light/10 text-sm text-diva-purple">
              <p className="mb-2">
                As configurações feitas aqui determinarão quais horários estarão disponíveis para seus clientes agendarem serviços.
              </p>
              <div className="flex gap-2 items-center text-xs text-diva-purple-dark">
                <span>•</span>
                <span>Lembre-se de salvar as configurações para cada dia individualmente.</span>
              </div>
              <div className="flex gap-2 items-center text-xs text-diva-purple-dark">
                <span>•</span>
                <span>Dias marcados como "Indisponível" não aparecem como opções para seus clientes.</span>
              </div>
            </div>
          )}
          
          <div className="p-4">
            <ScheduleSettingsComponent />
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 