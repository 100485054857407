import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { Award, Heart, TrendingUp, Lightbulb, Target, Clock } from 'lucide-react';

const About = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <div className="container mx-auto px-4">
          {/* Header com gradiente */}
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
              Sobre a AgendeDiva
            </h1>
            <p className="text-lg text-gray-600 max-w-xl mx-auto">
              Conheça nossa história, missão, visão e valores que guiam tudo o que fazemos.
            </p>
          </div>
          
          {/* Seção de História */}
          <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden mb-16">
            <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
            <div className="p-6 md:p-8">
              <h2 className="text-2xl font-bold text-diva-dark mb-6">
                Nossa História
              </h2>
              
              <div className="prose prose-pink max-w-none text-gray-600">
                <p className="mb-4">
                  A AgendeDiva nasceu em 2025, quando o fundador Felipe Alves, 
                  um desenvolvedor full-stack com experiência em startups de tecnologia, 
                  percebeu a dificuldade que profissionais da beleza enfrentavam para gerenciar 
                  os agendamentos de seus serviços.
                </p>
                
                <p className="mb-4">
                  Felipe notou que muitos profissionais marcavam horários por WhatsApp e redes sociais, 
                  resultando em confusões e horários sobrepostos. Eles tentavam usar planilhas e 
                  até mesmo algumas soluções de mercado, mas nenhuma atendia completamente às 
                  necessidades específicas de profissionais autônomos da beleza.
                </p>
                
                <p className="mb-4">
                  Foi então que ele decidiu criar uma solução simples, mas completa, que ajudasse 
                  profissionais de beleza a organizarem seus agendamentos. Com seu conhecimento em
                  desenvolvimento de software, Felipe criou uma plataforma focada nas reais 
                  necessidades do mercado da beleza.
                </p>
                
                <p className="mb-4">
                  No início, a plataforma foi testada apenas com um pequeno grupo de profissionais da beleza. 
                  O feedback positivo e as sugestões de melhorias foram cruciais para o desenvolvimento 
                  do que hoje conhecemos como AgendeDiva.
                </p>
                
                <p>
                  A AgendeDiva continua em desenvolvimento, sempre com o objetivo de facilitar 
                  a vida dos profissionais da beleza e melhorar a experiência de seus clientes.
                </p>
              </div>
            </div>
          </div>
          
          {/* Grid de Missão, Visão e Valores */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {/* Missão */}
            <motion.div 
              className="bg-white rounded-xl shadow-md overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <div className="h-2 bg-diva-pink"></div>
              <div className="p-6 md:p-8">
                <div className="flex items-center mb-4">
                  <div className="bg-pink-100 p-3 rounded-full mr-4">
                    <Target className="h-6 w-6 text-diva-pink" />
                  </div>
                  <h2 className="text-2xl font-bold text-diva-dark">
                    Missão
                  </h2>
                </div>
                
                <p className="text-gray-600">
                  Simplificar a gestão de agendamentos para profissionais da beleza, permitindo 
                  que dediquem mais tempo ao que realmente importa: seus clientes e seu crescimento 
                  profissional. Queremos democratizar o acesso a ferramentas profissionais de gestão, 
                  tornando-as acessíveis para profissionais de todos os portes.
                </p>
              </div>
            </motion.div>
            
            {/* Visão */}
            <motion.div 
              className="bg-white rounded-xl shadow-md overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <div className="h-2 bg-diva-purple"></div>
              <div className="p-6 md:p-8">
                <div className="flex items-center mb-4">
                  <div className="bg-purple-100 p-3 rounded-full mr-4">
                    <Lightbulb className="h-6 w-6 text-diva-purple" />
                  </div>
                  <h2 className="text-2xl font-bold text-diva-dark">
                    Visão
                  </h2>
                </div>
                
                <p className="text-gray-600">
                  Ser reconhecida como a plataforma de gestão mais querida e utilizada por 
                  profissionais de beleza no Brasil até 2025, e expandir nossa atuação para 
                  toda a América Latina até 2030. Queremos transformar a maneira como os 
                  serviços de beleza são agendados e gerenciados, criando uma experiência 
                  única tanto para profissionais quanto para clientes.
                </p>
              </div>
            </motion.div>
            
            {/* Valores */}
            <motion.div 
              className="bg-white rounded-xl shadow-md overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              viewport={{ once: true }}
            >
              <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
              <div className="p-6 md:p-8">
                <div className="flex items-center mb-4">
                  <div className="bg-gradient-to-r from-pink-100 to-purple-100 p-3 rounded-full mr-4">
                    <Heart className="h-6 w-6 text-diva-pink" />
                  </div>
                  <h2 className="text-2xl font-bold text-diva-dark">
                    Valores
                  </h2>
                </div>
                
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <Award className="h-5 w-5 text-diva-pink mt-0.5 mr-2 flex-shrink-0" />
                    <p className="text-gray-600"><span className="font-medium text-diva-dark">Excelência:</span> Buscamos constantemente melhorar nossos produtos e serviços.</p>
                  </li>
                  <li className="flex items-start">
                    <Heart className="h-5 w-5 text-diva-pink mt-0.5 mr-2 flex-shrink-0" />
                    <p className="text-gray-600"><span className="font-medium text-diva-dark">Empatia:</span> Entendemos as necessidades dos nossos usuários e trabalhamos para atendê-las.</p>
                  </li>
                  <li className="flex items-start">
                    <TrendingUp className="h-5 w-5 text-diva-pink mt-0.5 mr-2 flex-shrink-0" />
                    <p className="text-gray-600"><span className="font-medium text-diva-dark">Inovação:</span> Estamos sempre buscando novas formas de melhorar a experiência dos nossos usuários.</p>
                  </li>
                  <li className="flex items-start">
                    <Clock className="h-5 w-5 text-diva-pink mt-0.5 mr-2 flex-shrink-0" />
                    <p className="text-gray-600"><span className="font-medium text-diva-dark">Confiabilidade:</span> Construímos um serviço no qual você pode confiar todos os dias.</p>
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
          
          {/* Timeline de Crescimento */}
          <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden mb-16">
            <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
            <div className="p-6 md:p-8">
              <h2 className="text-2xl font-bold text-diva-dark mb-6">
                Nossa Jornada
              </h2>
              
              <div className="relative border-l-2 border-diva-pink-light pl-8 py-2 space-y-10">
                {/* 2025 */}
                <div className="relative">
                  <div className="absolute -left-10 mt-1.5 h-5 w-5 rounded-full border-4 border-diva-pink bg-white"></div>
                  <div>
                    <h3 className="text-xl font-bold text-diva-pink">2025</h3>
                    <p className="mt-2 text-gray-600">
                      Início do desenvolvimento da plataforma pelo fundador Felipe Alves.
                      Primeiros testes com um grupo seleto de profissionais de beleza.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Equipe */}
          <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
            <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
            <div className="p-6 md:p-8">
              <h2 className="text-2xl font-bold text-diva-dark mb-6">
                Conheça Nossa Equipe
              </h2>
              
              <div className="flex justify-center">
                {/* Fundador */}
                <div className="text-center">
                  <div className="relative w-32 h-32 mx-auto mb-4">
                    <div className="absolute inset-0 rounded-full bg-gradient-to-r from-diva-pink to-diva-purple p-1">
                      <div className="h-full w-full rounded-full bg-white overflow-hidden">
                        <div className="h-full w-full bg-gray-200 flex items-center justify-center text-white">
                          <span className="text-3xl font-bold">FA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="text-lg font-bold text-diva-dark">Felipe Alves</h3>
                  <p className="text-diva-purple font-medium">Fundador e CTO</p>
                  <p className="text-sm text-gray-600 mt-2">
                    Desenvolvedor full-stack com experiência em startups de tecnologia
                  </p>
                </div>
              </div>
              
              <div className="text-center mt-10">
                <p className="text-gray-600">
                  Além do nosso fundador, temos uma equipe dedicada de designers, desenvolvedores, especialistas em atendimento ao cliente e profissionais de marketing, todos comprometidos em tornar a AgendeDiva a melhor plataforma para profissionais da beleza.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default About; 