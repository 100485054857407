import { useState, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { serviceService } from '@/services/serviceService';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Service } from '@/types/appointment';
import { ServiceFormDialog } from '@/components/services/ServiceFormDialog';
import { useToast } from '@/hooks/use-toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Clock,
  DollarSign,
  Scissors,
  Search,
  Edit,
  Trash2,
  Plus,
  MoreVertical,
  PlusCircle,
  Power,
  CheckCircle,
  XCircle,
} from 'lucide-react';
import { Input } from '@/components/ui/input';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { cn } from '@/lib/utils';
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const Services = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [services, setServices] = useState<Service[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [serviceToDelete, setServiceToDelete] = useState<Service | undefined>();
  const [lastCreatedService, setLastCreatedService] = useState<Partial<Service> | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      loadServices();
    }
  }, [user]);

  const loadServices = async () => {
    try {
      setIsLoading(true);
      const data = await serviceService.listByProfessional(user!.id);
      setServices(data);
    } catch (error) {
      console.error('Erro ao carregar serviços:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível carregar os serviços.',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleActive = async (serviceId: string, currentStatus: boolean) => {
    try {
      await serviceService.update(serviceId, { active: !currentStatus });
      loadServices();
      toast({
        title: 'Status atualizado',
        description: `Serviço ${!currentStatus ? 'ativado' : 'desativado'} com sucesso!`,
      });
    } catch (error) {
      console.error('Erro ao atualizar status do serviço:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível atualizar o status do serviço.',
        variant: 'destructive',
      });
    }
  };

  const handleEdit = (service: Service) => {
    setSelectedService(service);
    setIsFormOpen(true);
  };

  const handleDelete = async () => {
    if (!serviceToDelete) return;

    try {
      setIsDeleting(true);
      await serviceService.delete(serviceToDelete.id);
      loadServices();
      setServiceToDelete(undefined);
      toast({
        title: 'Serviço excluído',
        description: 'O serviço foi excluído com sucesso!',
      });
    } catch (error) {
      console.error('Erro ao excluir serviço:', error);
      
      // Mensagem de erro mais amigável
      let errorMessage = 'Não foi possível excluir o serviço.';
      
      // Verifica se o erro é do tipo Error e tem uma mensagem
      if (error instanceof Error) {
        // Se for um erro conhecido, mostre a mensagem específica
        if (error.message.includes('violates foreign key constraint')) {
          errorMessage = 'Este serviço não pode ser excluído porque está sendo usado em agendamentos. Ele foi marcado como inativo.';
        } else {
          errorMessage = `Erro: ${error.message}`;
        }
      }
      
      toast({
        title: 'Erro',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleOpenForm = () => {
    setSelectedService(undefined);
    setIsFormOpen(true);
  };

  const handleServiceSuccess = (service: Service) => {
    loadServices();
    if (!selectedService) {
      setLastCreatedService({
        name: '',
        description: service.description,
        duration_minutes: service.duration_minutes,
        price: service.price,
        active: true
      });
    }
  };

  const getFilteredServices = () => {
    let filtered = services;
    
    if (searchTerm) {
      filtered = filtered.filter(service =>
        service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    return filtered;
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(price);
  };

  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    if (hours > 0) {
      return `${hours}h${mins > 0 ? mins + 'min' : ''}`;
    }
    
    return `${mins}min`;
  };

  const getAverageDuration = () => {
    if (services.length === 0) return '0min';
    
    const totalMinutes = services.reduce((sum, service) => sum + service.duration_minutes, 0);
    const averageMinutes = Math.round(totalMinutes / services.length);
    
    return formatDuration(averageMinutes);
  };

  const getMostPopularService = () => {
    if (services.length === 0) return 'Nenhum serviço';
    
    // Em um cenário real, isto seria baseado em dados de agendamentos
    // Para este exemplo, retornaremos o primeiro serviço da lista
    return services[0]?.name || 'Nenhum serviço';
  };

  const activeCount = services.filter(s => s.active).length;
  const filteredServices = getFilteredServices();

  return (
    <div className="container mx-auto py-8 px-4 space-y-8">
      {/* Cabeçalho */}
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-diva-dark">Serviços</h1>
          <Button 
            className="bg-gradient-to-r from-diva-pink to-diva-purple hover:opacity-90 text-white"
            onClick={handleOpenForm}
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Serviço
          </Button>
        </div>
      </div>

      {/* Cards de estatísticas */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        <Card className="bg-diva-light shadow-sm border-0">
          <CardContent className="p-6">
            <h3 className="text-diva-purple font-medium text-sm mb-1">Total de serviços</h3>
            <p className="text-4xl font-bold text-diva-dark">{services.length}</p>
          </CardContent>
        </Card>
        
        <Card className="bg-diva-light shadow-sm border-0">
          <CardContent className="p-6">
            <h3 className="text-diva-purple font-medium text-sm mb-1">Mais procurado</h3>
            <p className="text-xl font-bold text-diva-dark">{getMostPopularService()}</p>
          </CardContent>
        </Card>
        
        <Card className="bg-diva-light shadow-sm border-0">
          <CardContent className="p-6">
            <h3 className="text-diva-purple font-medium text-sm mb-1">Tempo médio</h3>
            <p className="text-4xl font-bold text-diva-dark">{getAverageDuration()}</p>
          </CardContent>
        </Card>
      </div>

      {/* Tabela de serviços */}
      <Card className="bg-white shadow-sm rounded-sm border-0 overflow-hidden">
        {/* Barra de pesquisa */}
        <div className="px-6 py-4 border-b border-diva-pink-light/30">
          <div className="flex justify-between items-center">
            <div className="relative max-w-md w-full">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-diva-pink h-4 w-4" />
              <Input
                placeholder="Buscar serviços..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-9 border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20"
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center py-12">
            <div className="w-8 h-8 border-4 border-diva-pink/20 border-t-diva-pink rounded-full animate-spin"></div>
          </div>
        ) : filteredServices.length === 0 ? (
          <div className="text-center py-12 px-6">
            <Scissors className="h-10 w-10 text-diva-pink-light mx-auto mb-3" />
            <h3 className="text-lg font-medium text-diva-dark mb-1">
              {searchTerm ? 'Nenhum serviço encontrado' : 'Nenhum serviço cadastrado'}
            </h3>
            <p className="text-diva-purple mb-4 max-w-md mx-auto">
              {searchTerm 
                ? 'Tente buscar com outros termos ou limpar a pesquisa' 
                : 'Adicione seu primeiro serviço para começar a receber agendamentos'}
            </p>
            <Button 
              onClick={searchTerm ? () => setSearchTerm('') : handleOpenForm} 
              variant={searchTerm ? "outline" : "default"}
              className={searchTerm ? "border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20" : "bg-gradient-to-r from-diva-pink to-diva-purple hover:opacity-90"}
            >
              {searchTerm ? 'Limpar pesquisa' : 'Adicionar Serviço'}
            </Button>
          </div>
        ) : (
          <div>
            {/* Cabeçalho da tabela */}
            <div className="grid grid-cols-12 gap-4 px-6 py-3 bg-gradient-to-r from-diva-pink-light/30 to-diva-purple-light/20 text-diva-dark text-sm font-medium">
              <div className="col-span-5">SERVIÇO</div>
              <div className="col-span-2 text-center">DURAÇÃO</div>
              <div className="col-span-2 text-center">PREÇO</div>
              <div className="col-span-2 text-center">STATUS</div>
              <div className="col-span-1 text-center">AÇÕES</div>
            </div>
            
            {/* Linhas da tabela */}
            {filteredServices.map((service) => (
              <div 
                key={service.id} 
                className="grid grid-cols-12 gap-4 px-6 py-4 border-t border-diva-pink-light/20 hover:bg-diva-light/30 transition-colors"
              >
                <div className="col-span-5">
                  <div className="flex flex-col">
                    <span className="font-medium text-diva-dark">{service.name}</span>
                    {service.description && (
                      <span className="text-sm text-diva-purple mt-1">{service.description}</span>
                    )}
                  </div>
                </div>
                
                <div className="col-span-2 flex items-center justify-center">
                  <span className="text-diva-purple font-medium">{formatDuration(service.duration_minutes)}</span>
                </div>
                
                <div className="col-span-2 flex items-center justify-center">
                  <span className="font-medium text-diva-dark">{formatPrice(service.price)}</span>
                </div>
                
                <div className="col-span-2 flex items-center justify-center">
                  <Badge 
                    className={cn(
                      "rounded-full px-3",
                      service.active 
                        ? "bg-green-100 text-green-800 hover:bg-green-100" 
                        : "bg-diva-purple-light/50 text-diva-purple hover:bg-diva-purple-light/60"
                    )}
                  >
                    {service.active ? "Ativo" : "Inativo"}
                  </Badge>
                </div>
                
                <div className="col-span-1 flex items-center justify-center gap-1">
                  <TooltipProvider delayDuration={300}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button 
                          onClick={() => handleToggleActive(service.id, service.active)}
                          variant="ghost"
                          size="sm"
                          className="h-7 w-7 p-0 rounded-full"
                        >
                          <div className={`p-1 rounded-full ${service.active ? "bg-diva-purple-light/30 text-diva-purple" : "bg-green-100 text-green-600"}`}>
                            {service.active ? <XCircle className="h-4 w-4" /> : <CheckCircle className="h-4 w-4" />}
                          </div>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        <p>{service.active ? "Desativar" : "Ativar"}</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button 
                          onClick={() => handleEdit(service)}
                          variant="ghost"
                          size="sm"
                          className="h-7 w-7 p-0 rounded-full"
                        >
                          <div className="p-1 rounded-full bg-diva-pink-light/30 text-diva-pink">
                            <Edit className="h-4 w-4" />
                          </div>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        <p>Editar</p>
                      </TooltipContent>
                    </Tooltip>

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button 
                          onClick={() => setServiceToDelete(service)}
                          variant="ghost"
                          size="sm"
                          className="h-7 w-7 p-0 rounded-full"
                        >
                          <div className="p-1 rounded-full bg-red-100 text-red-600">
                            <Trash2 className="h-4 w-4" />
                          </div>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        <p>Excluir</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>

      {/* Diálogos */}
      {isFormOpen && (
        <ServiceFormDialog
          open={isFormOpen}
          onOpenChange={setIsFormOpen}
          service={selectedService}
          professionalId={user?.id}
          onSuccess={handleServiceSuccess}
          lastCreatedService={lastCreatedService}
        />
      )}

      <AlertDialog open={!!serviceToDelete} onOpenChange={() => setServiceToDelete(undefined)}>
        <AlertDialogContent className="border-0 rounded-xl overflow-hidden">
          <AlertDialogHeader className="bg-gradient-to-r from-diva-pink-light/50 to-diva-purple-light/20 p-6">
            <AlertDialogTitle className="text-diva-dark">Excluir Serviço</AlertDialogTitle>
            <AlertDialogDescription className="text-diva-purple">
              Tem certeza que deseja excluir o serviço <strong>{serviceToDelete?.name}</strong>? Esta ação não pode ser desfeita.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="bg-diva-light/20 p-6">
            <AlertDialogCancel 
              disabled={isDeleting}
              className="border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20 hover:text-diva-pink hover:border-diva-pink"
            >
              Cancelar
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              disabled={isDeleting}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              {isDeleting ? (
                <>
                  <span className="animate-spin mr-1">↻</span>
                  Excluindo...
                </>
              ) : (
                'Excluir'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Services; 