
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

const HeroSection = () => {
  return (
    <section className="hero-gradient pt-32 pb-16 md:pt-40 md:pb-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col-reverse md:flex-row items-center">
          <div className="md:w-1/2 space-y-6 mt-8 md:mt-0 text-center md:text-left">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-diva-dark">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple">
                Revolucione
              </span>{" "}
              seu negócio de beleza
            </h1>
            
            <p className="text-lg md:text-xl text-diva-dark/80 max-w-lg mx-auto md:mx-0">
              Crie seu link personalizado e gerencie agendamentos, clientes e finanças em uma única plataforma por apenas R$19,90/mês.
            </p>
            
            <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4 pt-4">
              <Link to="/signup">
                <Button className="diva-button-primary w-full sm:w-auto">
                  Comece agora
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Button>
              </Link>
              <a href="#features">
                <Button variant="outline" className="border-diva-purple text-diva-purple hover:bg-diva-purple hover:text-white w-full sm:w-auto">
                  Conheça os recursos
                </Button>
              </a>
            </div>
            
            <div className="text-sm text-diva-dark/70">
              <p>✨ Experimente 15 dias grátis, sem compromisso</p>
              <p>✨ Cancele quando quiser</p>
            </div>
          </div>
          
          <div className="md:w-1/2 flex justify-center">
            <div className="relative w-full max-w-md">
              <div className="absolute inset-0 bg-gradient-to-r from-diva-pink/30 to-diva-purple/30 rounded-3xl blur-3xl"></div>
              <div className="relative glass-card p-4 animate-float">
                <img
                  src="https://images.unsplash.com/photo-1607779097040-26e80aa4838b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                  alt="Profissional de beleza atendendo cliente"
                  className="rounded-xl w-full h-auto object-cover shadow-lg"
                />
                
                <div className="absolute -bottom-6 -right-6 bg-white rounded-2xl shadow-lg p-4 w-48">
                  <div className="flex items-center space-x-2">
                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                    <div className="text-sm font-medium text-gray-900">Novo agendamento</div>
                  </div>
                  <div className="mt-1 text-xs text-gray-500">
                    Extensão de cílios - 14:00
                  </div>
                </div>
                
                <div className="absolute -top-6 -left-6 bg-white rounded-2xl shadow-lg p-3">
                  <div className="flex items-center justify-center w-12 h-12 bg-gradient-to-r from-diva-pink to-diva-purple rounded-full text-white font-bold text-xl">
                    R$
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
