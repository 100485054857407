import { useState, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { clientService } from '@/services/clientService';
import { appointmentService } from '@/services/appointmentService';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Client, Appointment } from '@/types/appointment';
import { Search, Phone, Users, UserPlus, Calendar, RefreshCw } from 'lucide-react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { cn } from '@/lib/utils';
import { toast } from '@/components/ui/use-toast';

const Clients = () => {
  const { user } = useAuth();
  const [clients, setClients] = useState<Client[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      loadClients();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadClients = async () => {
    setIsLoading(true);
    try {
      // Primeiro, buscar os agendamentos para garantir que temos todos os clientes
      let appointments: Appointment[] = [];
      try {
        appointments = await appointmentService.getAppointments(user!.id);
      } catch (error) {
        console.error('Erro ao carregar agendamentos:', error);
        toast({
          title: "Erro ao carregar agendamentos",
          description: "Não foi possível carregar os agendamentos. Prosseguindo com os clientes cadastrados.",
          variant: "destructive",
        });
        appointments = [];
      }
      
      // Agora buscar a lista de clientes que pode ter sido atualizada
      let clientsData: Client[] = [];
      try {
        clientsData = await clientService.listByProfessional(user!.id);
      } catch (error) {
        console.error('Erro ao carregar clientes:', error);
        toast({
          title: "Erro ao carregar clientes",
          description: "Não foi possível carregar seus clientes. Tente novamente.",
          variant: "destructive",
        });
        clientsData = [];
      }
      
      console.log('Clientes carregados:', clientsData);
      console.log('Agendamentos carregados:', appointments);
      
      // Processar os clientes para garantir que todos os que fizeram agendamentos estejam na lista
      const processedClients = processClientData(clientsData, appointments);
      
      setClients(processedClients);
    } catch (error) {
      console.error('Erro geral ao carregar dados:', error);
      toast({
        title: "Erro ao carregar dados",
        description: "Ocorreu um erro inesperado. Por favor, tente novamente.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Função para processar os dados dos clientes e incluir agendamentos
  const processClientData = (clientsData: Client[], appointments: Appointment[]) => {
    // Mapear clientes por email e telefone para fácil referência
    const clientMap = new Map<string, Client>();
    
    // Adicionar todos os clientes ao mapa
    clientsData.forEach(client => {
      const key = `${client.email || ''}-${client.phone || ''}`;
      clientMap.set(key, {
        ...client,
        appointments: client.appointments || []
      });
    });
    
    // Adicionar agendamentos aos clientes existentes
    appointments.forEach(appointment => {
      if (!appointment.client_email || !appointment.client_phone) return;
      
      const key = `${appointment.client_email}-${appointment.client_phone}`;
      
      if (clientMap.has(key)) {
        // Adicionar agendamento a cliente existente
        const client = clientMap.get(key)!;
        if (!client.appointments) {
          client.appointments = [];
        }
        
        // Verificar se o agendamento já existe na lista
        const appointmentExists = client.appointments.some(app => app.id === appointment.id);
        if (!appointmentExists) {
          client.appointments.push(appointment);
        }
      } else {
        // Criar um novo cliente a partir dos dados do agendamento
        const newClient: Client = {
          id: `temp-${appointment.id}`, // ID temporário
          name: appointment.client_name || 'Cliente sem nome',
          email: appointment.client_email || '',
          phone: appointment.client_phone || '',
          professional_id: appointment.professional_id,
          created_at: appointment.created_at,
          updated_at: appointment.updated_at,
          appointments: [appointment]
        };
        
        clientMap.set(key, newClient);
      }
    });
    
    // Converter o mapa de volta para um array
    return Array.from(clientMap.values());
  };

  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.phone.includes(searchTerm)
  );

  // Estatísticas de clientes
  const getTotalClients = () => {
    return clients.length;
  };
    
  const getNewClientsThisMonth = () => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    
    return clients.filter(client => 
      new Date(client.created_at) >= firstDayOfMonth
    ).length;
  };
  
  const getClientReturnRate = () => {
    if (clients.length === 0) return "0%";
    
    // Clientes com mais de um agendamento
    const clientsWithMultipleAppointments = clients.filter(client => 
      client.appointments && client.appointments.length > 1
    ).length;
    
    const rate = Math.round((clientsWithMultipleAppointments / clients.length) * 100);
    return `${rate}%`;
  };

  // Função para calcular valor total gasto por cliente
  const getTotalSpent = (client: Client) => {
    if (!client.appointments || client.appointments.length === 0) return 0;
    
    return client.appointments.reduce((total, appointment) => {
      // Verificar se o serviço existe e tem preço
      if (!appointment.service) return total;
      
      // Garantir que o preço é um número válido
      const price = typeof appointment.service.price === 'number' ? appointment.service.price : 0;
      return total + price;
    }, 0);
  };

  // Função para formatar valor em reais
  const formatCurrency = (value: number) => {
    return `R$ ${value.toFixed(2).replace('.', ',')}`;
  };

  // Função para obter a data do último agendamento
  const getLastAppointmentDate = (client: Client) => {
    if (!client.appointments || client.appointments.length === 0) return "-";
    
    // Ordenar agendamentos por data (mais recente primeiro)
    const sortedAppointments = [...client.appointments].sort((a, b) => {
      // Verificar se as datas são válidas antes de comparar
      const dateA = a.date ? new Date(a.date) : new Date(0);
      const dateB = b.date ? new Date(b.date) : new Date(0);
      return dateB.getTime() - dateA.getTime();
    });
    
    // Verificar se há um agendamento válido antes de formatar
    if (!sortedAppointments[0] || !sortedAppointments[0].date) return "-";
    
    try {
      return format(new Date(sortedAppointments[0].date), "dd/MM/yyyy", { locale: ptBR });
    } catch (error) {
      console.error('Erro ao formatar data:', error, sortedAppointments[0]);
      return "-";
    }
  };

  // Função para gerar iniciais do nome
  const getInitials = (name: string) => {
    if (!name) return "";
    const parts = name.split(' ').filter(part => part.length > 0);
    if (parts.length === 0) return "";
    if (parts.length === 1) return parts[0].substring(0, 2).toUpperCase();
    return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
  };

  return (
    <div className="container mx-auto py-8 px-4 space-y-8">
      {/* Cabeçalho */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 sm:mb-6">
        <h1 className="text-2xl font-bold tracking-tight bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent mb-2 sm:mb-0">
          Clientes
        </h1>
        <Button 
          onClick={loadClients} 
          variant="outline" 
          className="border-diva-pink/20 hover:bg-diva-pink/5 hover:text-diva-pink hover:border-diva-pink/30"
          disabled={isLoading}
        >
          <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
          Atualizar
        </Button>
      </div>

      {/* Cards de estatísticas */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        <Card className="bg-gradient-to-br from-diva-pink/10 to-diva-purple/10 shadow-sm border-0 overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40"></div>
          <CardContent className="p-6 relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-diva-purple font-medium text-sm">Total de clientes</h3>
              <Users className="h-5 w-5 text-diva-pink opacity-80" />
            </div>
            <p className="text-4xl font-bold bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
              {getTotalClients()}
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gradient-to-br from-diva-pink/10 to-diva-purple/10 shadow-sm border-0 overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40"></div>
          <CardContent className="p-6 relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-diva-purple font-medium text-sm">Novos clientes (mês)</h3>
              <UserPlus className="h-5 w-5 text-diva-pink opacity-80" />
            </div>
            <p className="text-4xl font-bold bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
              {getNewClientsThisMonth()}
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gradient-to-br from-diva-pink/10 to-diva-purple/10 shadow-sm border-0 overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40"></div>
          <CardContent className="p-6 relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-diva-purple font-medium text-sm">Taxa de retorno</h3>
              <RefreshCw className="h-5 w-5 text-diva-pink opacity-80" />
            </div>
            <p className="text-4xl font-bold bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
              {getClientReturnRate()}
            </p>
          </CardContent>
        </Card>
      </div>

      {/* Tabela de clientes */}
      <Card className="border border-gray-100 shadow-sm overflow-hidden">
        {/* Barra de pesquisa */}
        <div className="px-6 py-4 border-b border-diva-pink-light/20">
          <div className="flex justify-between items-center">
            <div className="relative max-w-md w-full">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-diva-mid h-4 w-4" />
              <Input
                placeholder="Buscar clientes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 border-diva-pink/20 focus:border-diva-pink"
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center py-12">
            <div className="flex flex-col items-center">
              <RefreshCw className="h-8 w-8 animate-spin text-diva-pink mb-2" />
              <p className="text-sm text-gray-500">Carregando clientes...</p>
            </div>
          </div>
        ) : filteredClients.length === 0 ? (
          <div className="text-center py-12 px-6">
            <div className="w-16 h-16 rounded-full bg-diva-pink/10 flex items-center justify-center mx-auto mb-3">
              <Users className="h-8 w-8 text-diva-pink/60" />
            </div>
            <h3 className="text-lg font-medium text-diva-dark mb-1">
              {searchTerm ? 'Nenhum cliente encontrado' : 'Nenhum cliente cadastrado'}
            </h3>
            <p className="text-gray-500 mb-4 max-w-md mx-auto">
              {searchTerm 
                ? 'Tente buscar com outros termos ou limpar a pesquisa' 
                : 'Os clientes são automaticamente adicionados quando realizam agendamentos'}
            </p>
            {searchTerm && (
              <Button 
                onClick={() => setSearchTerm('')} 
                variant="outline"
                className="border-diva-pink/20 hover:bg-diva-pink/5 hover:text-diva-pink hover:border-diva-pink/30"
              >
                Limpar pesquisa
              </Button>
            )}
          </div>
        ) : (
          <div className="overflow-x-auto">
            {/* Cabeçalho da tabela */}
            <div className="grid grid-cols-12 gap-4 px-6 py-3 bg-gradient-to-r from-diva-pink/5 to-diva-purple/5 text-diva-dark text-sm font-medium">
              <div className="col-span-4">CLIENTE</div>
              <div className="col-span-3">CONTATO</div>
              <div className="col-span-2 text-center">ÚLTIMA VISITA</div>
              <div className="col-span-3 text-right">TOTAL GASTO</div>
            </div>
            
            {/* Linhas da tabela */}
            {filteredClients.map((client) => (
              <div
                key={client.id}
                className="grid grid-cols-12 gap-4 px-6 py-4 border-t border-gray-100 hover:bg-gray-50 transition-colors"
              >
                <div className="col-span-4">
                  <div className="flex items-center gap-3">
                    <div className="h-10 w-10 rounded-full flex items-center justify-center font-medium bg-gradient-to-br from-diva-pink/20 to-diva-purple/20 text-diva-purple">
                      {getInitials(client.name)}
                    </div>
                    <div>
                      <div className="font-medium text-gray-700">{client.name}</div>
                      <div className="text-xs text-gray-500">
                        {client.email}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-span-3 flex items-center text-gray-600">
                  <Phone className="h-3.5 w-3.5 mr-1.5 text-diva-pink/70" />
                  {client.phone}
                </div>
                
                <div className="col-span-2 flex items-center justify-center text-gray-600">
                  <Calendar className="h-3.5 w-3.5 mr-1.5 text-diva-pink/70" />
                  {getLastAppointmentDate(client)}
                </div>
                
                <div className="col-span-3 flex items-center justify-end">
                  <span className="font-medium text-diva-dark">{formatCurrency(getTotalSpent(client))}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default Clients; 