import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import {
  Calendar,
  Scissors,
  Users,
  User,
  Building2,
  LogOut,
  DollarSign,
  Menu,
  X
} from "lucide-react";
import { useState, useEffect } from "react";

export function MainNav() {
  const location = useLocation();
  const { signOut } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const routes = [
    {
      href: "/agenda",
      label: "Agenda",
      icon: Calendar,
    },
    {
      href: "/servicos",
      label: "Serviços",
      icon: Scissors,
    },
    {
      href: "/clientes",
      label: "Clientes",
      icon: Users,
    },
    {
      href: "/perfil",
      label: "Perfil",
      icon: User,
    },
    {
      href: "/financial",
      label: "Financeiro",
      icon: DollarSign,
    },
    {
      href: "/negocio",
      label: "Seu Negócio",
      icon: Building2,
    },
  ];

  // Componente de conteúdo do menu
  const NavContent = () => (
    <>
      <div className={`px-3 ${isMobile ? 'pt-4' : ''}`}>
        <div className="space-y-1">
          {routes.map((route) => {
            const Icon = route.icon;
            const isActive = location.pathname === route.href;
            return (
              <Button
                key={route.href}
                variant={isActive ? "default" : "ghost"}
                className={cn(
                  "w-full justify-start mb-1",
                  isActive 
                    ? "bg-gradient-to-r from-diva-pink to-diva-purple hover:opacity-90 text-white" 
                    : "hover:text-diva-pink"
                )}
                asChild
                onClick={() => isMobile && setIsMobileMenuOpen(false)}
              >
                <Link to={route.href} className="flex items-center gap-2">
                  <Icon className="h-5 w-5" />
                  {route.label}
                </Link>
              </Button>
            );
          })}
        </div>
      </div>

      <div className="px-3 mt-auto">
        <Button 
          variant="ghost" 
          onClick={signOut} 
          className="w-full justify-start text-red-600 hover:text-red-700 hover:bg-red-50"
        >
          <LogOut className="h-5 w-5 mr-2" />
          Sair
        </Button>
      </div>
    </>
  );

  return (
    <>
      {/* Header Mobile */}
      {isMobile && (
        <div className="bg-white border-b p-4 flex items-center justify-between md:hidden sticky top-0 z-10">
          <h1 className="text-xl font-bold text-diva-pink">AgendeDiva</h1>
          <Button
            variant="ghost" 
            size="icon"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label={isMobileMenuOpen ? "Fechar menu" : "Abrir menu"}
          >
            {isMobileMenuOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
          </Button>
        </div>
      )}

      {/* Menu Mobile */}
      {isMobile && isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black/50 z-20" onClick={() => setIsMobileMenuOpen(false)}>
          <div 
            className="absolute right-0 top-0 h-full w-64 bg-white shadow-lg overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="font-bold text-diva-pink">Menu</h2>
              <Button 
                variant="ghost" 
                size="icon" 
                onClick={() => setIsMobileMenuOpen(false)}
                aria-label="Fechar menu"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            <div className="flex flex-col h-[calc(100%-64px)] py-2 overflow-y-auto">
              <NavContent />
            </div>
          </div>
        </div>
      )}

      {/* Menu Desktop - apenas mostrado quando não estiver em modo mobile */}
      {!isMobile && (
        <aside className="fixed left-0 top-0 bottom-0 w-64 border-r bg-white shadow-sm z-10">
          <div className="flex flex-col h-full py-6">
            <div className="px-6 mb-8">
              <h1 className="text-xl font-bold text-diva-pink">AgendeDiva</h1>
            </div>
            
            <nav className="flex-1">
              <NavContent />
            </nav>
          </div>
        </aside>
      )}
    </>
  );
} 