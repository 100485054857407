import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Menu, X, Home, LayoutGrid, CreditCard, Users, MessageCircle, Phone } from "lucide-react";
import AgendeDivaLogo from "./AgendeDivaLogo";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  // Função para lidar com o clique no item Depoimentos
  const handleTestimonialsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    
    // Se estiver na página inicial, apenas rola para a seção
    if (location.pathname === '/') {
      const testimonialsSection = document.getElementById('testimonials');
      if (testimonialsSection) {
        testimonialsSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Se estiver em outra página, navega para a página inicial com o hash
      window.location.href = '/#testimonials';
    }
    
    // Fecha o menu móvel se estiver aberto
    setIsMenuOpen(false);
  };

  const menuItems = [
    {
      to: "/",
      label: "Início",
      icon: Home
    },
    {
      to: "/features",
      label: "Recursos",
      icon: LayoutGrid
    },
    {
      to: "/pricing",
      label: "Preços",
      icon: CreditCard
    },
    {
      to: "/sobre",
      label: "Sobre Nós",
      icon: Users
    },
    {
      to: "#testimonials",
      label: "Depoimentos",
      icon: MessageCircle,
      isAnchor: true,
      onClick: handleTestimonialsClick
    },
    {
      to: "/contato",
      label: "Contato",
      icon: Phone
    }
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-lg border-b border-diva-pink-light/20">
      <div className="container max-w-7xl mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2">
            <AgendeDivaLogo />
            <span className="text-2xl font-bold text-diva-dark font-serif">AgendeDiva</span>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <div className="flex items-center space-x-6">
              {menuItems.map((item) => (
                item.isAnchor ? (
                  <a 
                    key={item.to}
                    href={item.to} 
                    className="text-diva-dark hover:text-diva-pink transition-colors font-medium flex items-center gap-1.5"
                    onClick={item.onClick}
                  >
                    <item.icon className="h-4 w-4" />
                    {item.label}
                  </a>
                ) : (
                  <Link 
                    key={item.to}
                    to={item.to} 
                    className="text-diva-dark hover:text-diva-pink transition-colors font-medium flex items-center gap-1.5"
                  >
                    <item.icon className="h-4 w-4" />
                    {item.label}
                  </Link>
                )
              ))}
            </div>
            
            <div className="flex items-center space-x-4">
              <Link to="/login">
                <Button variant="outline" className="border-diva-pink text-diva-pink hover:bg-diva-pink hover:text-white">
                  Entrar
                </Button>
              </Link>
              <Link to="/signup">
                <Button className="bg-gradient-to-r from-diva-pink to-diva-purple text-white hover:shadow-lg transition-all">
                  Começar Agora
                </Button>
              </Link>
            </div>
          </div>

          {/* Mobile Menu Toggle */}
          <button 
            className="md:hidden p-2 rounded-md focus:outline-none" 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="w-6 h-6 text-diva-dark" />
            ) : (
              <Menu className="w-6 h-6 text-diva-dark" />
            )}
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4 animate-fade-in">
            <div className="flex flex-col space-y-4">
              {menuItems.map((item) => (
                item.isAnchor ? (
                  <a 
                    key={item.to}
                    href={item.to} 
                    className="px-4 py-2 text-diva-dark hover:bg-diva-pink-light/20 rounded-md flex items-center gap-2"
                    onClick={item.onClick}
                  >
                    <item.icon className="h-5 w-5" />
                    {item.label}
                  </a>
                ) : (
                  <Link 
                    key={item.to}
                    to={item.to} 
                    className="px-4 py-2 text-diva-dark hover:bg-diva-pink-light/20 rounded-md flex items-center gap-2"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <item.icon className="h-5 w-5" />
                    {item.label}
                  </Link>
                )
              ))}
              <div className="pt-4 border-t border-gray-200">
                <Link to="/login" onClick={() => setIsMenuOpen(false)}>
                  <Button variant="outline" className="w-full mb-3 border-diva-pink text-diva-pink hover:bg-diva-pink hover:text-white">
                    Entrar
                  </Button>
                </Link>
                <Link to="/signup" onClick={() => setIsMenuOpen(false)}>
                  <Button className="w-full bg-gradient-to-r from-diva-pink to-diva-purple text-white">
                    Começar Agora
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
