import { supabase } from '@/lib/supabase';
import type { Appointment, Client, Service, TimeSlot, WorkingHours } from '@/types/appointment';
import { addDays, format, parseISO, setHours, setMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const appointmentService = {
  // Buscar todos os agendamentos de um profissional
  async getAppointments(professionalId: string): Promise<Appointment[]> {
    const { data, error } = await supabase
      .from('appointments')
      .select(`
        *,
        service:services(*)
      `)
      .eq('professional_id', professionalId)
      .order('date', { ascending: true });

    if (error) {
      console.error('Erro ao buscar agendamentos:', error);
      throw error;
    }
    
    console.log('Agendamentos retornados:', data);
    return data;
  },

  // Buscar agendamentos por data
  async getAppointmentsByDate(professionalId: string, date: string): Promise<Appointment[]> {
    console.log(`Buscando agendamentos para o profissional ${professionalId} na data ${date}`);
    
    try {
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          service:services(*)
        `)
        .eq('professional_id', professionalId)
        .eq('date', date)
        .order('time_slot', { ascending: true });

      if (error) {
        console.error('Erro ao buscar agendamentos por data:', error);
        throw error;
      }
      
      console.log(`Agendamentos encontrados para ${date}:`, data);
      return data;
    } catch (error) {
      console.error(`Erro ao buscar agendamentos para ${date}:`, error);
      throw error;
    }
  },

  // Criar novo agendamento
  async createAppointment(appointment: Omit<Appointment, 'id' | 'created_at' | 'updated_at'>): Promise<Appointment> {
    console.log('Criando agendamento com dados:', appointment);
    
    try {
      // Garantir que a data está no formato correto (YYYY-MM-DD)
      const formattedDate = appointment.date.includes('T') 
        ? appointment.date.split('T')[0] 
        : appointment.date;
      
      const appointmentData = {
        ...appointment,
        date: formattedDate
      };
      
      const { data, error } = await supabase
        .from('appointments')
        .insert([appointmentData])
        .select()
        .single();

      if (error) {
        console.error('Erro ao criar agendamento:', error);
        throw error;
      }
      
      console.log('Agendamento criado com sucesso:', data);
      return data;
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      throw error;
    }
  },

  // Atualizar status do agendamento
  async updateAppointmentStatus(appointmentId: string, status: Appointment['status']): Promise<void> {
    const { error } = await supabase
      .from('appointments')
      .update({ status })
      .eq('id', appointmentId);

    if (error) throw error;
  },

  // Buscar horários disponíveis
  async getAvailableHours(professionalId: string, date: string, serviceId?: string): Promise<string[]> {
    try {
      // Buscar configurações de horário de trabalho
      const { data: workingHoursData, error: workingHoursError } = await supabase
        .from('working_hours')
        .select('*')
        .eq('professional_id', professionalId);

      if (workingHoursError) throw workingHoursError;
      
      // Verificar dia da semana
      const selectedDate = new Date(date);
      const dayOfWeek = selectedDate.getDay(); // 0 = domingo, 1 = segunda, ...

      // Buscar configuração para o dia da semana
      const dayConfig = workingHoursData?.find(day => day.day_of_week === dayOfWeek);
      
      // Se não há configuração ou o dia não está disponível
      if (!dayConfig || !dayConfig.is_available) {
        console.log(`Dia ${dayOfWeek} não está disponível para agendamento`);
        return [];
      }

      // Buscar duração do serviço selecionado, se houver
      let serviceDuration = 30; // duração padrão em minutos
      
      if (serviceId) {
        const { data: serviceData, error: serviceError } = await supabase
          .from('services')
          .select('duration_minutes')
          .eq('id', serviceId)
          .single();
          
        if (!serviceError && serviceData) {
          serviceDuration = serviceData.duration_minutes;
          console.log(`Duração do serviço: ${serviceDuration} minutos`);
        }
      }

      // Gerar time slots de acordo com horário de trabalho
      const startHour = parseInt(dayConfig.start_time.split(':')[0]);
      const startMinute = parseInt(dayConfig.start_time.split(':')[1]);
      const endHour = parseInt(dayConfig.end_time.split(':')[0]);
      const endMinute = parseInt(dayConfig.end_time.split(':')[1]);

      // Gerar time slots
      const timeSlots: string[] = [];
      let currentTime = new Date(date);
      currentTime.setHours(startHour, startMinute, 0, 0);
      
      const endTime = new Date(date);
      endTime.setHours(endHour, endMinute, 0, 0);

      // Verificar se a data é hoje
      const today = new Date();
      const isToday = format(today, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd');
      
      // Se for hoje, ajustar horário inicial para o próximo horário disponível
      if (isToday) {
        const now = new Date();
        // Adicionar 30 minutos de buffer para o próximo horário disponível
        now.setMinutes(now.getMinutes() + 30);
        
        // Se o horário atual já passou do horário inicial do dia, ajustar para o próximo slot disponível
        if (now > currentTime) {
          currentTime = now;
          // Arredondar para o próximo slot de X minutos
          const minutes = currentTime.getMinutes();
          const remainder = minutes % serviceDuration;
          if (remainder > 0) {
            currentTime.setMinutes(minutes + (serviceDuration - remainder));
          }
        }
      }

      // Buscar agendamentos existentes para a data
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
      const { data: existingAppointments, error: appointmentsError } = await supabase
        .from('appointments')
        .select('time_slot, service_id, date')
        .eq('professional_id', professionalId)
        .eq('date', formattedDate)
        .in('status', ['pending', 'confirmed']);

      if (appointmentsError) throw appointmentsError;

      // Criar um mapa de horários ocupados
      const occupiedTimeSlots = new Set<string>();
      
      // Buscar durações dos serviços agendados
      if (existingAppointments && existingAppointments.length > 0) {
        const serviceIds = [...new Set(existingAppointments.map(app => app.service_id))];
        const { data: servicesData } = await supabase
          .from('services')
          .select('id, duration_minutes')
          .in('id', serviceIds);

        const serviceDurations = new Map(
          servicesData?.map(service => [service.id, service.duration_minutes]) || []
        );

        // Marcar todos os slots ocupados por agendamentos existentes
        existingAppointments.forEach(appointment => {
          const appointmentTime = new Date(`${appointment.date}T${appointment.time_slot}`);
          const duration = serviceDurations.get(appointment.service_id) || 30;
          
          // Marcar todos os slots dentro da duração do serviço como ocupados
          const currentSlot = new Date(appointmentTime);
          const endSlot = new Date(appointmentTime.getTime() + duration * 60000);
          
          while (currentSlot < endSlot) {
            occupiedTimeSlots.add(format(currentSlot, 'HH:mm'));
            currentSlot.setMinutes(currentSlot.getMinutes() + serviceDuration);
          }
        });
      }

      // Gerar slots disponíveis
      while (currentTime < endTime) {
        const timeSlot = format(currentTime, 'HH:mm');
        
        // Verificar se o horário não está ocupado e se há tempo suficiente para o serviço
        const serviceEndTime = new Date(currentTime.getTime() + serviceDuration * 60000);
        let isSlotAvailable = true;
        
        // Verificar se algum slot dentro da duração do serviço está ocupado
        const checkTime = new Date(currentTime);
        while (checkTime < serviceEndTime) {
          if (occupiedTimeSlots.has(format(checkTime, 'HH:mm'))) {
            isSlotAvailable = false;
            break;
          }
          checkTime.setMinutes(checkTime.getMinutes() + serviceDuration);
        }

        // Se for hoje, verificar se o horário já passou
        if (isToday) {
          const now = new Date();
          if (currentTime <= now) {
            isSlotAvailable = false;
          }
        }

        if (isSlotAvailable && serviceEndTime <= endTime) {
          timeSlots.push(timeSlot);
        }

        currentTime.setMinutes(currentTime.getMinutes() + serviceDuration);
      }

      return timeSlots;
    } catch (error) {
      console.error('Erro ao buscar horários disponíveis:', error);
      throw error;
    }
  },

  // Buscar serviços do profissional
  async getServices(professionalId: string): Promise<Service[]> {
    const { data, error } = await supabase
      .from('services')
      .select('*')
      .eq('professional_id', professionalId)
      .eq('is_active', true)
      .order('name', { ascending: true });

    if (error) throw error;
    return data;
  },

  // Buscar cliente por ID
  async getClient(clientId: string): Promise<Client> {
    const { data, error } = await supabase
      .from('clients')
      .select('*')
      .eq('id', clientId)
      .single();

    if (error) throw error;
    return data;
  },

  // Criar novo cliente
  async createClient(client: Omit<Client, 'id' | 'created_at' | 'updated_at'>): Promise<Client> {
    const { data, error } = await supabase
      .from('clients')
      .insert([client])
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  // Criar um novo agendamento
  async create(appointment: {
    client_name: string;
    client_email: string;
    client_phone: string;
    service_id: string;
    professional_id: string;
    date: string;
    time_slot: string;
    notes?: string;
  }): Promise<Appointment> {
    try {
      console.log('Criando novo agendamento com dados:', appointment);
      
      // Ajustar a data para considerar o fuso horário local
      const localDate = new Date(appointment.date);
      const formattedDate = format(localDate, 'yyyy-MM-dd');
      
      console.log(`Data formatada para salvar: ${formattedDate}`);
      
      // Verificar se o cliente já existe no sistema
      const { data: existingClients, error: clientQueryError } = await supabase
        .from('clients')
        .select('id')
        .eq('professional_id', appointment.professional_id)
        .eq('email', appointment.client_email)
        .eq('phone', appointment.client_phone);

      if (clientQueryError) {
        console.error('Erro ao verificar cliente existente:', clientQueryError);
        throw clientQueryError;
      }

      // Se o cliente não existir, criar um novo
      if (!existingClients || existingClients.length === 0) {
        console.log('Cliente não encontrado. Criando novo cliente.');
        
        const { error: clientError } = await supabase
          .from('clients')
          .insert([{
            name: appointment.client_name,
            email: appointment.client_email,
            phone: appointment.client_phone,
            professional_id: appointment.professional_id
          }]);

        if (clientError) {
          console.error('Erro ao criar cliente automaticamente:', clientError);
          // Continuar mesmo com erro (não bloquear o agendamento)
        }
      } else {
        console.log('Cliente já existente no sistema.');
      }

      const { data, error } = await supabase
        .from('appointments')
        .insert([{
          client_name: appointment.client_name,
          client_email: appointment.client_email,
          client_phone: appointment.client_phone,
          service_id: appointment.service_id,
          professional_id: appointment.professional_id,
          date: formattedDate,
          time_slot: appointment.time_slot,
          status: 'pending',
          notes: appointment.notes
        }])
        .select()
        .single();

      if (error) {
        console.error('Erro ao criar agendamento:', error);
        throw error;
      }

      console.log('Agendamento criado com sucesso:', data);
      return data;
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      throw error;
    }
  },

  // Buscar todos os agendamentos de um profissional
  async listByProfessional(professionalId: string): Promise<Appointment[]> {
    try {
      console.log(`Listando todos os agendamentos para o profissional ${professionalId}`);
      
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          service:services(*)
        `)
        .eq('professional_id', professionalId)
        .order('date', { ascending: true });

      if (error) {
        console.error('Erro ao listar agendamentos:', error);
        throw error;
      }
      
      console.log(`Total de agendamentos encontrados: ${data?.length || 0}`);
      return data;
    } catch (error) {
      console.error('Erro ao listar agendamentos:', error);
      throw error;
    }
  },

  // Buscar agendamentos por data
  async listByDate(professionalId: string, date: string) {
    try {
      console.log('Buscando agendamentos com params:', { professionalId, date });
      
      // Garantir que a data está no formato correto (YYYY-MM-DD)
      const formattedDate = date.includes('T') 
        ? date.split('T')[0] 
        : date;
      
      console.log(`Data formatada para consulta: ${formattedDate}`);
      
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          client:clients(*),
          service:services(*)
        `)
        .eq('professional_id', professionalId)
        .eq('date', formattedDate)
        .order('time_slot', { ascending: true });

      if (error) {
        console.error('Erro na consulta:', error);
        throw error;
      }

      console.log('Dados retornados do banco:', data);
      return data;
    } catch (error) {
      console.error('Erro ao listar agendamentos por data:', error);
      throw error;
    }
  },

  // Atualizar status do agendamento
  async updateStatus(id: string, status: Appointment['status']) {
    const { data, error } = await supabase
      .from('appointments')
      .update({ status })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  // Cancelar agendamento
  async cancel(id: string) {
    return this.updateStatus(id, 'cancelled');
  },

  // Confirmar agendamento
  async confirm(id: string) {
    return this.updateStatus(id, 'confirmed');
  },

  // Marcar como concluído
  async complete(id: string) {
    return this.updateStatus(id, 'completed');
  },

  // Verificar disponibilidade de horário
  async checkAvailability(professionalId: string, date: string, startTime: string, endTime: string) {
    const { data, error } = await supabase
      .from('appointments')
      .select('*')
      .eq('professional_id', professionalId)
      .eq('date', date)
      .neq('status', 'cancelled')
      .or(`start_time.lte.${endTime},end_time.gte.${startTime}`);

    if (error) throw error;
    return data.length === 0; // retorna true se o horário estiver disponível
  },

  // Atualizar agendamento
  async update(id: string, appointment: Partial<Appointment>): Promise<Appointment> {
    try {
      // Se houver data no objeto, garantir formato correto
      if (appointment.date) {
        appointment = {
          ...appointment,
          date: appointment.date.includes('T') 
            ? appointment.date.split('T')[0] 
            : appointment.date
        };
      }
      
      const { data, error } = await supabase
        .from('appointments')
        .update(appointment)
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Erro ao atualizar agendamento:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Erro ao atualizar agendamento:', error);
      throw error;
    }
  },

  // Deletar agendamento
  async delete(id: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('appointments')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Erro ao deletar agendamento:', error);
        throw error;
      }
    } catch (error) {
      console.error('Erro ao deletar agendamento:', error);
      throw error;
    }
  },

  // Método auxiliar para gerar horários de funcionamento
  generateBusinessHours(startTime: string, endTime: string): string[] {
    const start = startTime.split(':').map(Number);
    const end = endTime.split(':').map(Number);
    
    const startHour = start[0];
    const startMinute = start[1] || 0;
    const endHour = end[0];
    const endMinute = end[1] || 0;
    
    const businessHours: string[] = [];
    let currentHour = startHour;
    let currentMinute = startMinute;
    
    while (
      currentHour < endHour || 
      (currentHour === endHour && currentMinute < endMinute)
    ) {
      const formattedHour = currentHour.toString().padStart(2, '0');
      const formattedMinute = currentMinute.toString().padStart(2, '0');
      businessHours.push(`${formattedHour}:${formattedMinute}`);
      
      // Avança 60 minutos
      currentMinute += 60;
      if (currentMinute >= 60) {
        currentHour += Math.floor(currentMinute / 60);
        currentMinute = currentMinute % 60;
      }
    }
    
    return businessHours;
  },
  
  // Método para retornar horários padrão para cada dia
  getDefaultHoursForDay(dayOfWeek: number): string[] {
    // Finais de semana (0=Domingo, 6=Sábado)
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return []; // Indisponível
    }
    
    // Dias de semana (1-5): horário comercial padrão (9h às 18h)
    return this.generateBusinessHours('09:00', '18:00');
  },

  // Buscar todos os agendamentos pendentes
  async getPendingAppointments(professionalId: string): Promise<Appointment[]> {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          service:services(*)
        `)
        .eq('professional_id', professionalId)
        .eq('status', 'pending')
        .order('date', { ascending: true })
        .order('time_slot', { ascending: true });

      if (error) {
        console.error('Erro ao buscar agendamentos pendentes:', error);
        throw error;
      }

      return data || [];
    } catch (error) {
      console.error('Erro ao buscar agendamentos pendentes:', error);
      throw error;
    }
  },
}; 