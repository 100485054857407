import { supabase } from '@/lib/supabase';
import { FinancialTransaction, FinancialSummary } from '@/types/appointment';
import { Appointment } from '@/types/appointment';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const financialService = {
  // Registrar nova transação
  async create(transaction: {
    professional_id: string;
    type: 'income' | 'expense';
    amount: number;
    description?: string;
    payment_method?: string;
    status: 'pending' | 'completed' | 'cancelled';
    service_id?: string;
  }): Promise<FinancialTransaction> {
    try {
      const { data, error } = await supabase
        .from('financial_transactions')
        .insert([transaction])
        .select()
        .single();

      if (error) {
        console.error('Erro ao criar transação:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Erro ao criar transação:', error);
      throw error;
    }
  },

  // Atualizar transação
  async update(id: string, transaction: {
    type?: 'income' | 'expense';
    amount?: number;
    description?: string;
    payment_method?: string;
    status?: 'pending' | 'completed' | 'cancelled';
    service_id?: string;
  }): Promise<FinancialTransaction> {
    try {
      const { data, error } = await supabase
        .from('financial_transactions')
        .update(transaction)
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Erro ao atualizar transação:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Erro ao atualizar transação:', error);
      throw error;
    }
  },

  // Excluir transação
  async delete(id: string) {
    try {
      const { error } = await supabase
        .from('financial_transactions')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error('Erro ao excluir transação:', error);
      throw error;
    }
  },

  // Buscar transações por período
  async listByPeriod(professionalId: string, startDate: string, endDate: string) {
    try {
      // Simplificando a consulta para evitar junções que podem causar erro
      const { data, error } = await supabase
        .from('financial_transactions')
        .select('*')
        .eq('professional_id', professionalId)
        .gte('created_at', startDate)
        .lte('created_at', endDate)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Carregando os serviços relacionados, se houver
      const transactionsWithServices = await Promise.all(
        data.map(async (transaction) => {
          if (transaction.service_id) {
            try {
              const { data: serviceData, error: serviceError } = await supabase
                .from('services')
                .select('*')
                .eq('id', transaction.service_id)
                .single();
                
              if (!serviceError && serviceData) {
                return {
                  ...transaction,
                  service: serviceData
                };
              }
            } catch (err) {
              console.error('Erro ao buscar serviço:', err);
            }
          }
          return transaction;
        })
      );
      
      return transactionsWithServices;
    } catch (error) {
      console.error('Erro ao listar transações:', error);
      // Retornar array vazio ao invés de lançar erro
      return [];
    }
  },

  // Buscar resumo financeiro do período
  async getSummary(professionalId: string, startDate: string, endDate: string): Promise<FinancialSummary> {
    try {
      const { data: transactions, error } = await supabase
        .from('financial_transactions')
        .select('*')
        .eq('professional_id', professionalId)
        .gte('created_at', startDate)
        .lte('created_at', endDate);

      if (error) throw error;

      const income = transactions
        .filter(tx => tx.type === 'income' && tx.status === 'completed')
        .reduce((sum, tx) => sum + tx.amount, 0);

      const expenses = transactions
        .filter(tx => tx.type === 'expense' && tx.status === 'completed')
        .reduce((sum, tx) => sum + tx.amount, 0);

      const pendingAmount = transactions
        .filter(tx => tx.status === 'pending')
        .reduce((sum, tx) => sum + tx.amount, 0);

      return {
        income,
        expenses,
        profit: income - expenses,
        totalTransactions: transactions.length,
        pendingAmount,
        name: 'Resumo' // para o gráfico
      };
    } catch (error) {
      console.error('Erro ao obter resumo financeiro:', error);
      throw error;
    }
  },

  // Atualizar status da transação
  async updateStatus(id: string, status: FinancialTransaction['status']) {
    const { data, error } = await supabase
      .from('financial_transactions')
      .update({ status })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  // Buscar métodos de pagamento mais utilizados
  async getPaymentMethodStats(professionalId: string, startDate: string, endDate: string) {
    try {
      const { data: transactions, error } = await supabase
        .from('financial_transactions')
        .select('payment_method')
        .eq('professional_id', professionalId)
        .eq('type', 'income')
        .eq('status', 'completed')
        .gte('created_at', startDate)
        .lte('created_at', endDate);

      if (error) throw error;

      const stats = transactions.reduce((acc, tx) => {
        if (tx.payment_method) {
          acc[tx.payment_method] = (acc[tx.payment_method] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);

      return Object.entries(stats)
        .map(([method, count]) => ({
          method,
          count,
          percentage: (count / transactions.length) * 100
        }))
        .sort((a, b) => b.count - a.count);
    } catch (error) {
      console.error('Erro ao obter estatísticas de pagamento:', error);
      throw error;
    }
  }
};

interface FinancialMetrics {
  monthlyRevenue: number;
  weeklyRevenue: number;
  averageTicket: number;
  growth: number;
  revenueByDay: {
    [key: string]: number;
  };
}

export function calculateFinancialMetrics(appointments: Appointment[]): FinancialMetrics {
  const now = new Date();
  const startOfCurrentWeek = startOfWeek(now, { locale: ptBR });
  const endOfCurrentWeek = endOfWeek(now, { locale: ptBR });
  const startOfCurrentMonth = startOfMonth(now);
  const endOfCurrentMonth = endOfMonth(now);

  // Filtra apenas agendamentos confirmados e concluídos
  const completedAppointments = appointments.filter(
    app => app.status === 'confirmed' || app.status === 'completed'
  );

  // Calcula faturamento mensal
  const monthlyRevenue = completedAppointments
    .filter(app => {
      const appointmentDate = new Date(app.date);
      return isWithinInterval(appointmentDate, {
        start: startOfCurrentMonth,
        end: endOfCurrentMonth
      });
    })
    .reduce((total, app) => {
      // Verifica se o serviço tem preço definido
      const price = app.service?.price || app.price || 0;
      return total + price;
    }, 0);

  // Calcula faturamento semanal
  const weeklyRevenue = completedAppointments
    .filter(app => {
      const appointmentDate = new Date(app.date);
      return isWithinInterval(appointmentDate, {
        start: startOfCurrentWeek,
        end: endOfCurrentWeek
      });
    })
    .reduce((total, app) => {
      const price = app.service?.price || app.price || 0;
      return total + price;
    }, 0);

  // Calcula ticket médio apenas para agendamentos com preço
  const appointmentsWithPrice = completedAppointments.filter(app => 
    (app.service?.price || app.price) > 0
  );
  const averageTicket = appointmentsWithPrice.length > 0 
    ? appointmentsWithPrice.reduce((total, app) => {
        const price = app.service?.price || app.price || 0;
        return total + price;
      }, 0) / appointmentsWithPrice.length 
    : 0;

  // Calcula crescimento (comparando com o mês anterior)
  const lastMonthStart = startOfMonth(new Date(now.getFullYear(), now.getMonth() - 1));
  const lastMonthEnd = endOfMonth(new Date(now.getFullYear(), now.getMonth() - 1));
  
  const lastMonthRevenue = completedAppointments
    .filter(app => {
      const appointmentDate = new Date(app.date);
      return isWithinInterval(appointmentDate, {
        start: lastMonthStart,
        end: lastMonthEnd
      });
    })
    .reduce((total, app) => {
      const price = app.service?.price || app.price || 0;
      return total + price;
    }, 0);

  const growth = lastMonthRevenue ? ((monthlyRevenue - lastMonthRevenue) / lastMonthRevenue) * 100 : 0;

  // Calcula faturamento por dia da semana
  const revenueByDay = completedAppointments
    .filter(app => {
      const appointmentDate = new Date(app.date);
      return isWithinInterval(appointmentDate, {
        start: startOfCurrentWeek,
        end: endOfCurrentWeek
      });
    })
    .reduce((acc, app) => {
      const date = new Date(app.date);
      const dayName = date.toLocaleDateString('pt-BR', { weekday: 'short' });
      const price = app.service?.price || app.price || 0;
      acc[dayName] = (acc[dayName] || 0) + price;
      return acc;
    }, {} as { [key: string]: number });

  return {
    monthlyRevenue,
    weeklyRevenue,
    averageTicket,
    growth,
    revenueByDay
  };
} 