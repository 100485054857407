import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { AuthProvider } from '@/contexts/AuthContext';
import { Toaster } from '@/components/ui/toaster';
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MainLayout } from '@/components/layout/MainLayout';
import { PrivateRoute } from '@/components/PrivateRoute';
import { PublicRoute } from '@/components/PublicRoute';
import { initializeDatabase } from '@/lib/database-setup';

// Pages
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SignupConfirm from "./pages/SignupConfirm";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Appointments from '@/pages/Appointments';
import Clients from '@/pages/Clients';
import Services from '@/pages/Services';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from "./pages/PageNotFound";
import Profile from '@/pages/Profile';
import Business from '@/pages/Business';
import PublicScheduling from '@/pages/PublicScheduling';
import Dashboard from '@/pages/Dashboard';
import Financial from '@/pages/Financial.jsx';
import ScheduleSettings from '@/pages/ScheduleSettings';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import RefundPolicy from './pages/RefundPolicy';
import Support from './pages/Support';
import Tutorials from './pages/Tutorials';
import Contact from './pages/Contact';
import About from './pages/About';

// Cliente de query com configuração para retry em caso de erro de rede
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: attempt => Math.min(attempt > 1 ? 2000 : 1000, 30000),
      staleTime: 5 * 60 * 1000, // 5 minutos
    },
  },
});

const App = () => {
  // Inicializar banco de dados na primeira renderização
  useEffect(() => {
    // Usar setTimeout para garantir que a inicialização ocorra após os componentes serem montados
    const initTimer = setTimeout(() => {
      console.log('Iniciando verificação do banco de dados...');
      
      initializeDatabase()
        .then(() => {
          console.log('Verificação do banco de dados concluída');
        })
        .catch(error => {
          console.error('Falha ao verificar banco de dados:', error);
          // Não mostramos toast aqui para não confundir o usuário na inicialização
        });
    }, 1000);
    
    return () => clearTimeout(initTimer);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <BrowserRouter>
          <AuthProvider>
            <Toaster />
            <Sonner />
            <Routes>
              {/* Rotas Públicas */}
              <Route element={<PublicRoute />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/signup/confirm" element={<SignupConfirm />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/features" element={<Features />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/support" element={<Support />} />
                <Route path="/tutorials" element={<Tutorials />} />
                <Route path="/contato" element={<Contact />} />
                <Route path="/sobre" element={<About />} />
                <Route path="/b/:professionalId" element={<PublicScheduling />} />
                {/* Páginas Legais */}
                <Route path="/termos-de-servico" element={<TermsOfService />} />
                <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
                <Route path="/politica-de-cookies" element={<CookiePolicy />} />
                <Route path="/politica-de-reembolso" element={<RefundPolicy />} />
              </Route>

              {/* Rotas Privadas */}
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/agenda" element={<Appointments />} />
                <Route path="/clientes" element={<Clients />} />
                <Route path="/servicos" element={<Services />} />
                <Route path="/perfil" element={<Profile />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/negocio" element={<Business />} />
                <Route path="/configuracoes-agenda" element={<ScheduleSettings />} />
              </Route>
              
              {/* Página 404 */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
  );
};

export default App;
