import React from 'react';
import { Link } from 'react-router-dom';

const TeamSection = () => {
  return (
    <section id="team" className="section-padding bg-gradient-to-br from-diva-purple/5 to-diva-pink/5">
      <div className="container mx-auto">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-diva-dark mb-4">
            Conheça Nossa Equipe
          </h2>
          <p className="text-diva-dark/70 text-lg">
            As pessoas por trás da plataforma que está transformando a gestão de negócios de beleza
          </p>
        </div>

        <div className="flex flex-col items-center justify-center">
          {/* Fundador */}
          <div className="text-center max-w-xs">
            <div className="relative w-40 h-40 mx-auto mb-6">
              <div className="absolute inset-0 rounded-full bg-gradient-to-r from-diva-pink to-diva-purple p-1">
                <div className="h-full w-full rounded-full bg-white overflow-hidden">
                  <div className="h-full w-full bg-gray-200 flex items-center justify-center text-white">
                    <span className="text-3xl font-bold">FA</span>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-2xl font-bold text-diva-dark">Felipe Alves</h3>
            <p className="text-diva-purple font-medium text-lg mb-3">Fundador e CTO</p>
            <p className="text-diva-dark/70">
              Desenvolvedor full-stack com experiência em startups de tecnologia
            </p>
          </div>
          
          <div className="text-center mt-12 max-w-2xl">
            <p className="text-diva-dark/80 text-lg">
              Além do nosso fundador, temos uma equipe dedicada de designers, desenvolvedores, 
              especialistas em atendimento ao cliente e profissionais de marketing, todos 
              comprometidos em tornar a AgendeDiva a melhor plataforma para profissionais da beleza.
            </p>
            
            <div className="mt-8">
              <Link 
                to="/sobre" 
                className="text-diva-pink hover:text-diva-purple transition-colors font-medium"
              >
                Saiba mais sobre nossa história →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection; 