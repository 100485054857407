import { supabase } from '@/lib/supabase';
import { Client } from '@/types/appointment';

export const clientService = {
  // Criar um novo cliente
  async create(client: Omit<Client, 'id' | 'created_at' | 'updated_at'>) {
    const { data, error } = await supabase
      .from('clients')
      .insert([client])
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  // Buscar todos os clientes de um profissional
  async listByProfessional(professionalId: string) {
    try {
      console.log('Buscando clientes para o profissional:', professionalId);
      
      const { data, error } = await supabase
        .from('clients')
        .select(`
          *,
          appointments(
            *,
            service:services(*)
          )
        `)
        .eq('professional_id', professionalId)
        .order('name', { ascending: true });

      if (error) {
        console.error('Erro ao buscar clientes:', error);
        throw error;
      }
      
      console.log('Dados de clientes retornados:', data?.length || 0, 'registros');
      return data || [];
    } catch (error) {
      console.error('Falha na busca de clientes:', error);
      throw error;
    }
  },

  // Buscar cliente por ID
  async getById(id: string) {
    const { data, error } = await supabase
      .from('clients')
      .select('*, appointments(*)')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  },

  // Atualizar cliente
  async update(id: string, client: Partial<Client>) {
    const { data, error } = await supabase
      .from('clients')
      .update(client)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  // Buscar histórico de agendamentos do cliente
  async getAppointmentHistory(clientId: string) {
    const { data, error } = await supabase
      .from('appointments')
      .select(`
        *,
        service:services(*),
        financial_transactions(*)
      `)
      .eq('client_id', clientId)
      .order('date', { ascending: false });

    if (error) throw error;
    return data;
  },

  // Buscar estatísticas do cliente
  async getStats(clientId: string) {
    const { data: appointments, error } = await supabase
      .from('appointments')
      .select(`
        *,
        financial_transactions(amount)
      `)
      .eq('client_id', clientId);

    if (error) throw error;

    return {
      total_appointments: appointments.length,
      total_spent: appointments.reduce((total, appointment) => {
        const transactions = appointment.financial_transactions || [];
        return total + transactions.reduce((sum, tx) => sum + (tx.amount || 0), 0);
      }, 0),
      last_visit: appointments[0]?.date || null,
    };
  }
}; 