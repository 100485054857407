import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Check } from "lucide-react";

const Pricing = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />

      <main className="flex-grow pt-24">
        <section className="section-padding">
          <div className="container mx-auto">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h1 className="text-4xl md:text-5xl font-bold text-diva-dark mb-4 font-serif">
                Preços simples e acessíveis
              </h1>
              <p className="text-xl text-diva-dark/70">
                Invista no crescimento do seu negócio com a melhor plataforma para profissionais da beleza
              </p>
            </div>

            <div className="max-w-4xl mx-auto">
              <div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-diva-pink-light/30">
                <div className="p-8 md:p-12 relative overflow-hidden">
                  {/* Background decoration */}
                  <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-b from-diva-pink-light/20 to-diva-purple-light/20 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
                  
                  <div className="relative">
                    <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                      <div className="mb-8 lg:mb-0">
                        <h2 className="text-3xl font-bold font-serif text-diva-dark mb-4">
                          Plano AgendeDiva Pro
                        </h2>
                        <div className="mb-6">
                          <div className="flex items-baseline">
                            <span className="text-5xl font-bold text-diva-dark">R$19</span>
                            <span className="text-3xl font-bold text-diva-dark">,90</span>
                            <span className="ml-2 text-diva-dark/60">/mês</span>
                          </div>
                          <p className="text-diva-dark/70 mt-2">
                            Cobrado mensalmente
                          </p>
                        </div>
                        <div>
                          <Link to="/signup">
                            <Button className="diva-button-primary w-full sm:w-auto">
                              Experimente 15 dias grátis
                            </Button>
                          </Link>
                          <p className="text-sm text-diva-dark/60 mt-2">
                            Sem necessidade de cartão de crédito
                          </p>
                        </div>
                      </div>
                      
                      <div className="bg-gradient-to-r from-diva-pink to-diva-purple text-white rounded-xl px-6 py-4 shadow-lg">
                        <h3 className="font-bold mb-2">O que está incluído:</h3>
                        <ul className="space-y-1 text-sm max-w-xs">
                          <li className="flex items-start">
                            <span className="mr-2 mt-0.5">✓</span>
                            Link personalizado para divulgação
                          </li>
                          <li className="flex items-start">
                            <span className="mr-2 mt-0.5">✓</span>
                            Sistema de agendamentos completo
                          </li>
                          <li className="flex items-start">
                            <span className="mr-2 mt-0.5">✓</span>
                            Gestão de clientes e histórico de serviços
                          </li>
                          <li className="flex items-start">
                            <span className="mr-2 mt-0.5">✓</span>
                            Notificações automáticas via WhatsApp
                          </li>
                          <li className="flex items-start">
                            <span className="mr-2 mt-0.5">✓</span>
                            Controle financeiro simplificado
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 p-8 md:p-12">
                  <h3 className="text-xl font-bold text-diva-dark mb-6">
                    Todos os recursos incluídos:
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4">
                    {[
                      "Link personalizado exclusivo",
                      "Agendamento online 24/7",
                      "Painel administrativo completo",
                      "Notificações automáticas",
                      "Lembretes para clientes",
                      "Histórico de serviços",
                      "Gestão financeira simplificada",
                      "Relatórios de desempenho",
                      "Personalização da página",
                      "Integrações com WhatsApp",
                      "Gestão de disponibilidade",
                      "Pagamentos online (PIX, cartão)",
                      "Suporte prioritário"
                    ].map((feature, index) => (
                      <div key={index} className="flex items-center">
                        <div className="flex-shrink-0 flex items-center justify-center w-5 h-5 rounded-full bg-diva-pink mr-3">
                          <Check className="w-3 h-3 text-white" />
                        </div>
                        <span className="text-diva-dark/80">{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16 text-center max-w-2xl mx-auto">
              <h3 className="text-2xl font-bold text-diva-dark mb-4 font-serif">
                Perguntas Frequentes
              </h3>
              <div className="mt-8 space-y-6 text-left">
                {[
                  {
                    question: "Como funciona o período de teste gratuito?",
                    answer: "Você pode testar todas as funcionalidades da plataforma por 15 dias, sem compromisso. Não solicitamos dados de cartão de crédito durante o teste. Ao final do período, você pode optar por assinar o plano mensal ou cancelar."
                  },
                  {
                    question: "Posso personalizar meu link?",
                    answer: "Sim! Você pode escolher um nome exclusivo para seu link, por exemplo: agendediva.com/seunegocio. Esse link será sua identidade digital para compartilhar com clientes."
                  },
                  {
                    question: "Existe alguma taxa de configuração ou setup?",
                    answer: "Não há nenhuma taxa adicional. O valor mensal de R$19,90 cobre todas as funcionalidades da plataforma, sem custos extras ou escondidos."
                  },
                  {
                    question: "Posso cancelar a qualquer momento?",
                    answer: "Sim, você pode cancelar sua assinatura quando quiser, sem multas ou taxas adicionais. Seu acesso permanecerá ativo até o fim do período já pago."
                  }
                ].map((faq, index) => (
                  <div key={index} className="bg-white rounded-xl p-6 border border-diva-pink-light/20 shadow-sm">
                    <h4 className="font-bold text-diva-dark mb-2">{faq.question}</h4>
                    <p className="text-diva-dark/70">{faq.answer}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding bg-diva-dark">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-6 font-serif">
              Pronta para transformar seu negócio?
            </h2>
            <p className="text-white/80 max-w-2xl mx-auto mb-8">
              Junte-se a milhares de profissionais da beleza que já estão usando o AgendeDiva para 
              gerenciar seus negócios de forma profissional.
            </p>
            <Link to="/signup">
              <Button className="bg-white hover:bg-diva-pink-light text-diva-dark hover:text-diva-dark font-medium text-lg px-8 py-6">
                Começar agora
              </Button>
            </Link>
            <p className="text-white/60 mt-4">15 dias grátis. Cancele quando quiser.</p>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Pricing;
