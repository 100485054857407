import { useState, useEffect, useMemo } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { format, isWeekend } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast } from 'sonner';
import { serviceService } from '@/services/serviceService';
import { settingsService } from '@/services/settingsService';
import { appointmentService } from '@/services/appointmentService';
import { Service } from '@/types/appointment';
import { BusinessSettings } from '@/types/settings';
import { MapPin, Calendar as CalendarIcon, Clock, AlertCircle, Scissors, DollarSign, Phone, Mail, ChevronDown, FileText, MessageCircle, Instagram, AlertTriangle, Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Separator } from '@/components/ui/separator';
import { motion } from 'framer-motion';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { formatPhoneNumber } from '@/lib/utils';
import '../styles/publicScheduling.styles.css';
import PageNotFound from './PageNotFound';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

// Estilos de animação para o componente Collapsible
const slideAnimation = {
  open: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
      opacity: { duration: 0.2 }
    }
  },
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.2 }
    }
  }
};

interface BusinessInfo {
  logo_url?: string;
  // ... existing business info types ...
}

// Função para renderizar Markdown simples
const renderMarkdown = (text: string) => {
  // Substituir ** para negrito
  const boldText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  // Substituir _ para itálico
  const formattedText = boldText.replace(/_(.*?)_/g, '<em>$1</em>');
  
  return formattedText;
};

// Função para renderizar parágrafos
const renderDescription = (description: string | undefined) => {
  if (!description) return null;
  
  // Dividir por parágrafos
  const paragraphs = description.split('\n');
  
  return paragraphs.map((paragraph, index) => {
    if (paragraph.trim() === '') return null;
    
    // Aplicar Markdown a cada parágrafo
    const formattedText = renderMarkdown(paragraph);
    
    return (
      <p 
        key={index} 
        className="text-xs sm:text-sm leading-relaxed text-gray-600 mb-2"
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    );
  });
};

// Versão silenciosa do toast que não mostra erros de links inválidos
const silentToast = {
  error: () => {
    // Não faz nada, suprimindo todas as mensagens de erro
    return null;
  }
};

const DAYS_MAP = {
  "Domingo": 0,
  "Segunda-feira": 1,
  "Terça-feira": 2,
  "Quarta-feira": 3,
  "Quinta-feira": 4,
  "Sexta-feira": 5,
  "Sábado": 6,
};

export default function PublicScheduling() {
  const { professionalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<Service[]>([]);
  const [businessSettings, setBusinessSettings] = useState<BusinessSettings | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [availableHours, setAvailableHours] = useState<string[]>([]);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [servicesExpanded, setServicesExpanded] = useState(false);
  const [loadingHours, setLoadingHours] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState<boolean | null>(null);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    serviceId: '',
    time: ''
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Estado para armazenar os dias disponíveis
  const [availableDays, setAvailableDays] = useState<number[]>([]);
  const [loadingAvailableDays, setLoadingAvailableDays] = useState(false);

  useEffect(() => {
    // Sobrescrever o console.error para evitar erros no console
    const originalConsoleError = console.error;
    console.error = function(...args) {
      // Não imprime erros no console para essa página
      return;
    };

    if (professionalId) {
      const loadInitialData = async () => {
        try {
          if (!professionalId) return;
          
          // Tentar primeiro buscar por URL personalizada
          let businessData;
          try {
            // Verificar primeiro se o ID fornecido é uma URL personalizada
            // e não um UUID bruto que causaria o erro "Invalid input syntax for type uuid"
            if (professionalId && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(professionalId)) {
              // É um UUID válido, buscar diretamente pelo ID
              businessData = await settingsService.getBusiness(professionalId);
            } else {
              // É provavelmente uma URL personalizada
              businessData = await settingsService.getBusinessByCustomUrl(professionalId);
            }
          } catch (error) {
            // Se ocorrer algum erro na primeira tentativa, tentar novamente com o método alternativo
            try {
              // Se a primeira chamada era getBusinessByCustomUrl, agora tentamos getBusiness
              // Se a primeira era getBusiness, agora tentamos getBusinessByCustomUrl
              if (professionalId && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(professionalId)) {
                businessData = await settingsService.getBusinessByCustomUrl(professionalId);
              } else {
                businessData = await settingsService.getBusiness(professionalId);
              }
            } catch (innerError) {
              // Silenciosamente falha
              setLoading(false);
              return;
            }
          }

          if (!businessData) {
            setLoading(false);
            return;
          }

          // Buscar serviços do profissional usando o ID obtido
          try {
            const servicesData = await serviceService.listByProfessional(businessData.professional_id);
            setServices(servicesData.filter(s => s.active));
            setBusinessSettings(businessData);
          } catch (error) {
            // Silenciosamente falha
          }
        } catch (error) {
          // Silenciosamente falha
        } finally {
          setLoading(false);
        }
      };

      loadInitialData();
    }

    // Restaurar o console.error original ao desmontar o componente
    return () => {
      console.error = originalConsoleError;
    };
  }, [professionalId]);

  // Efeito para carregar os dias disponíveis
  useEffect(() => {
    if (professionalId) {
      const loadAvailableDays = async () => {
        setLoadingAvailableDays(true);
        try {
          // Buscar configuração de horários para o profissional
          const { data, error } = await supabase
            .from('working_hours')
            .select('day_of_week, is_available')
            .eq('professional_id', businessSettings?.professional_id || professionalId);

          if (error) {
            console.error('Erro ao carregar dias disponíveis:', error);
            // Se ocorrer erro, consideramos todos os dias de semana disponíveis como fallback
            setAvailableDays([1, 2, 3, 4, 5]); // Segunda a sexta por padrão
            return;
          }

          if (data && data.length > 0) {
            // Filtrar apenas os dias marcados como disponíveis
            const availableDays = data
              .filter(day => day.is_available)
              .map(day => day.day_of_week);

            setAvailableDays(availableDays);
          } else {
            // Se não houver configuração, assume dias de semana como padrão
            setAvailableDays([1, 2, 3, 4, 5]); // Segunda a sexta por padrão
          }
        } catch (error) {
          console.error('Erro ao carregar dias disponíveis:', error);
          // Em caso de erro, consideramos todos os dias de semana disponíveis como fallback
          setAvailableDays([1, 2, 3, 4, 5]); // Segunda a sexta por padrão
        } finally {
          setLoadingAvailableDays(false);
        }
      };

      loadAvailableDays();
    }
  }, [professionalId, businessSettings]);

  // Efeito para carregar horários disponíveis quando uma data ou serviço for selecionado
  useEffect(() => {
    if (selectedDate && professionalId) {
      const loadAvailableHours = async () => {
        setLoadingHours(true);
        try {
          const formattedDate = format(selectedDate, 'yyyy-MM-dd');
          console.log(`Buscando horários disponíveis para a data: ${formattedDate}`);
          
          const todayDateStr = format(new Date(), 'yyyy-MM-dd');
          console.log(`Data de hoje: ${todayDateStr}. É hoje? ${formattedDate === todayDateStr}`);
          
          const realProfessionalId = businessSettings?.professional_id || professionalId || '';
          
          // Passar o ID do serviço selecionado para considerar sua duração
          const hours = await appointmentService.getAvailableHours(
            realProfessionalId, 
            formattedDate,
            formData.serviceId || undefined
          );
          
          console.log(`Horários disponíveis retornados: ${hours.length}`, hours);
          setAvailableHours(hours);
          
          // Resetar o horário selecionado quando mudar a data ou serviço
          setFormData(prev => ({ ...prev, time: '' }));
        } catch (error) {
          console.error('Erro ao carregar horários disponíveis:', error);
          setAvailableHours([]);
          toast.error('Não foi possível carregar os horários disponíveis. Tente novamente.', {
            duration: 3000,
            id: 'error-loading-hours'
          });
        } finally {
          setLoadingHours(false);
        }
      };
      
      loadAvailableHours();
    } else {
      setAvailableHours([]);
    }
  }, [selectedDate, professionalId, businessSettings, formData.serviceId]);

  // Verificar se o salão está aberto agora com base no horário de funcionamento
  const checkIfBusinessIsOpen = (hours: string) => {
    try {
      const now = new Date();
      const currentDayName = Object.keys(DAYS_MAP).find(
        key => DAYS_MAP[key as keyof typeof DAYS_MAP] === now.getDay()
      );
      
      if (!currentDayName) return false;
      
      const hourLines = hours.split('\n');
      const currentDayLine = hourLines.find(line => line.startsWith(currentDayName));
      
      if (!currentDayLine) return false;
      
      const [_, hoursText] = currentDayLine.split(':');
      if (hoursText.trim().toLowerCase() === 'fechado') return false;
      
      const [startTimeStr, endTimeStr] = hoursText.trim().split(' às ');
      
      if (!startTimeStr || !endTimeStr) return false;
      
      const [startHour, startMinute] = startTimeStr.split(':').map(Number);
      const [endHour, endMinute] = endTimeStr.split(':').map(Number);
      
      const startDate = new Date();
      startDate.setHours(startHour, startMinute, 0);
      
      const endDate = new Date();
      endDate.setHours(endHour, endMinute, 0);
      
      return now >= startDate && now <= endDate;
    } catch (error) {
      console.error('Erro ao verificar se o negócio está aberto:', error);
      return false;
    }
  };

  // Efeito para verificar se o salão está aberto quando as configurações são carregadas
  useEffect(() => {
    if (businessSettings?.hours) {
      const isOpen = checkIfBusinessIsOpen(businessSettings.hours);
      setIsBusinessOpen(isOpen);
    }
  }, [businessSettings?.hours]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validações antes de prosseguir
    if (!formData.name.trim()) {
      setErrorMessage('Por favor, informe seu nome completo');
      return;
    }
    
    if (!formData.email.trim()) {
      setErrorMessage('Por favor, informe seu email');
      return;
    }
    
    // Validar formato de email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Por favor, informe um email válido');
      return;
    }
    
    // Validar telefone (pelo menos 14 caracteres no formato (99) 9 9999-9999)
    if (!formData.phone.trim() || formData.phone.length < 14) {
      setErrorMessage('Por favor, informe um telefone válido com DDD');
      return;
    }
    
    // Validar serviço selecionado
    if (!formData.serviceId) {
      setErrorMessage('Por favor, selecione um serviço');
      return;
    }
    
    // Validar data selecionada
    if (!selectedDate) {
      setErrorMessage('Por favor, selecione uma data');
      return;
    }
    
    // Validar horário selecionado
    if (!formData.time) {
      setErrorMessage('Por favor, selecione um horário');
      return;
    }
    
    // Verificar se o horário ainda está disponível
    if (!availableHours.includes(formData.time)) {
      setErrorMessage('Este horário não está mais disponível. Por favor, selecione outro horário.');
      return;
    }
    
    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // Garantir que temos um ID válido para o profissional
      const professionalUuid = businessSettings?.professional_id || (professionalId || '');
      
      // Formatar a data para garantir formato YYYY-MM-DD
      // Ajuste para lidar com o fuso horário corretamente
      const selectedDateObj = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000);
      const formattedDate = format(selectedDateObj, 'yyyy-MM-dd');
      
      console.log('Enviando agendamento com data:', formattedDate);
      
      const appointment = {
        client_name: formData.name,
        client_phone: formData.phone,
        client_email: formData.email,
        service_id: formData.serviceId,
        professional_id: professionalUuid,
        date: formattedDate,  // Data já formatada corretamente
        time_slot: formData.time,
        status: 'pending'
      };

      console.log('Dados do agendamento a serem enviados:', appointment);
      
      const createdAppointment = await appointmentService.create(appointment);
      console.log('Agendamento criado com sucesso:', createdAppointment);
      
      // Exibir mensagem de sucesso
      toast.success('Agendamento realizado com sucesso!');
      
      // Resetar formulário
      setFormData({ name: '', phone: '', email: '', serviceId: '', time: '' });
      setSelectedDate(undefined);
      setAvailableHours([]);
      
      // Redirecionar para uma página de confirmação ou mostrar mensagem de confirmação
      // TODO: Implementar página de confirmação
    } catch (error: unknown) {
      console.error('Erro ao criar agendamento:', error);
      
      // Verificar se o erro é devido a um conflito de horário
      const errorMessage = error instanceof Error ? error.message : String(error);
      
      if (errorMessage.includes('already booked') || errorMessage.includes('conflict')) {
        setErrorMessage('Este horário já foi reservado. Por favor, escolha outro horário.');
        
        // Recarregar os horários disponíveis
        if (selectedDate) {
          const formattedDate = format(selectedDate, 'yyyy-MM-dd');
          const professionalUuid = businessSettings?.professional_id || (professionalId || '');
          
          try {
            const newHours = await appointmentService.getAvailableHours(professionalUuid, formattedDate);
            setAvailableHours(newHours);
            setFormData(prev => ({ ...prev, time: '' }));
          } catch (hourError) {
            console.error('Erro ao atualizar horários:', hourError);
          }
        }
      } else {
        // Mensagem genérica para outros tipos de erro
        setErrorMessage('Não foi possível realizar o agendamento. Tente novamente mais tarde.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isNoServiceDay = (date: Date) => {
    return isWeekend(date) || date.getDay() === 0;
  };

  const getInitials = (businessName: string) => {
    if (!businessName) return 'BZ';
    
    const words = businessName.trim().split(' ').filter(word => word.length > 0);
    if (words.length === 0) return 'BZ';
    
    if (words.length === 1) {
      // Se for uma palavra só, pega as duas primeiras letras
      return words[0].slice(0, 2).toUpperCase();
    }
    
    // Pega a primeira letra das duas primeiras palavras
    return words.slice(0, 2).map(word => word[0]).join('').toUpperCase();
  };

  // Função para formatar o número de WhatsApp para uso no link
  const formatWhatsAppNumber = (number: string | undefined): string => {
    if (!number) return '';
    // Remove todos os caracteres não numéricos
    const digitsOnly = number.replace(/\D/g, '');
    
    // Se o número já começar com 55 (código do Brasil), mantém como está
    if (digitsOnly.startsWith('55') && digitsOnly.length >= 12) {
      return digitsOnly;
    }
    
    // Caso contrário, assume que é um número brasileiro e adiciona o código do país
    return `55${digitsOnly}`;
  };

  // Handler para formatar o telefone enquanto o usuário digita
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, phone: formattedPhone });
  };

  // Função para verificar se um dia está disponível
  const isDayUnavailable = (date: Date) => {
    const dayOfWeek = date.getDay();
    // Comparação adequada para datas passadas - agora só bloqueia dias anteriores a hoje, não o dia de hoje
    const isPastDate = date < new Date(new Date().setHours(0, 0, 0, 0));
    
    // Dia indisponível se for dia passado ou se não estiver nos dias disponíveis
    return isPastDate || !availableDays.includes(dayOfWeek);
  };

  // Função para obter o nome do dia da semana a partir do código numérico
  const getDayName = (dayNumber: number): string => {
    const days = Object.keys(DAYS_MAP);
    for (const day of days) {
      if (DAYS_MAP[day as keyof typeof DAYS_MAP] === dayNumber) {
        return day;
      }
    }
    return "";
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-pink-50 to-purple-50">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-diva-pink border-t-transparent"></div>
      </div>
    );
  }

  if (!businessSettings) {
    return <PageNotFound />;
  }

  // Definindo as variáveis CSS personalizadas
  document.documentElement.style.setProperty('--header-bg-color', businessSettings.header_bg_color || '#f9f9f9');
  document.documentElement.style.setProperty('--business-name-color', businessSettings.business_name_color || '#1f2937');
  document.documentElement.style.setProperty('--decorator-line-color', businessSettings.decorator_line_color || '#e11d8f');

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-50 to-purple-50 py-1 px-1 sm:py-6 sm:px-6">
      <motion.div 
        className="container mx-auto max-w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
        initial={fadeIn.initial}
        animate={fadeIn.animate}
        transition={fadeIn.transition}
      >
        <Card className="p-3 sm:p-6 shadow-xl backdrop-blur-sm bg-white/90 border border-diva-pink/20 rounded-xl sm:rounded-2xl">
          {/* Header com design moderno e acessível */}
          <div className="relative rounded-lg sm:rounded-xl overflow-hidden mb-6 sm:mb-8 headerBackground">
            {/* Decoração sutil no topo */}
            <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-diva-pink to-purple-600 opacity-80 decoratorLine"></div>
            
            <div className="py-6 px-4 sm:py-8 sm:px-6">
              {/* Container flexível para melhor alinhamento em todos os dispositivos */}
              <div className="flex flex-col items-center">
                {businessSettings.logo_url ? (
                  <motion.div
                    className="w-24 h-24 sm:w-32 sm:h-32 relative rounded-xl overflow-hidden shadow-lg border-2 border-white"
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img 
                      src={businessSettings.logo_url} 
                      alt={businessSettings.business_name || "Logo da empresa"}
                      className="w-full h-full object-cover"
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    className="w-24 h-24 sm:w-32 sm:h-32 relative rounded-xl overflow-hidden shadow-lg"
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="w-full h-full bg-gradient-to-br from-diva-pink to-purple-600 flex items-center justify-center">
                      <span className="text-2xl sm:text-4xl font-bold text-white">
                        {getInitials(businessSettings.business_name)}
                      </span>
                    </div>
                  </motion.div>
                )}

                {businessSettings.show_business_name && (
                  <motion.div 
                    className="mt-4 sm:mt-5 text-center"
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    {/* Indicador de Status (Aberto/Fechado) */}
                    {isBusinessOpen !== null && businessSettings.show_hours && (
                      <motion.div
                        className={`mb-2 inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                          isBusinessOpen 
                            ? 'bg-green-100 text-green-800 border border-green-200' 
                            : 'bg-red-100 text-red-800 border border-red-200'
                        }`}
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        <span className={`h-2 w-2 rounded-full mr-2 ${isBusinessOpen ? 'bg-green-500' : 'bg-red-500'}`}></span>
                        {isBusinessOpen ? 'Aberto agora' : 'Fechado agora'}
                      </motion.div>
                    )}
                    
                    <h1 className="text-xl sm:text-3xl font-bold tracking-tight businessName">
                      {businessSettings.business_name}
                    </h1>
                    {/* Linha decorativa abaixo do nome */}
                    <div className="w-16 h-1 bg-gradient-to-r from-diva-pink to-purple-600 rounded-full mx-auto mt-3 opacity-80 decoratorLine"></div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>

          {/* Conteúdo principal em layout de cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-6">
            {/* Coluna da esquerda - Informações do negócio */}
            <div className="space-y-3 sm:space-y-5">
              {/* Descrição */}
              {businessSettings.show_description && businessSettings.description && (
                <motion.div 
                  className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  whileHover={{ y: -2, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-start gap-3 sm:gap-4">
                    <div className="bg-diva-pink/10 rounded-full p-2 sm:p-2.5 flex-shrink-0">
                      <FileText className="h-4 w-4 sm:h-5 sm:w-5 text-diva-pink" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-1 sm:mb-2">Sobre nós</h3>
                      <div className="space-y-1">
                        {renderDescription(businessSettings.description)}
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}

              {/* Localização */}
              {businessSettings.show_address && businessSettings.address && (
                <motion.div 
                  className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  whileHover={{ y: -2, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-start gap-3 sm:gap-4">
                    <div className="bg-diva-pink/10 rounded-full p-2 sm:p-2.5 flex-shrink-0">
                      <MapPin className="h-4 w-4 sm:h-5 sm:w-5 text-diva-pink" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-1 sm:mb-2">Localização</h3>
                      <p className="text-xs sm:text-sm leading-relaxed text-gray-600">{businessSettings.address}</p>
                    </div>
                  </div>
                </motion.div>
              )}

              {/* WhatsApp */}
              {businessSettings.show_whatsapp && businessSettings.whatsapp && businessSettings.allow_online_booking && (
                <motion.div 
                  className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.33 }}
                  whileHover={{ y: -2, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-start gap-3 sm:gap-4">
                    <div className="bg-green-500 rounded-full p-2 sm:p-2.5 flex-shrink-0">
                      <MessageCircle className="h-4 w-4 sm:h-5 sm:w-5 text-white" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-1 sm:mb-2">Fale conosco</h3>
                      <a 
                        href={`https://wa.me/${formatWhatsAppNumber(businessSettings.whatsapp)}`}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-xs sm:text-sm text-green-600 hover:text-green-700 font-medium flex items-center gap-1"
                      >
                        Clique para falar no WhatsApp
                        <span className="text-xs opacity-70">↗</span>
                      </a>
                    </div>
                  </div>
                </motion.div>
              )}

              {/* Instagram */}
              {businessSettings.show_instagram && businessSettings.instagram_url && (
                <motion.div 
                  className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.35 }}
                  whileHover={{ y: -2, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-start gap-3 sm:gap-4">
                    <div className="bg-gradient-to-br from-pink-500 to-purple-600 rounded-full p-2 sm:p-2.5 flex-shrink-0">
                      <Instagram className="h-4 w-4 sm:h-5 sm:w-5 text-white" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-1 sm:mb-2">Siga nas redes sociais</h3>
                      <a 
                        href={`https://instagram.com/${businessSettings.instagram_url}`}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-xs sm:text-sm text-pink-600 hover:text-pink-700 font-medium flex items-center gap-1"
                      >
                        @{businessSettings.instagram_url}
                        <span className="text-xs opacity-70">↗</span>
                      </a>
                    </div>
                  </div>
                </motion.div>
              )}

              {/* Horário de Funcionamento - Design Aprimorado */}
              {businessSettings.show_hours && businessSettings.hours && (
                <motion.div 
                  className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  whileHover={{ y: -2, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-start gap-3 sm:gap-4">
                    <div className="bg-diva-pink/10 rounded-full p-2 sm:p-2.5 flex-shrink-0">
                      <Clock className="h-4 w-4 sm:h-5 sm:w-5 text-diva-pink" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-2 sm:mb-3">Horários de Funcionamento</h3>
                      <div className="space-y-2 sm:space-y-2.5">
                        {businessSettings.hours.split('\n').map((line, index) => {
                          const [day, hours] = line.split(':');
                          const isClosed = hours.trim().toLowerCase() === 'fechado';
                          
                          return (
                            <div 
                              key={index} 
                              className={`rounded-md p-1.5 ${isClosed ? 
                                'bg-red-50 border border-red-100' : 
                                'bg-green-50 border border-green-100'}`}
                            >
                              <div className="flex justify-between items-center">
                                <span className={`font-medium text-xs sm:text-sm ${isClosed ? 'text-red-700' : 'text-green-700'}`}>
                                  {day}
                                </span>
                                <span className={`text-xs sm:text-sm font-medium ${isClosed ? 
                                  'text-red-600 flex items-center' : 
                                  'text-green-600 flex items-center'}`}
                                >
                                  {isClosed ? (
                                    <>
                                      <span className="h-1.5 w-1.5 rounded-full bg-red-500 mr-1.5"></span>
                                      Fechado
                                    </>
                                  ) : (
                                    <>
                                      <span className="h-1.5 w-1.5 rounded-full bg-green-500 mr-1.5"></span>
                                      {hours}
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      
                      {/* Legenda Visual */}
                      <div className="mt-3 pt-3 border-t border-gray-100">
                        <p className="text-xs text-gray-500 flex items-center">
                          <Clock className="h-3 w-3 mr-1 text-diva-pink/70" />
                          Os horários acima mostram quando estamos disponíveis para atendimento
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>

            {/* Coluna da direita - Serviços e agendamento */}
            <div>
              {/* Lista de Serviços */}
              {!businessSettings.allow_online_booking ? (
                <>
                  <motion.div
                    className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 mb-3 sm:mb-5 shadow-sm border border-gray-100"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    <Alert className="bg-gradient-to-r from-yellow-50 to-orange-50 border-yellow-200 mb-3">
                      <AlertCircle className="h-4 w-4 sm:h-5 sm:w-5 text-yellow-600" />
                      <AlertDescription className="text-xs sm:text-sm text-yellow-800 font-medium">
                        O agendamento online não está disponível no momento.
                      </AlertDescription>
                    </Alert>
                    
                    {businessSettings.whatsapp && (
                      <div className="mt-3">
                        <a 
                          href={`https://wa.me/${formatWhatsAppNumber(businessSettings.whatsapp)}`}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="flex items-center justify-center gap-2 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg w-full transition-colors"
                        >
                          <MessageCircle className="h-4 w-4 sm:h-5 sm:w-5" />
                          <span className="font-medium text-sm sm:text-base">Fale Conosco pelo WhatsApp</span>
                        </a>
                        <p className="text-xs text-gray-500 mt-2 text-center">
                          Entre em contato para obter informações sobre disponibilidade
                        </p>
                      </div>
                    )}
                  </motion.div>

                  <motion.div
                    className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.6 }}
                  >
                    <Collapsible 
                      open={servicesExpanded}
                      onOpenChange={setServicesExpanded}
                    >
                      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-3 gap-2">
                        <div className="flex items-center">
                          <Scissors className="h-4 w-4 sm:h-5 sm:w-5 mr-2 text-diva-pink" />
                          <h2 className="text-base sm:text-lg font-semibold text-gray-800 flex items-center">
                            Nossos Serviços
                          </h2>
                        </div>
                        <CollapsibleTrigger asChild>
                          <Button 
                            className={`text-sm px-4 py-3 h-auto flex items-center gap-2 w-full justify-center rounded-md font-medium
                              ${servicesExpanded 
                                ? 'bg-diva-pink text-white hover:bg-diva-pink/90' 
                                : 'bg-diva-pink/10 text-diva-pink hover:bg-diva-pink/20'}`}
                          >
                            {servicesExpanded ? (
                              <>
                                <span className="font-medium">Ocultar serviços</span>
                                <ChevronDown className="h-4 w-4 transform rotate-180 transition-transform" />
                              </>
                            ) : (
                              <>
                                <span className="font-medium">Ver nossos serviços</span>
                                <ChevronDown className="h-4 w-4 transition-transform" />
                              </>
                            )}
                          </Button>
                        </CollapsibleTrigger>
                      </div>
                      
                      <CollapsibleContent forceMount>
                        <motion.div
                          className="mt-4"
                          variants={slideAnimation}
                        >
                          {/* Tabela de serviços - cabeçalho */}
                          <div className="overflow-hidden border-b border-gray-200 rounded-t-lg mb-2">
                            <div className="flex items-center bg-gradient-to-r from-diva-pink/5 to-diva-purple/5 py-2 px-3 sm:px-4">
                              <div className="flex-1 text-xs sm:text-sm font-medium text-gray-700">SERVIÇO</div>
                              <div className="w-auto text-xs sm:text-sm font-medium text-gray-700 text-right flex shrink-0">
                                <span className="hidden xs:inline-block w-16 sm:w-20 text-center">DURAÇÃO</span>
                                <span className="hidden xs:inline-block w-16 sm:w-20 text-center">PREÇO</span>
                                <span className="inline-block xs:hidden w-auto">DETALHES</span>
                              </div>
                            </div>
                          </div>

                          {/* Lista de serviços */}
                          {services.length === 0 ? (
                            <div className="py-6 text-center text-gray-500 border border-dashed border-gray-200 rounded-lg">
                              <Scissors className="h-10 w-10 mx-auto mb-2 text-gray-300" />
                              <p>Nenhum serviço disponível</p>
                            </div>
                          ) : (
                            <div className="bg-white rounded-lg">
                              {services.map((service, index) => (
                                <motion.div 
                                  key={service.id}
                                  className="py-3 md:py-4 border-b border-gray-100 last:border-0 flex justify-between items-start px-3 sm:px-4"
                                  initial={{ opacity: 0, y: 10 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.2, delay: index * 0.1 }}
                                >
                                  <div className="flex-1">
                                    <h3 className="text-sm sm:text-base font-semibold text-gray-800">{service.name}</h3>
                                    {service.description && (
                                      <p className="text-xs sm:text-sm text-gray-500 mt-1">{service.description}</p>
                                    )}
                                    <div className="flex flex-wrap items-center gap-2 sm:gap-3 mt-2 serviço-info-container">
                                      <span className="inline-flex items-center text-xs sm:text-sm text-gray-500 serviço-duração serviço-info-item">
                                        <Clock className="h-3 w-3 sm:h-3.5 sm:w-3.5 mr-1 text-diva-pink/70 serviço-duração-icon" />
                                        <span className="whitespace-nowrap">{service.duration_minutes} min</span>
                                      </span>
                                      {businessSettings.show_prices && (
                                        <span className="font-medium text-diva-pink text-xs sm:text-sm whitespace-nowrap serviço-preço serviço-info-item">
                                          <span className="serviço-preço-valor">R$ {service.price.toFixed(2).replace('.', ',')}</span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {businessSettings.whatsapp && businessSettings.show_whatsapp && (
                                    <a
                                      href={`https://wa.me/${formatWhatsAppNumber(businessSettings.whatsapp)}?text=Olá! Gostaria de informações sobre o serviço de ${service.name}.`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="ml-2 flex-shrink-0 p-1.5 sm:p-2 text-green-600 hover:text-green-700 bg-green-50 hover:bg-green-100 rounded-full transition-colors"
                                      aria-label={`Perguntar sobre ${service.name}`}
                                      title={`Perguntar sobre ${service.name}`}
                                    >
                                      <MessageCircle className="h-3.5 w-3.5 sm:h-4 sm:w-4 md:h-5 md:w-5" />
                                    </a>
                                  )}
                                </motion.div>
                              ))}
                            </div>
                          )}
                        </motion.div>
                      </CollapsibleContent>
                    </Collapsible>
                  </motion.div>
                </>
              ) : (
                <motion.div
                  className="space-y-3 sm:space-y-5"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <Card className="max-w-md w-full bg-white shadow-lg rounded-lg p-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-6">
                      Agendar Serviço
                    </h2>

                    {errorMessage && (
                      <div className="mb-6 p-3 bg-red-50 border border-red-200 text-red-600 rounded-md text-sm">
                        {errorMessage}
                      </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-5">
                      {/* Dados Pessoais */}
                      <div className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100">
                        <h2 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 sm:mb-4 flex items-center">
                          <Phone className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
                          Dados Pessoais
                        </h2>
                        
                        <div className="space-y-3 sm:space-y-4">
                          <div>
                            <Label htmlFor="name" className="text-xs sm:text-sm font-medium text-gray-700">Nome</Label>
                            <Input
                              id="name"
                              value={formData.name}
                              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                              placeholder="Seu nome completo"
                              className="mt-1 text-sm h-8 sm:h-10"
                              required
                            />
                          </div>

                          <div>
                            <Label htmlFor="phone" className="text-xs sm:text-sm font-medium text-gray-700">Telefone</Label>
                            <div className="relative mt-1">
                              <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 h-3.5 w-3.5 sm:h-4 sm:w-4 text-gray-400" />
                              <Input
                                id="phone"
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                placeholder="(99) 99999-9999"
                                className="pl-9 sm:pl-10 text-sm h-8 sm:h-10"
                                required
                              />
                            </div>
                          </div>

                          <div>
                            <Label htmlFor="email" className="text-xs sm:text-sm font-medium text-gray-700">E-mail</Label>
                            <div className="relative mt-1">
                              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-3.5 w-3.5 sm:h-4 sm:w-4 text-gray-400" />
                              <Input
                                id="email"
                                type="email"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                placeholder="seu@email.com"
                                className="pl-9 sm:pl-10 text-sm h-8 sm:h-10"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Serviço */}
                      <div className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100">
                        <h2 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 sm:mb-4 flex items-center">
                          <Scissors className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
                          Serviço
                        </h2>
                        
                        <div>
                          <Label htmlFor="service" className="text-xs sm:text-sm font-medium text-gray-700">Selecione o serviço desejado</Label>
                          <Select
                            value={formData.serviceId}
                            onValueChange={(value) => setFormData({ ...formData, serviceId: value })}
                          >
                            <SelectTrigger className="mt-1 sm:mt-2 h-8 sm:h-10 text-xs sm:text-sm">
                              <SelectValue placeholder="Escolha um serviço" />
                            </SelectTrigger>
                            <SelectContent>
                              {services.map((service) => (
                                <SelectItem key={service.id} value={service.id} className="text-xs sm:text-sm">
                                  <div className="flex items-center justify-between w-full serviço-seletor-container">
                                    <span className="truncate mr-1 serviço-nome-truncate">{service.name}</span>
                                    {businessSettings.show_prices && (
                                      <span className="text-diva-pink font-medium ml-1 sm:ml-2 text-xs sm:text-sm whitespace-nowrap flex-shrink-0 serviço-preço">
                                        <span className="serviço-preço-valor">R$ {service.price.toFixed(2).replace('.', ',')}</span>
                                      </span>
                                    )}
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                      </div>

                      {/* Data e Hora */}
                      <div className="bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 shadow-sm border border-gray-100">
                        <h2 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 sm:mb-4 flex items-center">
                          <CalendarIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
                          Data e Horário
                        </h2>
                        
                        <div className="space-y-3 sm:space-y-4">
                          <div>
                            <Label className="text-xs sm:text-sm font-medium text-gray-700 mb-1 sm:mb-2 block">Selecione uma data</Label>
                            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                              <Calendar
                                mode="single"
                                selected={selectedDate}
                                onSelect={setSelectedDate}
                                locale={ptBR}
                                disabled={isDayUnavailable}
                                className="w-full max-w-full mx-auto py-2 px-1 sm:p-3"
                                modifiersClassNames={{
                                  selected: "bg-diva-pink text-white hover:bg-diva-pink hover:text-white font-bold",
                                  today: "bg-diva-pink/10 text-diva-pink ring-2 ring-diva-pink font-bold",
                                  disabled: "text-gray-300 line-through bg-gray-50",
                                  available: "bg-green-50 text-green-700 hover:bg-green-100 font-medium",
                                }}
                                modifiers={{
                                  available: (date) => {
                                    const dayOfWeek = date.getDay();
                                    const isPastDate = date < new Date(new Date().setHours(0, 0, 0, 0));
                                    return !isPastDate && availableDays.includes(dayOfWeek);
                                  }
                                }}
                                classNames={{
                                  day: cn(
                                    "h-8 w-8 sm:h-9 sm:w-9 p-0 font-medium aria-selected:opacity-100 text-xs sm:text-sm rounded-md transition-colors",
                                    "hover:bg-gray-100",
                                    "data-[today]:bg-diva-pink/10 data-[today]:text-diva-pink data-[today]:ring-2 data-[today]:ring-diva-pink",
                                    "aria-selected:bg-diva-pink aria-selected:text-white"
                                  ),
                                }}
                              />
                              
                              {/* Legenda aprimorada do calendário */}
                              <div className="flex flex-wrap gap-3 justify-center pt-3 mt-2 border-t border-gray-100 text-xs p-2">
                                <div className="flex items-center">
                                  <div className="w-3 h-3 rounded-full bg-diva-pink mr-1.5"></div>
                                  <span>Selecionado</span>
                                </div>
                                <div className="flex items-center">
                                  <div className="w-3 h-3 rounded-full bg-green-50 border border-green-300 mr-1.5"></div>
                                  <span>Disponível</span>
                                </div>
                                <div className="flex items-center">
                                  <div className="w-3 h-3 rounded-full bg-gray-200 mr-1.5"></div>
                                  <span>Indisponível</span>
                                </div>
                                <div className="flex items-center">
                                  <div className="w-3 h-3 rounded-full border-2 border-diva-pink mr-1.5"></div>
                                  <span>Hoje</span>
                                </div>
                              </div>
                              
                              {/* Informações adicionais sobre dias de atendimento */}
                              <div className="pt-2 pb-1 px-3 text-xs text-gray-600 bg-gray-50 border-t border-gray-200">
                                <div className="font-medium mb-1 flex items-center">
                                  <CalendarIcon className="w-3 h-3 mr-1 text-diva-pink" />
                                  Dias de atendimento:
                                </div>
                                <ul className="space-y-1">
                                  {availableDays.sort().map(dayNumber => (
                                    <li key={dayNumber} className="flex items-center">
                                      <span className="w-2 h-2 bg-green-500 rounded-full mr-1.5"></span>
                                      {getDayName(dayNumber)}
                                    </li>
                                  ))}
                                </ul>
                                <div className="mt-2 text-xs flex items-start">
                                  <AlertCircle className="w-3 h-3 mr-1 text-yellow-500 mt-0.5" />
                                  <span className="flex-1 text-gray-500">Os dias sem destaque verde estão indisponíveis para agendamento.</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <Label className="text-xs sm:text-sm font-medium text-gray-700">Horário disponível</Label>
                            {selectedDate && isNoServiceDay(selectedDate) ? (
                              <Alert className="mt-1 sm:mt-2 bg-gradient-to-r from-yellow-50 to-orange-50 border-yellow-200">
                                <AlertCircle className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-yellow-600" />
                                <AlertDescription className="text-yellow-800 text-xs sm:text-sm">
                                  Não há atendimento neste dia.
                                </AlertDescription>
                              </Alert>
                            ) : selectedDate && !loadingHours && availableHours.length === 0 ? (
                              <Alert className="mt-1 sm:mt-2 bg-gradient-to-r from-red-50 to-orange-50 border-red-200">
                                <AlertCircle className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-red-600" />
                                <AlertDescription className="text-red-800 text-xs sm:text-sm">
                                  Todos os horários para esta data já foram reservados. Por favor, selecione outra data.
                                </AlertDescription>
                              </Alert>
                            ) : (
                              <div className="space-y-1.5">
                                <Label className="text-xs sm:text-sm font-medium text-gray-700 mb-1 sm:mb-2 block">
                                  Escolha um horário
                                </Label>
                                
                                {loadingHours ? (
                                  <div className="flex items-center justify-center p-4 border rounded-lg bg-gray-50">
                                    <Loader2 className="h-5 w-5 animate-spin text-diva-pink mr-2" />
                                    <span className="text-sm text-gray-500">Carregando horários disponíveis...</span>
                                  </div>
                                ) : availableHours.length > 0 ? (
                                  <div className="grid grid-cols-3 sm:grid-cols-4 gap-2">
                                    {availableHours.map((hour) => {
                                      // Encontrar o serviço selecionado para mostrar a duração
                                      const selectedServiceData = services.find(s => s.id === formData.serviceId);
                                      const duration = selectedServiceData?.duration_minutes || 30;
                                      
                                      // Calcular horário de término
                                      const startTime = new Date(`2000-01-01T${hour}:00`);
                                      const endTime = new Date(startTime.getTime() + duration * 60000);
                                      const endTimeStr = format(endTime, 'HH:mm');
                                      
                                      return (
                                        <button
                                          key={hour}
                                          type="button"
                                          onClick={() => setFormData({ ...formData, time: hour })}
                                          className={`
                                            px-2 py-3 text-center rounded-lg border text-xs sm:text-sm font-medium whitespace-nowrap
                                            hover:border-diva-pink hover:bg-diva-pink/5 transition-colors
                                            ${formData.time === hour 
                                              ? 'bg-gradient-to-r from-diva-pink to-diva-purple text-white border-transparent'
                                              : 'bg-white text-gray-700 border-gray-200'
                                            }
                                          `}
                                        >
                                          <div className="flex flex-col items-center">
                                            <span className="font-semibold">{hour}</span>
                                            <span className="text-[10px] mt-0.5 opacity-80">
                                              {formData.serviceId ? `até ${endTimeStr}` : ''}
                                            </span>
                                          </div>
                                        </button>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="flex flex-col items-center justify-center p-4 border rounded-lg bg-gray-50">
                                    <div className="flex items-center text-yellow-600 mb-1">
                                      <AlertCircle className="h-4 w-4 mr-1" />
                                      <span className="text-sm font-medium">Sem horários disponíveis</span>
                                    </div>
                                    <p className="text-xs text-gray-500 text-center">
                                      {selectedDate ? 'Não há horários disponíveis para esta data. Por favor, escolha outra data.' : 'Selecione uma data para ver os horários disponíveis.'}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <Button
                          type="submit"
                          className="w-full bg-gradient-to-r from-diva-pink to-purple-600 hover:from-diva-pink/90 hover:to-purple-600/90 text-white py-3 sm:py-6 text-sm sm:text-base font-medium rounded-lg sm:rounded-xl shadow-md transition-all duration-300"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Enviando...' : 'Confirmar Agendamento'}
                        </Button>
                      </motion.div>
                    </form>
                  </Card>
                </motion.div>
              )}
            </div>
          </div>
        </Card>
      </motion.div>
    </div>
  );
} 