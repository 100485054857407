import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';

interface BypassSignupButtonProps {
  email: string;
  password: string;
  name: string;
  businessName: string;
  phone: string;
  disabled?: boolean;
}

export default function BypassSignupButton({
  email,
  password,
  name,
  businessName,
  phone,
  disabled = false
}: BypassSignupButtonProps) {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  const handleSignup = async () => {
    if (!email || !password || !name) {
      toast({
        title: 'Dados incompletos',
        description: 'Email, senha e nome são obrigatórios para criar sua conta.',
        variant: 'destructive'
      });
      return;
    }
    
    try {
      setIsLoading(true);
      
      // Gerar um identificador único para o link personalizado
      const timestamp = new Date().getTime();
      const random = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
      
      // Criar um link baseado no nome do negócio ou no nome do usuário
      let linkSlug;
      if (businessName) {
        linkSlug = businessName
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-z0-9]+/g, '')
          .replace(/\s+/g, '');
      } else {
        linkSlug = name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-z0-9]+/g, '')
          .replace(/\s+/g, '');
      }
      
      // Adicionar números aleatórios para garantir unicidade
      const safeLink = `${linkSlug}${random}`;
      
      // Criar conta com todos os dados necessários desde o início
      console.log('Criando conta com email:', email);
      const { data, error } = await supabase.auth.signUp({
        email: email.trim(),
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/login`,
          data: {
            nome_completo: name.trim(),
            nome_negocio: businessName.trim() || name.trim(),
            link_personalizado: safeLink,
            phone: phone || ''
          }
        }
      });
      
      if (error) {
        console.error('Erro ao criar conta:', error);
        throw error;
      }
      
      if (!data.user) {
        throw new Error('Não foi possível criar a conta. O serviço não retornou os dados do usuário.');
      }
      
      console.log('Conta criada com sucesso, ID:', data.user.id);
      
      // Criar perfil manualmente para garantir que os dados sejam salvos
      try {
        console.log('Criando perfil manualmente...');
        const { error: profileError } = await supabase
          .from('profiles')
          .insert([
            { 
              id: data.user.id,
              name: name.trim(), 
              email: email.trim(),
              phone: phone,
              nome_negocio: businessName.trim() || name.trim(),
              link_personalizado: safeLink,
              created_at: new Date(),
              updated_at: new Date()
            }
          ]);
        
        if (profileError) {
          console.error('Erro ao criar perfil:', profileError);
          // Se não conseguir criar o perfil, consideramos um erro crítico
          throw new Error(`Não foi possível salvar todas as informações da conta: ${profileError.message}`);
        }
        
        console.log('Perfil criado com sucesso!');
        
        // Criar configurações do negócio
        console.log('Criando configurações do negócio...');
        const { error: settingsError } = await supabase
          .from('business_settings')
          .insert([
            {
              professional_id: data.user.id,
              business_name: businessName.trim() || name.trim(),
              description: '',
              allow_online_booking: true,
              show_prices: true,
              show_business_name: true,
              created_at: new Date(),
              updated_at: new Date()
            }
          ]);
        
        if (settingsError) {
          console.error('Erro ao criar configurações do negócio:', settingsError);
          // Consideramos um erro crítico se não conseguir criar as configurações básicas
          throw new Error(`Não foi possível salvar as configurações do negócio: ${settingsError.message}`);
        }
        
        console.log('Configurações do negócio criadas com sucesso!');
      } catch (error) {
        console.error('Erro ao salvar dados completos:', error);
        // Tentar excluir o usuário para não deixar dados parciais
        try {
          await supabase.auth.admin.deleteUser(data.user.id);
        } catch (deleteError) {
          console.error('Erro ao excluir usuário após falha no cadastro:', deleteError);
        }
        throw error; // Propagar o erro para ser tratado mais acima
      }
      
      // Enviar email de confirmação
      try {
        console.log('Enviando email de confirmação...');
        await supabase.auth.resend({
          type: 'signup',
          email: email.trim(),
          options: {
            emailRedirectTo: `${window.location.origin}/login`
          }
        });
        console.log('Email de confirmação enviado com sucesso!');
      } catch (resendError) {
        console.warn('Erro ao enviar email de confirmação:', resendError);
        // Não tratamos como erro crítico, pois a conta já foi criada
      }
      
      // Sucesso! Redirecionar para página de confirmação
      toast({
        title: 'Conta criada com sucesso!',
        description: 'Todas as informações foram salvas. Um email de confirmação foi enviado.',
        duration: 8000
      });
      
      navigate('/signup/confirm', { state: { email } });
    } catch (error) {
      console.error('Erro completo na criação da conta:', error);
      
      let errorMessage = 'Não foi possível criar sua conta com todos os dados necessários.';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      toast({
        title: 'Falha na criação da conta',
        description: errorMessage,
        variant: 'destructive'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <Button
      variant="default"
      size="lg"
      onClick={handleSignup}
      disabled={isLoading || disabled}
      className="w-full bg-gradient-to-r from-diva-pink to-diva-purple text-white py-6 mt-2 text-base font-medium"
    >
      {isLoading ? (
        <>
          <span className="mr-2 h-4 w-4 animate-spin">↻</span>
          Criando sua conta...
        </>
      ) : (
        'Criar conta gratuitamente'
      )}
    </Button>
  );
} 