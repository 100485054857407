import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';

export default function PageNotFound() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-50 to-purple-50 py-6 px-4 flex items-center justify-center">
      <motion.div 
        className="container mx-auto max-w-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card className="p-6 sm:p-8 shadow-xl backdrop-blur-sm bg-white/90 border border-diva-pink/20 rounded-xl">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">Página não encontrada</h1>
            <div className="w-16 h-1 bg-diva-pink rounded-full mx-auto mb-6"></div>
            
            <p className="text-gray-600 mb-6">
              Não foi possível encontrar a página que você está procurando.
            </p>
            
            <div className="mb-8 p-4 bg-gray-50 rounded-lg border border-gray-200">
              <h2 className="text-lg font-medium text-gray-800 mb-2">Possíveis causas</h2>
              <ul className="text-sm text-gray-600 space-y-2 text-left">
                <li>• O link que você digitou pode estar incorreto</li>
                <li>• A página pode ter sido movida ou excluída</li>
                <li>• O profissional pode não estar mais disponível no sistema</li>
              </ul>
            </div>
            
            <Link to="/" className="inline-block">
              <Button 
                className="bg-diva-pink hover:bg-diva-pink/90 text-white"
              >
                Voltar para a página inicial
              </Button>
            </Link>
          </div>
        </Card>
      </motion.div>
    </div>
  );
} 