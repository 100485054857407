import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/useAuth';
import { settingsService } from '@/services/settingsService';
import { BusinessSettings } from '@/types/settings';
import { Copy, Image, X, Bold as BoldIcon, Italic as ItalicIcon, Smile, Instagram, FileImage, Scissors, Info, AlertCircle, Settings, CalendarRange } from 'lucide-react';
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';
import { formatPhoneNumber } from '@/lib/utils';
import EmojiPicker from 'emoji-picker-react';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import '../styles/business.styles.css';
import { motion } from 'framer-motion';
import { 
  ArrowLeft, 
  CalendarDays, 
  Clock, 
  MapPin, 
  Phone, 
  Store, 
  UploadCloud 
} from 'lucide-react';

// Dias da semana para configuração de horário
const DAYS_OF_WEEK = [
  { value: 'Domingo', label: 'Domingo' },
  { value: 'Segunda-feira', label: 'Segunda-feira' },
  { value: 'Terça-feira', label: 'Terça-feira' },
  { value: 'Quarta-feira', label: 'Quarta-feira' },
  { value: 'Quinta-feira', label: 'Quinta-feira' },
  { value: 'Sexta-feira', label: 'Sexta-feira' },
  { value: 'Sábado', label: 'Sábado' },
];

export default function Business() {
  const { user } = useAuth();
  const { toast: showToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<BusinessSettings | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [columnsAdded, setColumnsAdded] = useState(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [charCount, setCharCount] = useState(0);
  
  // Formatos de texto
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  
  // Lista de emojis fixos para o setor de beleza
  const beautyEmojis = [
    "💅", // esmalte
    "💇‍♀️", // corte de cabelo
    "💆‍♀️", // massagem facial
    "💄", // batom
    "✨", // brilho
    "👄", // lábios
    "💋", // beijo
    "💖", // coração rosa
    "💝", // coração com fita
    "🌸", // flor
  ];

  // Função para obter iniciais do nome do negócio
  const getInitials = (businessName: string) => {
    if (!businessName) return 'BZ';
    
    const words = businessName.trim().split(' ').filter(word => word.length > 0);
    if (words.length === 0) return 'BZ';
    
    if (words.length === 1) {
      // Se for uma palavra só, pega as duas primeiras letras
      return words[0].slice(0, 2).toUpperCase();
    }
    
    // Pega a primeira letra das duas primeiras palavras
    return words.slice(0, 2).map(word => word[0]).join('').toUpperCase();
  };

  const [workingHours, setWorkingHours] = useState<Record<string, { isOpen: boolean, startTime: string, endTime: string }>>({
    'Domingo': { isOpen: false, startTime: '09:00', endTime: '18:00' },
    'Segunda-feira': { isOpen: true, startTime: '09:00', endTime: '18:00' },
    'Terça-feira': { isOpen: true, startTime: '09:00', endTime: '18:00' },
    'Quarta-feira': { isOpen: true, startTime: '09:00', endTime: '18:00' },
    'Quinta-feira': { isOpen: true, startTime: '09:00', endTime: '18:00' },
    'Sexta-feira': { isOpen: true, startTime: '09:00', endTime: '18:00' },
    'Sábado': { isOpen: false, startTime: '09:00', endTime: '18:00' },
  });

  const loadSettings = useCallback(async () => {
    try {
      if (!user?.id) return;
      const data = await settingsService.getBusiness(user.id);
      setSettings(data);
    } catch (error) {
      console.error('Erro ao carregar configurações:', error);
      showToast({
        title: "Erro",
        description: "Não foi possível carregar as configurações. Tente novamente mais tarde.",
      });
    } finally {
      setLoading(false);
    }
  }, [user, showToast]);

  useEffect(() => {
    // Adicionar as colunas de cores no banco de dados se elas não existirem
    const addColumnsIfNeeded = async () => {
      try {
        const { data, error } = await supabase.from('business_settings').select('header_bg_color, custom_url').limit(1);
        
        // Verificar se a coluna custom_url existe
        const customUrlExists = !(error && error.message.includes('custom_url') && error.message.includes('does not exist'));
        
        if (error && error.message.includes('column') && error.message.includes('does not exist')) {
          console.log('Colunas necessárias não existem no banco de dados');
          
          // Adicionar coluna custom_url se não existir
          if (!customUrlExists) {
            try {
              // Usar RPC para executar SQL diretamente (requer função SQL no Supabase)
              await supabase.rpc('add_custom_url_column');
              console.log('Coluna custom_url adicionada com sucesso');
            } catch (addError) {
              console.error('Erro ao adicionar coluna custom_url:', addError);
            }
          }
          
          showToast({
            title: "Atualização necessária",
            description: "Por favor, execute a migração SQL para adicionar os campos necessários.",
          });
          setColumnsAdded(false);
        } else {
          console.log('Colunas necessárias já existem ou foram adicionadas com sucesso');
          setColumnsAdded(true);
        }
      } catch (error) {
        console.error('Erro ao verificar colunas:', error);
        showToast({
          title: "Erro",
          description: "Não foi possível verificar as colunas necessárias.",
          variant: "destructive"
        });
      }
    };

    addColumnsIfNeeded();
    
    if (user?.id) {
      loadSettings();
    }
  }, [user, loadSettings, showToast]);

  useEffect(() => {
    if (settings?.logo_url) {
      setPreviewUrl(settings.logo_url);
    }
  }, [settings]);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB
        toast('Arquivo muito grande', {
          description: 'A imagem deve ter no máximo 5MB',
          duration: 3000,
        });
        return;
      }

      if (!file.type.startsWith('image/')) {
        toast('Formato inválido', {
          description: 'O arquivo deve ser uma imagem',
          duration: 3000,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
        if (settings) {
          setSettings({
            ...settings,
            logo_url: reader.result as string
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setPreviewUrl(null);
    if (settings) {
      setSettings({
        ...settings,
        logo_url: undefined
      });
    }
  };

  useEffect(() => {
    if (settings?.hours) {
      try {
        // Tentativa de parsear os horários salvos no formato de texto
        const parsedHours = settings.hours.split('\n').reduce((acc, line) => {
          const [day, schedule] = line.split(':');
          if (day && schedule) {
            const trimmedDay = day.trim();
            if (schedule.trim().toLowerCase() === 'fechado') {
              acc[trimmedDay] = { isOpen: false, startTime: '09:00', endTime: '18:00' };
            } else {
              const [startTime, endTime] = schedule.trim().split(' às ');
              if (startTime && endTime) {
                acc[trimmedDay] = { isOpen: true, startTime: startTime.trim(), endTime: endTime.trim() };
              }
            }
          }
          return acc;
        }, { ...workingHours });
        
        setWorkingHours(parsedHours);
      } catch (error) {
        console.error('Erro ao parsear horários:', error);
      }
    }
  }, [settings?.hours, workingHours]);

  // Função para atualizar horários
  const updateWorkingHour = (day: string, field: 'isOpen' | 'startTime' | 'endTime', value: boolean | string) => {
    setWorkingHours(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value
      }
    }));
  };

  // Função para formatar horários para salvar
  const formatWorkingHoursForSave = () => {
    return DAYS_OF_WEEK.map(({ value: day }) => {
      const { isOpen, startTime, endTime } = workingHours[day];
      return isOpen 
        ? `${day}: ${startTime} às ${endTime}` 
        : `${day}: Fechado`;
    }).join('\n');
  };

  // Função para verificar e adicionar a coluna hours na tabela business_settings se ela não existir
  const ensureHoursColumnExists = async () => {
    try {
      // Verificar se a coluna hours existe
      const { data, error } = await supabase
        .from('business_settings')
        .select('hours')
        .limit(1);
      
      if (error && error.message && error.message.includes('hours')) {
        console.log('Coluna hours não existe, tentando adicionar...');
        
        try {
          // Vamos tentar executar uma RPC para adicionar a coluna
          await supabase.rpc('add_hours_column');
          console.log('Coluna hours adicionada com sucesso!');
          return true;
        } catch (addError) {
          console.error('Erro ao adicionar coluna hours:', addError);
          return false;
        }
      } else {
        console.log('Coluna hours já existe');
        return true;
      }
    } catch (checkError) {
      console.error('Erro ao verificar coluna hours:', checkError);
      return false;
    }
  };

  // Função para salvar os dias de funcionamento na tabela working_hours
  const saveWorkingHoursToDb = async (professionalId: string) => {
    try {
      // Primeiro, verificar se a tabela existe
      const { data: tableCheck, error: tableError } = await supabase
        .from('working_hours')
        .select('*')
        .limit(1);
      
      // Se houver erro, verificar se é porque a tabela não existe
      if (tableError && tableError.message && (
          tableError.message.includes('relation "working_hours" does not exist') ||
          tableError.message.includes('não existe')
      )) {
        console.log('Tabela working_hours não existe. Criando a tabela...');
        
        // Tentar criar a tabela (isso só funcionará se você tiver permissões adequadas)
        try {
          await supabase.rpc('create_working_hours_table', { professional_id_param: professionalId });
          console.log('Tabela working_hours criada com sucesso!');
        } catch (createError) {
          console.error('Não foi possível criar a tabela working_hours:', createError);
          
          // Como alternativa, vamos salvar os horários no campo hours da tabela business_settings
          const formattedHours = formatWorkingHoursForSave();
          
          const { data, error } = await supabase
            .from('business_settings')
            .update({ hours: formattedHours })
            .eq('professional_id', professionalId);
          
          if (error) {
            console.error('Erro ao salvar horários no campo hours:', error);
            throw error;
          }
          
          console.log('Horários salvos no campo hours como alternativa.');
          return true;
        }
      }
      
      // Para cada dia da semana, salvar o status no banco de dados
      const promises = DAYS_OF_WEEK.map(async ({ value: day }, index) => {
        const { isOpen, startTime, endTime } = workingHours[day];
        const dayOfWeek = index; // 0 = Domingo, 1 = Segunda, etc.
        
        try {
          // Verificar se já existe registro para este dia
          const { data, error } = await supabase
            .from('working_hours')
            .select('id')
            .eq('professional_id', professionalId)
            .eq('day_of_week', dayOfWeek)
            .maybeSingle();
          
          if (error) {
            console.error(`Erro ao verificar registro para o dia ${day}:`, error);
            return null;
          }
          
          if (data) {
            // Atualizar registro existente
            return supabase
              .from('working_hours')
              .update({
                is_available: isOpen,
                start_time: startTime,
                end_time: endTime,
                updated_at: new Date().toISOString()
              })
              .eq('professional_id', professionalId)
              .eq('day_of_week', dayOfWeek);
          } else {
            // Criar novo registro
            return supabase
              .from('working_hours')
              .insert({
                professional_id: professionalId,
                day_of_week: dayOfWeek,
                is_available: isOpen,
                start_time: startTime,
                end_time: endTime
              });
          }
        } catch (dayError) {
          console.error(`Erro ao processar o dia ${day}:`, dayError);
          return null;
        }
      });
      
      const results = await Promise.all(promises);
      
      // Corrigir problema de tipagem com os erros
      const errors: Array<{ code: string; details: string; hint: string; message: string }> = [];
      results.forEach(result => {
        if (result && result.error) {
          errors.push(result.error);
        }
      });
      
      if (errors.length > 0) {
        console.error('Erros ao salvar alguns dias de funcionamento:', errors);
        
        // Como alternativa, vamos salvar os horários no campo hours da tabela business_settings
        const formattedHours = formatWorkingHoursForSave();
        
        const { data, error } = await supabase
          .from('business_settings')
          .update({ hours: formattedHours })
          .eq('professional_id', professionalId);
        
        if (error) {
          console.error('Erro ao salvar horários no campo hours:', error);
          throw error;
        }
        
        console.log('Horários salvos no campo hours como alternativa.');
      } else {
        console.log('Horários de funcionamento salvos com sucesso na tabela working_hours');
        
        // De qualquer forma, vamos atualizar também o campo hours na tabela business_settings para compatibilidade
        const formattedHours = formatWorkingHoursForSave();
        
        await supabase
          .from('business_settings')
          .update({ hours: formattedHours })
          .eq('professional_id', professionalId);
      }
      
      return true;
    } catch (error) {
      console.error('Erro global ao salvar horários de funcionamento:', error);
      
      // Como último recurso, ainda tentamos salvar os horários no campo hours
      try {
        const formattedHours = formatWorkingHoursForSave();
        
        await supabase
          .from('business_settings')
          .update({ hours: formattedHours })
          .eq('professional_id', professionalId);
        
        console.log('Horários salvos apenas no campo hours como último recurso.');
        return true;
      } catch (finalError) {
        console.error('Falha total ao salvar horários:', finalError);
        return false;
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!settings || !user?.id) return;

    try {
      // Log para debug dos dados antes de enviar
      console.log('Tentando salvar configurações com Instagram:', {
        instagram_url: settings.instagram_url,
        show_instagram: settings.show_instagram
      });

      // Validar se o campo custom_url está preenchido
      if (!settings.custom_url || settings.custom_url.trim() === '') {
        showToast({
          title: "Campo obrigatório",
          description: "É necessário definir uma URL personalizada para seu negócio.",
          variant: "destructive"
        });
        return;
      }

      // Validar se a URL personalizada está em uso por outro usuário
      if (settings.custom_url && settings.custom_url.trim() !== '') {
        try {
          // Verificar se a URL já está em uso por outro usuário
          const { data: existingSettings, error } = await supabase
            .from('business_settings')
            .select('professional_id')
            .eq('custom_url', settings.custom_url)
            .neq('professional_id', user.id) // Excluir o próprio usuário da verificação
            .limit(1);

          if (!error && existingSettings && existingSettings.length > 0) {
            showToast({
              title: "URL não disponível",
              description: "Esta URL personalizada já está em uso. Por favor, escolha outra.",
              variant: "destructive"
            });
            return;
          }
        } catch (error) {
          console.error('Erro ao verificar disponibilidade da URL:', error);
        }
      }

      // Verificar e adicionar a coluna hours se necessário
      await ensureHoursColumnExists();

      // Formatar horários para salvar
      const formattedHours = formatWorkingHoursForSave();
      
      // Criar um objeto settings para salvar com ou sem hours
      const settingsToSave = {
        business_name: settings.business_name,
        description: settings.description,
        address: settings.address,
        logo_url: settings.logo_url,
        hours: formattedHours, // Incluir hours de qualquer forma, se não existir a coluna, será ignorado
        whatsapp: settings.whatsapp,
        instagram_url: settings.instagram_url,
        show_business_name: settings.show_business_name,
        show_description: settings.show_description,
        show_address: settings.show_address,
        show_hours: settings.show_hours,
        show_prices: settings.show_prices,
        show_instagram: settings.show_instagram,
        show_whatsapp: settings.show_whatsapp,
        allow_online_booking: settings.allow_online_booking
      };
      
      if (columnsAdded) {
        Object.assign(settingsToSave, {
          header_bg_color: settings.header_bg_color,
          business_name_color: settings.business_name_color,
          decorator_line_color: settings.decorator_line_color,
          custom_url: settings.custom_url
        });
      }
      
      // Tentar salvar as configurações
      try {
        const updatedSettings = await settingsService.update(user.id, settingsToSave);
        setSettings({...updatedSettings, hours: formattedHours});
        
        // Mostrar toast de sucesso após salvar as configurações
        showToast({
          title: "Sucesso",
          description: "As configurações foram salvas com sucesso."
        });
      } catch (saveError: unknown) {
        // Se o erro for relacionado à coluna hours, tente novamente sem essa coluna
        const errorMessage = saveError instanceof Error ? saveError.message : String(saveError);
        
        if (errorMessage.includes('hours')) {
          console.log('Erro ao salvar com hours. Tentando novamente sem essa coluna...');
          
          // Remover a propriedade hours
          const { hours, ...settingsWithoutHours } = settingsToSave;
          
          const updatedSettings = await settingsService.update(user.id, settingsWithoutHours);
          setSettings({...updatedSettings, hours: formattedHours}); // Manter o hours localmente
          
          showToast({
            title: "Configurações salvas",
            description: "As informações básicas foram salvas, mas houve um problema com os horários."
          });
        } else {
          // Se for outro erro, relançar para ser capturado pelo catch externo
          throw saveError;
        }
      }
      
      // Salvar os horários de funcionamento na tabela working_hours
      const workingHoursSaved = await saveWorkingHoursToDb(user.id);
      
      if (!workingHoursSaved) {
        showToast({
          title: "Atenção",
          description: "As configurações foram salvas, mas houve um problema ao salvar os horários de funcionamento.",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error('Erro ao salvar configurações:', error);
      showToast({
        title: "Erro",
        description: "Não foi possível salvar as configurações: " + (error instanceof Error ? error.message : 'Erro desconhecido'),
        variant: "destructive"
      });
    }
  };

  const handleCopyLink = () => {
    if (!settings) return;
    
    const link = settings.custom_url
      ? `${window.location.origin}/b/${settings.custom_url}`
      : `${window.location.origin}/b/${settings.professional_id}`;
    
    navigator.clipboard.writeText(link);
    showToast({
      description: 'Link copiado para a área de transferência!'
    });
  };

  const handleWhatsAppChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    if (settings) {
      setSettings({ ...settings, whatsapp: formattedNumber });
    }
  };

  // Função para aplicar formatos ao texto
  const applyFormat = (format: 'bold' | 'italic') => {
    const textarea = document.getElementById('description') as HTMLTextAreaElement;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);
    
    let formattedText = '';
    let cursorPosition = 0;
    
    if (selectedText) {
      let wrapper = '';
      
      if (format === 'bold') {
        wrapper = '**';
      } else if (format === 'italic') {
        wrapper = '_';
      }
      
      formattedText = textarea.value.substring(0, start) + 
                     wrapper + selectedText + wrapper + 
                     textarea.value.substring(end);
      
      cursorPosition = end + (wrapper.length * 2);
    } else {
      let wrapper = '';
      
      if (format === 'bold') {
        wrapper = '**';
      } else if (format === 'italic') {
        wrapper = '_';
      }
      
      formattedText = textarea.value.substring(0, start) + 
                     wrapper + wrapper + 
                     textarea.value.substring(end);
      
      cursorPosition = start + wrapper.length;
    }
    
    if (settings) {
      setSettings({ ...settings, description: formattedText });
    }
    
    // Restaurar o foco e posição do cursor após a atualização
    setTimeout(() => {
      textarea.focus();
      textarea.selectionStart = cursorPosition;
      textarea.selectionEnd = cursorPosition;
    }, 0);
  };

  // Função para adicionar emoji à descrição
  const addEmoji = (emoji: string) => {
    if (!settings) return;
    
    const textarea = document.getElementById('description') as HTMLTextAreaElement;
    if (!textarea) return;
    
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    
    const newText = textarea.value.substring(0, start) + 
                    emoji + 
                    textarea.value.substring(end);
    
    // Verificar se não vai exceder o limite
    if (newText.length <= 400) {
      setSettings({ ...settings, description: newText });
      
      // Restaurar o foco e posição do cursor após a atualização
      setTimeout(() => {
        textarea.focus();
        textarea.selectionStart = start + emoji.length;
        textarea.selectionEnd = start + emoji.length;
      }, 0);
    }
  };

  // Atualizar a contagem de caracteres quando a descrição mudar
  useEffect(() => {
    if (settings?.description) {
      setCharCount(settings.description.length);
    } else {
      setCharCount(0);
    }
  }, [settings?.description]);

  // Função para abrir/fechar o seletor de emoji com animação suave
  const toggleEmojiPicker = (open: boolean) => {
    if (open) {
      setIsEmojiPickerOpen(true);
    } else {
      setIsEmojiPickerOpen(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="w-8 h-8 border-4 border-diva-pink/20 border-t-diva-pink rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!settings) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Card className="p-6">
          <p className="text-gray-500">Erro ao carregar configurações</p>
        </Card>
      </div>
    );
  }

  // Definindo as variáveis CSS personalizadas
  document.documentElement.style.setProperty('--header-bg-color', settings.header_bg_color || '#ffffff');
  document.documentElement.style.setProperty('--business-name-color', settings.business_name_color || '#000000');
  document.documentElement.style.setProperty('--decorator-line-color', settings.decorator_line_color || '#e11d8f');

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8 space-y-4 sm:space-y-8 overflow-x-hidden">
      {/* Conteúdo principal */}
      <Card className="bg-white shadow-sm rounded-sm border-0 overflow-hidden max-w-full">
        <div className="px-3 sm:px-6 py-3 sm:py-4 border-b border-diva-pink-light/30">
          <h2 className="text-base sm:text-lg font-medium text-diva-dark">Configurações do Negócio</h2>
        </div>
        
        <form onSubmit={handleSubmit} className="p-2 sm:p-6 space-y-4 sm:space-y-8">
          {/* Seção Logo */}
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-diva-dark text-sm sm:text-base font-medium flex items-center">
              <FileImage className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
              Logo do Negócio
            </h3>
            
            <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
              <div className="flex-shrink-0">
                {previewUrl ? (
                  <div className="relative">
                    <img 
                      src={previewUrl} 
                      alt="Logo preview" 
                      className="w-24 h-24 sm:w-32 sm:h-32 object-cover rounded-lg border shadow-sm"
                    />
                    <button
                      type="button"
                      onClick={removeLogo}
                      className="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
                      title="Remover logo"
                      aria-label="Remover logo"
                    >
                      <X className="w-4 h-4 text-gray-500" />
                    </button>
                  </div>
                ) : (
                  <div className="w-24 h-24 sm:w-32 sm:h-32 bg-diva-light rounded-lg border-2 border-dashed border-diva-pink-light/40 flex items-center justify-center">
                    <UploadCloud className="w-6 h-6 sm:w-8 sm:h-8 text-diva-pink/50" />
                  </div>
                )}
              </div>
              <div className="flex-grow text-center sm:text-left space-y-2">
                <div className="flex flex-col sm:flex-row items-center gap-2">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => document.getElementById('logo-upload')?.click()}
                    className="border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20 w-full sm:w-auto"
                  >
                    Escolher imagem
                  </Button>
                  <input
                    id="logo-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleLogoUpload}
                    className="hidden"
                    title="Upload de logo"
                    aria-label="Upload de logo"
                  />
                </div>
                <p className="text-xs text-diva-purple">
                  Recomendado: Imagem quadrada de até 5MB. <br className="hidden sm:inline" />
                  Formatos aceitos: JPG, PNG ou GIF
                </p>
              </div>
            </div>
          </div>

          {/* Informações Básicas */}
          <div className="space-y-3 sm:space-y-4 border-t border-diva-pink-light/20 pt-6 sm:pt-8">
            <h3 className="text-diva-dark text-sm sm:text-base font-medium flex items-center">
              <Store className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
              Informações Básicas
            </h3>
            
            <div className="grid grid-cols-1 gap-3 sm:gap-6">
              <div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="business_name" className="text-xs sm:text-sm text-diva-dark">Nome do Negócio</Label>
                  <Switch
                    checked={settings.show_business_name}
                    onCheckedChange={(checked) => setSettings({ ...settings, show_business_name: checked })}
                    className="scale-90 sm:scale-100"
                  />
                </div>
                <Input
                  id="business_name"
                  value={settings.business_name}
                  onChange={(e) => setSettings({ ...settings, business_name: e.target.value })}
                  className="mt-1 border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20 text-sm h-8 sm:h-10"
                />
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="address" className="text-xs sm:text-sm text-diva-dark">Endereço</Label>
                  <Switch
                    checked={settings.show_address}
                    onCheckedChange={(checked) => setSettings({ ...settings, show_address: checked })}
                    className="scale-90 sm:scale-100"
                  />
                </div>
                <Input
                  id="address"
                  value={settings.address || ''}
                  onChange={(e) => setSettings({ ...settings, address: e.target.value })}
                  className="mt-1 border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20 text-sm h-8 sm:h-10"
                />
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="whatsapp" className="text-xs sm:text-sm text-diva-dark">WhatsApp para Contato</Label>
                  <Switch
                    checked={settings.show_whatsapp || false}
                    onCheckedChange={(checked) => setSettings({ ...settings, show_whatsapp: checked })}
                    className="scale-90 sm:scale-100"
                  />
                </div>
                <Input
                  id="whatsapp"
                  value={settings.whatsapp || ''}
                  onChange={handleWhatsAppChange}
                  placeholder="Ex: (21) 9 9999-9999"
                  className="mt-1 border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20 text-sm h-8 sm:h-10"
                />
                <p className="text-xs text-diva-purple mt-1">
                  Informe seu número no formato brasileiro com DDD.
                </p>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="instagram_url" className="text-xs sm:text-sm text-diva-dark">Instagram</Label>
                  <Switch
                    checked={settings.show_instagram || false}
                    onCheckedChange={(checked) => setSettings({ ...settings, show_instagram: checked })}
                    className="scale-90 sm:scale-100"
                  />
                </div>
                <div className="flex rounded-md shadow-sm mt-1">
                  <span className="inline-flex items-center px-2 sm:px-3 rounded-l-md border border-r-0 border-diva-pink-light bg-diva-light text-diva-purple text-xs truncate max-w-[90px] sm:max-w-none">
                    instagram.com/
                  </span>
                  <Input
                    id="instagram_url"
                    value={settings.instagram_url || ''}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[@\s]/g, '');
                      setSettings({ ...settings, instagram_url: value });
                    }}
                    placeholder="seu.perfil"
                    className="rounded-l-none border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20 text-sm h-8 sm:h-10"
                  />
                </div>
                <p className="text-xs text-diva-purple mt-1">
                  Digite seu nome de usuário do Instagram sem o "@".
                </p>
              </div>
            </div>
          </div>

          {/* Personalização */}
          <div className="space-y-3 sm:space-y-4 border-t border-diva-pink-light/20 pt-6 sm:pt-8">
            <h3 className="text-diva-dark text-sm sm:text-base font-medium flex items-center">
              <FileImage className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
              Personalização
            </h3>
            
            <div className="grid grid-cols-1 gap-3 sm:gap-6">
              <div>
                <Label htmlFor="header_bg_color" className="block mb-1 sm:mb-2 text-xs sm:text-sm text-diva-dark">Cor de Fundo do Cabeçalho</Label>
                <div className="flex items-center gap-2 sm:gap-4">
                  <input
                    type="color"
                    id="header_bg_color"
                    title="Escolha a cor de fundo do cabeçalho"
                    value={settings.header_bg_color || '#ffffff'}
                    onChange={(e) => setSettings({ ...settings, header_bg_color: e.target.value })}
                    className="w-8 h-8 sm:w-10 sm:h-10 rounded border p-1 cursor-pointer"
                  />
                  <div className="text-xs sm:text-sm text-diva-purple flex-1">
                    Cor de fundo da área superior
                  </div>
                </div>
              </div>

              <div>
                <Label htmlFor="business_name_color" className="block mb-1 sm:mb-2 text-xs sm:text-sm text-diva-dark">Cor do Nome da Empresa</Label>
                <div className="flex items-center gap-2 sm:gap-4">
                  <input
                    type="color"
                    id="business_name_color"
                    title="Escolha a cor do texto do nome da empresa"
                    value={settings.business_name_color || '#000000'}
                    onChange={(e) => setSettings({ ...settings, business_name_color: e.target.value })}
                    className="w-8 h-8 sm:w-10 sm:h-10 rounded border p-1 cursor-pointer"
                  />
                  <div className="text-xs sm:text-sm text-diva-purple flex-1">
                    Cor do texto do nome
                  </div>
                </div>
              </div>

              <div>
                <Label htmlFor="decorator_line_color" className="block mb-1 sm:mb-2 text-xs sm:text-sm text-diva-dark">Cor das Linhas Decorativas</Label>
                <div className="flex items-center gap-2 sm:gap-4">
                  <input
                    type="color"
                    id="decorator_line_color"
                    title="Escolha a cor das linhas decorativas"
                    value={settings.decorator_line_color || '#e11d8f'}
                    onChange={(e) => setSettings({ ...settings, decorator_line_color: e.target.value })}
                    className="w-8 h-8 sm:w-10 sm:h-10 rounded border p-1 cursor-pointer"
                  />
                  <div className="text-xs sm:text-sm text-diva-purple flex-1">
                    Cor das linhas acima e abaixo
                  </div>
                </div>
              </div>
            </div>

            {/* Prévia do cabeçalho */}
            <div className="bg-diva-light p-2 sm:p-4 rounded-lg mt-2">
              <Label className="block mb-2 text-xs sm:text-sm text-diva-dark">Prévia do cabeçalho</Label>
              <div className="border rounded-lg p-3 sm:p-4 flex flex-col items-center relative headerBackground">
                {/* Linha decorativa no topo */}
                <div className="absolute top-0 left-0 right-0 h-1 rounded-t-lg decoratorLine"></div>

                {previewUrl ? (
                  <div className="w-10 h-10 sm:w-16 sm:h-16 rounded-lg overflow-hidden mb-2">
                    <img 
                      src={previewUrl} 
                      alt="Logo preview" 
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="w-10 h-10 sm:w-16 sm:h-16 bg-gradient-to-br from-diva-pink to-purple-600 rounded-lg flex items-center justify-center mb-2">
                    <span className="text-sm sm:text-xl font-bold text-white">
                      {getInitials(settings.business_name)}
                    </span>
                  </div>
                )}
                {settings.show_business_name && (
                  <>
                    <span className="text-sm sm:text-lg font-bold businessName">
                      {settings.business_name || 'Nome do Negócio'}
                    </span>
                    {/* Linha decorativa abaixo do nome */}
                    <div className="w-10 sm:w-16 h-1 rounded-full mt-2 decoratorLine"></div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Descrição */}
          <div className="space-y-3 sm:space-y-4 border-t border-diva-pink-light/20 pt-6 sm:pt-8">
            <div className="flex items-center justify-between">
              <h3 className="text-diva-dark text-sm sm:text-base font-medium flex items-center">
                <Store className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
                Descrição
              </h3>
              <Switch
                checked={settings.show_description}
                onCheckedChange={(checked) => setSettings({ ...settings, show_description: checked })}
              />
            </div>
            
            <div className="mt-1 border rounded-md overflow-hidden border-diva-pink-light">
              <div className="flex flex-wrap items-center justify-between px-2 sm:px-3 py-1.5 sm:py-2 bg-diva-light border-b border-diva-pink-light/30">
                <div className="flex flex-wrap items-center gap-0.5 sm:gap-1">
                  <Button 
                    type="button"
                    variant="ghost" 
                    size="sm" 
                    className={`px-1.5 sm:px-2 h-7 sm:h-8 ${isBold ? 'bg-diva-pink-light/30 text-diva-pink' : ''}`}
                    onClick={() => {
                      setIsBold(!isBold);
                      applyFormat('bold');
                    }}
                  >
                    <BoldIcon className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
                  </Button>
                  <Button 
                    type="button"
                    variant="ghost" 
                    size="sm" 
                    className={`px-1.5 sm:px-2 h-7 sm:h-8 ${isItalic ? 'bg-diva-pink-light/30 text-diva-pink' : ''}`}
                    onClick={() => {
                      setIsItalic(!isItalic);
                      applyFormat('italic');
                    }}
                  >
                    <ItalicIcon className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
                  </Button>
                  
                  <div className="h-4 sm:h-5 border-r border-diva-pink-light/30 mx-0.5 sm:mx-1"></div>
                  
                  <div className="flex flex-wrap">
                    {beautyEmojis.map((emoji, index) => (
                      <Button
                        key={index}
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="px-1 sm:px-2 h-7 sm:h-8 hover:bg-diva-pink-light/20 text-sm sm:text-base"
                        onClick={() => addEmoji(emoji)}
                        title={`Adicionar ${emoji}`}
                      >
                        {emoji}
                      </Button>
                    ))}
                  </div>
                </div>
                <div className={`text-xs ${charCount > 400 ? 'text-red-500' : 'text-diva-purple'}`}>
                  {charCount}/400
                </div>
              </div>
              
              <Textarea
                id="description"
                value={settings.description || ''}
                onChange={(e) => {
                  // Limitar a 400 caracteres
                  if (e.target.value.length <= 400) {
                    setSettings({ ...settings, description: e.target.value });
                  }
                }}
                className="border-0 rounded-none focus-visible:ring-0 min-h-[120px] sm:min-h-[150px] text-sm"
                placeholder="Descreva seu negócio aqui... Use ** para negrito e _ para itálico"
              />
            </div>
          </div>

          {/* Link personalizado */}
          <div className="space-y-3 sm:space-y-4 border-t border-diva-pink-light/20 pt-6 sm:pt-8">
            <h3 className="text-diva-dark text-sm sm:text-base font-medium flex items-center">
              <MapPin className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
              Link Personalizado
            </h3>
            
            <div className="space-y-3 sm:space-y-4">
              <div>
                <Label htmlFor="custom_url" className="text-diva-dark text-xs sm:text-sm mb-1 block">URL Personalizada</Label>
                <div className="flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-2 sm:px-3 rounded-l-md border border-r-0 border-diva-pink-light bg-diva-light text-diva-purple text-xs sm:text-sm truncate max-w-[110px] sm:max-w-none">
                    {window.location.origin}/b/
                  </span>
                  <Input
                    id="custom_url"
                    value={settings.custom_url || ''}
                    onChange={(e) => {
                      // Remover espaços e caracteres especiais
                      const value = e.target.value.replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
                      setSettings({ ...settings, custom_url: value });
                    }}
                    placeholder="sua-url-personalizada"
                    className={`rounded-l-none border-diva-pink-light focus:border-diva-pink text-sm h-9 sm:h-10 ${!settings.custom_url || settings.custom_url.trim() === '' ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''}`}
                    required
                    aria-required="true"
                    aria-invalid={!settings.custom_url || settings.custom_url.trim() === ''}
                    aria-describedby="custom-url-error"
                  />
                </div>
                {(!settings.custom_url || settings.custom_url.trim() === '') && (
                  <p className="text-xs text-red-500 mt-1" id="custom-url-error">
                    Este campo é obrigatório e não pode ser deixado em branco.
                  </p>
                )}
                <p className="text-xs text-diva-purple mt-1">
                  Use apenas letras, números, hífen e sublinhado.
                </p>
              </div>
              
              <div className="flex flex-col sm:flex-row gap-2">
                <Input
                  value={settings.custom_url 
                    ? `${window.location.origin}/b/${settings.custom_url}`
                    : `${window.location.origin}/b/${settings.professional_id}`}
                  readOnly
                  className="bg-diva-light border-diva-pink-light/30 text-xs sm:text-sm py-1 h-9 sm:h-10"
                />
                <Button
                  variant="outline"
                  onClick={handleCopyLink}
                  title="Copiar link"
                  className="border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20 h-9 sm:h-10 sm:w-auto"
                >
                  <Copy className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5 sm:mr-2" />
                  <span>Copiar</span>
                </Button>
              </div>
            </div>
          </div>

          {/* Configurações de Agendamento - seção única com melhor UX */}
          <div className="space-y-3 sm:space-y-4 border-t border-diva-pink-light/20 pt-6 sm:pt-8">
            <h3 className="text-diva-dark text-sm sm:text-base font-medium flex items-center">
              <Settings className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2 text-diva-pink" />
              Configurações de Agendamento
            </h3>
            
            <p className="text-xs sm:text-sm text-diva-purple">
              Configure os dias e horários em que você atende para que seus clientes possam agendar serviços através do seu link público.
            </p>
            
            <div className="space-y-4">
              {/* Dias e Horários de Atendimento */}
              <div className="flex items-center justify-between p-3 bg-diva-light rounded-lg border border-diva-pink-light/20">
                <div>
                  <h4 className="text-xs sm:text-sm font-medium text-diva-dark">Dias e Horários de Atendimento</h4>
                  <p className="text-xs text-diva-purple mt-1">Defina quais dias e horários você está disponível para agendamentos</p>
                </div>
                <Link to="/configuracoes-agenda">
                  <Button variant="outline" size="sm" className="border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20">
                    <CalendarRange className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5 sm:mr-2" />
                    Configurar Agenda
                  </Button>
                </Link>
              </div>
              
              {/* Switches de configuração */}
              <div className="flex flex-col gap-3 sm:grid sm:grid-cols-2 sm:gap-6 bg-diva-light p-3 sm:p-4 rounded-lg">
                <div className="flex items-start gap-2 sm:gap-3">
                  <Switch
                    checked={settings.allow_online_booking}
                    onCheckedChange={(checked) => setSettings({ ...settings, allow_online_booking: checked })}
                    className="mt-0.5 scale-90 sm:scale-100"
                  />
                  <div>
                    <Label className="text-xs sm:text-sm text-diva-dark">Permitir Agendamento Online</Label>
                    <p className="text-xs text-diva-purple">Habilite para permitir que clientes agendem serviços online.</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-2 sm:gap-3">
                  <Switch
                    checked={settings.show_prices}
                    onCheckedChange={(checked) => setSettings({ ...settings, show_prices: checked })}
                    className="mt-0.5 scale-90 sm:scale-100"
                  />
                  <div>
                    <Label className="text-xs sm:text-sm text-diva-dark">Mostrar Preços</Label>
                    <p className="text-xs text-diva-purple">Habilite para mostrar os preços dos serviços publicamente.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Botão de salvar */}
          <div className="pt-4 sticky bottom-0 z-10 -mx-2 sm:-mx-6 px-2 sm:px-6 pb-3 sm:pb-6 bg-white bg-opacity-80 backdrop-blur-sm border-t border-diva-pink-light/20">
            <Button 
              type="submit" 
              className="w-full py-2 sm:py-6 bg-gradient-to-r from-diva-pink to-diva-purple hover:opacity-90 text-white text-sm sm:text-base font-medium"
            >
              Salvar Alterações
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
} 