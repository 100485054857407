import { useState, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/hooks/use-toast';
import { settingsService } from '@/services/settingsService';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader2, KeyRound, User2 } from 'lucide-react';

export default function Profile() {
  const { user } = useAuth();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
  });
  
  // Informações adicionais para demonstração
  const [securityInfo] = useState({
    lastLogin: new Date().toISOString(),
    passwordLastChanged: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString() // 30 dias atrás
  });

  useEffect(() => {
    if (user) {
      loadProfile();
    }
  }, [user]);

  const loadProfile = async () => {
    if (!user) return;

    setLoading(true);
    try {
      const profile = await settingsService.getProfile(user.id);
      if (profile) {
        setProfileData({
          name: profile.name || '',
          email: profile.email || '',
        });
      }
    } catch (error) {
      console.error('Erro ao carregar perfil:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível carregar as informações do perfil.',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!user) return;

    setSaving(true);
    try {
      await settingsService.updateProfile(user.id, profileData);
      toast({
        title: 'Sucesso',
        description: 'Perfil atualizado com sucesso!',
      });
    } catch (error) {
      console.error('Erro ao salvar perfil:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível salvar as alterações do perfil.',
        variant: 'destructive',
      });
    } finally {
      setSaving(false);
    }
  };

  const handleResetPassword = () => {
    // TODO: Implementar reset de senha
    toast({
      title: 'Em breve',
      description: 'Funcionalidade de alteração de senha será implementada em breve.',
    });
  };

  // Formatar data
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="w-8 h-8 border-4 border-diva-pink/20 border-t-diva-pink rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8 px-4 space-y-8">
      {/* Cabeçalho */}
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-diva-dark">Seu Perfil</h1>
        </div>
      </div>

      {/* Cards de estatísticas */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        <Card className="bg-diva-light shadow-sm border-0 col-span-1">
          <CardContent className="p-6 flex flex-col items-center">
            <div className="h-24 w-24 bg-diva-pink-light/30 rounded-full flex items-center justify-center text-diva-pink mb-4">
              <User2 className="h-12 w-12" />
            </div>
            <h3 className="text-lg font-semibold text-diva-dark">{profileData.name || 'Seu nome'}</h3>
            <p className="text-sm text-diva-purple">{profileData.email || 'seu.email@exemplo.com'}</p>
          </CardContent>
        </Card>
        
        <Card className="bg-diva-light shadow-sm border-0 sm:col-span-2">
          <CardContent className="p-6">
            <h3 className="text-diva-purple font-medium text-sm mb-4">Informações de Segurança</h3>
            <div className="space-y-4">
              <div className="flex justify-between items-center border-b border-diva-pink-light/20 pb-2">
                <span className="text-diva-dark">Último acesso</span>
                <span className="text-diva-purple">{formatDate(securityInfo.lastLogin)}</span>
              </div>
              <div className="flex justify-between items-center border-b border-diva-pink-light/20 pb-2">
                <span className="text-diva-dark">Última alteração de senha</span>
                <span className="text-diva-purple">{formatDate(securityInfo.passwordLastChanged)}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Formulário do perfil */}
      <Card className="bg-white shadow-sm rounded-sm border-0 overflow-hidden">
        <div className="px-6 py-4 border-b border-diva-pink-light/30">
          <h2 className="text-lg font-medium text-diva-dark">Editar Informações</h2>
        </div>
        
        <div className="p-6 space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name" className="text-diva-dark">Nome</Label>
              <Input
                id="name"
                value={profileData.name}
                onChange={(e) => setProfileData(prev => ({ ...prev, name: e.target.value }))}
                className="border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="email" className="text-diva-dark">E-mail</Label>
              <Input
                id="email"
                type="email"
                value={profileData.email}
                onChange={(e) => setProfileData(prev => ({ ...prev, email: e.target.value }))}
                className="border-diva-pink-light focus:border-diva-pink focus:ring-diva-purple/20"
              />
            </div>

            <div className="pt-4 space-y-4">
              <Button
                className="w-full bg-gradient-to-r from-diva-pink to-diva-purple hover:opacity-90 text-white"
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Salvando...
                  </>
                ) : (
                  'Salvar Alterações'
                )}
              </Button>

              <Button
                variant="outline"
                className="w-full border-diva-pink-light text-diva-purple hover:bg-diva-pink-light/20"
                onClick={handleResetPassword}
              >
                <KeyRound className="mr-2 h-4 w-4" />
                Alterar Senha
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
} 