import { supabase } from './supabase';

/**
 * Verifica se uma tabela existe no banco de dados
 */
async function checkTableExists(tableName: string): Promise<boolean> {
  try {
    const { error } = await supabase
      .from(tableName)
      .select('count(*)', { count: 'exact', head: true })
      .limit(1);
    
    if (!error) {
      return true;
    }
    
    // Se o erro for de tabela não existente, retorna false
    if (error.code === '42P01' || error.message?.includes('does not exist')) {
      return false;
    }
    
    // Para outros erros, logar e retornar falso
    console.error(`Erro ao verificar tabela ${tableName}:`, error);
    return false;
  } catch (error) {
    console.error(`Erro ao verificar tabela ${tableName}:`, error);
    return false;
  }
}

/**
 * Verifica conexão com o banco de dados
 */
export async function checkDatabaseConnection(): Promise<boolean> {
  try {
    // Tenta acessar auth para verificar se a conexão está funcionando
    const { error } = await supabase.auth.getSession();
    
    if (error) {
      console.error('Erro ao verificar sessão:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Exceção ao verificar conexão:', error);
    return false;
  }
}

/**
 * Função principal para verificar a configuração do banco de dados
 */
export async function setupDatabase(): Promise<boolean> {
  try {
    // Verificar conexão
    const isConnected = await checkDatabaseConnection();
    
    if (!isConnected) {
      console.error('Não foi possível conectar ao banco de dados');
      return false;
    }
    
    console.log('Conexão com o banco de dados estabelecida');
    
    // Verificar se as tabelas existem
    const profilesExist = await checkTableExists('profiles');
    
    if (!profilesExist) {
      console.warn('A tabela profiles não existe. O signup criará automaticamente.');
    } else {
      console.log('Tabela profiles existe e está acessível');
    }
    
    return true;
  } catch (error) {
    console.error('Erro ao verificar banco de dados:', error);
    return false;
  }
}

/**
 * Esta função deve ser chamada na inicialização do aplicativo
 * Implementação simplificada para evitar erros no console
 */
export async function initializeDatabase(): Promise<void> {
  console.log('Verificando conexão com o banco de dados...');
  
  try {
    const isConnected = await checkDatabaseConnection();
    
    if (isConnected) {
      console.log('✓ Conexão com o banco de dados verificada com sucesso');
    } else {
      console.error('✗ Problemas detectados na conexão com o banco de dados');
    }
  } catch (error) {
    console.error('Erro ao verificar conexão com o banco de dados:', error);
  } finally {
    console.log('Verificação de conexão concluída');
  }
} 