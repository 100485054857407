import { Service } from '@/types/appointment';
import { supabase, publicSupabase } from '@/lib/supabase';

type ServiceInput = Omit<Service, 'id' | 'created_at' | 'updated_at'>;
type ServiceUpdate = Partial<Omit<Service, 'id' | 'created_at' | 'updated_at' | 'professional_id'>>;

export const serviceService = {
  async listByProfessional(professionalId: string): Promise<Service[]> {
    try {
      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('professional_id', professionalId)
        .order('name', { ascending: true });

      if (error) {
        // Tratamento silencioso
        return [];
      }

      return data || [];
    } catch (error) {
      // Tratamento silencioso
      return [];
    }
  },

  async create(data: Partial<ServiceInput>): Promise<Service> {
    console.log('Criando serviço com dados:', data);

    const { data: newService, error } = await supabase
      .from('services')
      .insert([{
        ...data,
        active: true, // Garante que o serviço seja criado como ativo
      }])
      .select()
      .single();

    if (error) {
      console.error('Erro ao criar serviço:', error);
      if (error.code === '23505') {
        throw new Error('Já existe um serviço com este nome.');
      }
      throw error;
    }

    console.log('Serviço criado com sucesso:', newService);
    return newService;
  },

  async update(id: string, data: ServiceUpdate): Promise<Service> {
    console.log('Atualizando serviço:', id, 'com dados:', data);

    // Certifique-se de que duration_minutes é um número
    if (data.duration_minutes !== undefined) {
      data.duration_minutes = Number(data.duration_minutes);
    }

    // Certifique-se de que price é um número
    if (data.price !== undefined) {
      data.price = Number(data.price);
    }

    const { data: updatedService, error } = await supabase
      .from('services')
      .update(data)
      .eq('id', id)
      .select()
      .single();

    if (error) {
      console.error('Erro ao atualizar serviço:', error);
      if (error.code === '23505') {
        throw new Error('Já existe um serviço com este nome.');
      }
      throw error;
    }

    console.log('Serviço atualizado com sucesso:', updatedService);
    return updatedService;
  },

  async delete(id: string): Promise<void> {
    console.log('Deletando serviço:', id);

    try {
      // Primeiro, verificar se existem agendamentos que usam este serviço
      const { data: appointments, error: checkError } = await supabase
        .from('appointments')
        .select('id')
        .eq('service_id', id)
        .limit(1);

      if (checkError) {
        console.error('Erro ao verificar agendamentos relacionados:', checkError);
      }

      // Se existirem agendamentos que usam este serviço
      if (appointments && appointments.length > 0) {
        // Opção 1: Inativar o serviço em vez de excluí-lo
        const { error: updateError } = await supabase
          .from('services')
          .update({ active: false })
          .eq('id', id);

        if (updateError) {
          console.error('Erro ao inativar serviço:', updateError);
          throw updateError;
        }
        
        console.log('Serviço inativado com sucesso (não deletado devido a agendamentos existentes)');
        return;
      }
      
      // Se não houver agendamentos, tenta excluir o serviço
      const { error } = await supabase
        .from('services')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Erro ao deletar serviço:', error);
        
        // Se for erro de violação de chave estrangeira, inativa em vez de excluir
        if (error.code === '23503') {
          console.log('Detectada violação de chave estrangeira. Tentando inativar o serviço...');
          const { error: updateError } = await supabase
            .from('services')
            .update({ active: false })
            .eq('id', id);
            
          if (updateError) {
            console.error('Erro ao inativar serviço:', updateError);
            throw updateError;
          }
          
          console.log('Serviço inativado com sucesso');
          return;
        }
        
        throw error;
      }

      console.log('Serviço deletado com sucesso');
    } catch (error) {
      console.error('Erro na operação de deleção:', error);
      throw error;
    }
  },

  async getById(id: string): Promise<Service | null> {
    try {
      const { data, error } = await publicSupabase
        .from('services')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        // Tratamento silencioso
        return null;
      }
      
      return data;
    } catch (error) {
      // Tratamento silencioso
      return null;
    }
  }
}; 