import React, { useEffect, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { workingHoursService } from '@/services/workingHoursService';
import { WorkingHours } from '@/types/appointment';
import { toast } from 'sonner';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Loader2, Save, Clock, CalendarCheck, CalendarX, Check, ChevronDown, ChevronUp } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

const diasDaSemana = [
  { value: 0, label: 'Domingo' },
  { value: 1, label: 'Segunda' },
  { value: 2, label: 'Terça' },
  { value: 3, label: 'Quarta' },
  { value: 4, label: 'Quinta' },
  { value: 5, label: 'Sexta' },
  { value: 6, label: 'Sábado' }
];

// Componente para configuração de um dia específico
interface DayConfigCardProps {
  dayNumber: number;
  config?: WorkingHours;
  onSave: (config: Omit<WorkingHours, 'id' | 'created_at' | 'updated_at'>) => Promise<void>;
}

function DayConfigCard({ dayNumber, config, onSave }: DayConfigCardProps) {
  const { user } = useAuth();
  const [isAvailable, setIsAvailable] = useState(config?.is_available ?? false);
  const [startTime, setStartTime] = useState(config?.start_time ?? '09:00');
  const [endTime, setEndTime] = useState(config?.end_time ?? '18:00');
  const [isSaving, setIsSaving] = useState(false);
  const dayName = diasDaSemana.find(d => d.value === dayNumber)?.label || '';

  const handleSave = async () => {
    if (!user) return;

    setIsSaving(true);
    try {
      await onSave({
        professional_id: user.id,
        day_of_week: dayNumber,
        is_available: isAvailable,
        start_time: startTime,
        end_time: endTime
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card className="border border-diva-pink/20 overflow-hidden">
      <CardHeader className="pb-2 px-3 py-2 bg-gradient-to-r from-diva-pink/5 to-diva-purple/5 border-b border-diva-pink-light/10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {isAvailable ? (
              <CalendarCheck className="h-4 w-4 text-diva-pink" />
            ) : (
              <CalendarX className="h-4 w-4 text-gray-400" />
            )}
            <span className={`text-base ${isAvailable ? 'text-diva-dark' : 'text-gray-500'}`}>
              {dayName}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-xs mr-1 text-gray-500">
              {isAvailable ? 'Disponível' : 'Indisponível'}
            </span>
            <Switch 
              checked={isAvailable} 
              onCheckedChange={setIsAvailable} 
              id={`available-${dayNumber}`}
              className="data-[state=checked]:bg-diva-pink"
            />
          </div>
        </div>
      </CardHeader>
      
      <CardContent className={`p-3 ${!isAvailable ? 'opacity-60' : ''}`}>
        <div className="space-y-3">
          <div className="grid grid-cols-2 gap-2">
            <div className="space-y-1">
              <Label htmlFor={`start-time-${dayNumber}`} className="flex items-center text-xs text-diva-purple">
                <Clock className="h-3 w-3 mr-1 text-diva-pink" />
                Início
              </Label>
              <Input
                type="time"
                id={`start-time-${dayNumber}`}
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                disabled={!isAvailable}
                className="h-8 text-sm border-diva-pink/20"
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor={`end-time-${dayNumber}`} className="flex items-center text-xs text-diva-purple">
                <Clock className="h-3 w-3 mr-1 text-diva-pink" />
                Fim
              </Label>
              <Input
                type="time"
                id={`end-time-${dayNumber}`}
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                disabled={!isAvailable}
                className="h-8 text-sm border-diva-pink/20"
              />
            </div>
          </div>
          
          <Button 
            onClick={handleSave} 
            className={`w-full h-8 text-sm ${isAvailable 
              ? 'bg-diva-pink hover:bg-diva-pink/90 text-white' 
              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                Salvando...
              </>
            ) : (
              <>
                <Save className="h-3 w-3 mr-1" />
                Salvar
              </>
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export function ScheduleSettings() {
  const { user } = useAuth();
  const [workingDays, setWorkingDays] = useState<WorkingHours[]>([]);
  const [loading, setLoading] = useState(true);
  const [slotDuration, setSlotDuration] = useState('30');
  const [savingDuration, setSavingDuration] = useState(false);
  const [expandedDuration, setExpandedDuration] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');

  useEffect(() => {
    if (user) {
      loadWorkingHours();
      loadDuration();
    }
  }, [user]);

  const loadWorkingHours = async () => {
    if (!user) return;

    setLoading(true);
    try {
      const hours = await workingHoursService.getAllByProfessional(user.id);
      setWorkingDays(hours);

      // Inicialização padrão se não houver configuração
      if (hours.length === 0) {
        await workingHoursService.initializeDefault(user.id);
        const updatedHours = await workingHoursService.getAllByProfessional(user.id);
        setWorkingDays(updatedHours);
      }
    } catch (error) {
      console.error('Erro ao carregar horários de funcionamento:', error);
      toast.error('Não foi possível carregar suas configurações de horário.');
    } finally {
      setLoading(false);
    }
  };

  const loadDuration = async () => {
    if (!user) return;

    try {
      const duration = await workingHoursService.getDuration(user.id);
      setSlotDuration(duration);
    } catch (error) {
      console.error('Erro ao carregar duração padrão:', error);
      // Não exibimos um toast para não alarmar o usuário com erro não-crítico
    }
  };

  const handleSaveDay = async (config: Omit<WorkingHours, 'id' | 'created_at' | 'updated_at'>) => {
    try {
      await workingHoursService.upsert(config);
      toast.success(`Configuração para ${diasDaSemana.find(d => d.value === config.day_of_week)?.label} salva com sucesso!`);
      
      // Atualizar a lista de horários após salvar
      loadWorkingHours();
    } catch (error) {
      console.error('Erro ao salvar configuração de horário:', error);
      toast.error('Não foi possível salvar a configuração de horário.');
    }
  };

  const handleSaveSlotDuration = async () => {
    if (!user) return;
    
    setSavingDuration(true);
    try {
      await workingHoursService.saveDuration(user.id, slotDuration);
      toast.success('Duração padrão salva com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar duração padrão:', error);
      toast.error('Não foi possível salvar a duração padrão.');
    } finally {
      setSavingDuration(false);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-6">
        <Loader2 className="h-8 w-8 animate-spin text-diva-pink mb-3" />
        <p className="text-gray-500 text-sm">Carregando configurações de horário...</p>
      </div>
    );
  }

  // Agrupar os dias em úteis e fim de semana
  const diasUteis = diasDaSemana.filter(d => d.value >= 1 && d.value <= 5);
  const fimDeSemana = diasDaSemana.filter(d => d.value === 0 || d.value === 6);

  return (
    <div className="space-y-4">
      <Card className="bg-white shadow-sm border border-diva-pink/10">
        <CardHeader className="pb-0 px-3 py-3 border-b border-diva-pink/10 cursor-pointer" onClick={() => setExpandedDuration(!expandedDuration)}>
          <div className="flex items-center justify-between">
            <CardTitle className="text-base font-medium text-diva-dark flex items-center">
              <Clock className="h-4 w-4 mr-2 text-diva-pink" />
              Duração dos agendamentos
            </CardTitle>
            <Button variant="ghost" size="sm" className="p-0 h-6 w-6">
              {expandedDuration ? (
                <ChevronUp className="h-4 w-4 text-diva-purple" />
              ) : (
                <ChevronDown className="h-4 w-4 text-diva-purple" />
              )}
            </Button>
          </div>
        </CardHeader>
        
        {expandedDuration && (
          <CardContent className="p-3">
            <div className="flex items-end gap-2">
              <div className="space-y-1">
                <Label htmlFor="slot-duration" className="text-xs text-diva-purple">
                  Tempo para cada serviço:
                </Label>
                <Select
                  value={slotDuration}
                  onValueChange={setSlotDuration}
                >
                  <SelectTrigger className="w-36 h-8 text-sm border-diva-pink/20" id="slot-duration">
                    <SelectValue placeholder="Duração" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="15">15 minutos</SelectItem>
                    <SelectItem value="30">30 minutos</SelectItem>
                    <SelectItem value="45">45 minutos</SelectItem>
                    <SelectItem value="60">1 hora</SelectItem>
                    <SelectItem value="90">1h30 minutos</SelectItem>
                    <SelectItem value="120">2 horas</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <Button 
                onClick={handleSaveSlotDuration}
                className="bg-diva-pink hover:bg-diva-pink/90 text-white h-8 text-sm"
                disabled={savingDuration}
              >
                {savingDuration ? (
                  <>
                    <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                    Salvando...
                  </>
                ) : (
                  <>
                    <Check className="h-3 w-3 mr-1" />
                    Salvar
                  </>
                )}
              </Button>
            </div>
          </CardContent>
        )}
      </Card>

      <div className="mt-4">
        <Tabs defaultValue="1" value={currentTab} onValueChange={setCurrentTab} className="w-full">
          <TabsList className="grid grid-cols-3 mb-3">
            <TabsTrigger value="1" className="text-xs">Dias úteis</TabsTrigger>
            <TabsTrigger value="2" className="text-xs">Fim de semana</TabsTrigger>
            <TabsTrigger value="3" className="text-xs">Todos os dias</TabsTrigger>
          </TabsList>
          
          <TabsContent value="1" className="space-y-2 mt-0">
            {diasUteis.map((dia) => (
              <DayConfigCard
                key={dia.value}
                dayNumber={dia.value}
                config={workingDays.find(wd => wd.day_of_week === dia.value)}
                onSave={handleSaveDay}
              />
            ))}
          </TabsContent>
          
          <TabsContent value="2" className="space-y-2 mt-0">
            {fimDeSemana.map((dia) => (
              <DayConfigCard
                key={dia.value}
                dayNumber={dia.value}
                config={workingDays.find(wd => wd.day_of_week === dia.value)}
                onSave={handleSaveDay}
              />
            ))}
          </TabsContent>
          
          <TabsContent value="3" className="space-y-2 mt-0">
            {diasDaSemana.map((dia) => (
              <DayConfigCard
                key={dia.value}
                dayNumber={dia.value}
                config={workingDays.find(wd => wd.day_of_week === dia.value)}
                onSave={handleSaveDay}
              />
            ))}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
} 