import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";
import { AlertCircle, ArrowLeft, Check } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import AgendeDivaLogo from "@/components/AgendeDivaLogo";
import { formatPhoneNumber } from "@/lib/utils";
import BypassSignupButton from "@/components/BypassSignupButton";

const Signup = () => {
  const { toast } = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [businessName, setBusinessName] = useState("");
  const [linkName, setLinkName] = useState("");

  // Função para converter o nome do negócio em um slug para o link personalizado
  const convertToSlug = (text: string) => {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/[^a-z0-9]+/g, '') // Remove caracteres especiais
      .replace(/\s+/g, ''); // Remove espaços
  };

  // Atualiza o link personalizado quando o nome do negócio muda
  const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBusinessName = e.target.value;
    setBusinessName(newBusinessName);
    
    // Sempre gera o link a partir do nome do negócio, sem permitir edição manual
    setLinkName(convertToSlug(newBusinessName));
  };

  // Função para validar o formato de email usando regex
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Handler para validar email enquanto o usuário digita
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    
    // Só validamos se o usuário já digitou algo
    if (newEmail.length > 0) {
      setIsEmailValid(isValidEmail(newEmail));
    } else {
      setIsEmailValid(null); // Estado neutro quando o campo está vazio
    }
  };

  // Handler para formatar o telefone enquanto o usuário digita
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhone(formattedPhone);
  };

  const handleSignup = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validações básicas
    if (!acceptedTerms) {
      toast({
        title: "Termos não aceitos",
        description: "Você precisa aceitar os termos de serviço para continuar",
        variant: "destructive",
      });
      return;
    }

    if (!name.trim()) {
      toast({
        title: 'Nome obrigatório',
        description: 'Por favor, insira seu nome completo',
        variant: 'destructive',
      });
      return;
    }

    if (!email || !isValidEmail(email)) {
      toast({
        title: 'Email inválido',
        description: 'Por favor, insira um endereço de email válido no formato correto.',
        variant: 'destructive',
      });
      return;
    }

    if (password !== confirmPassword) {
      toast({
        title: 'Senhas não coincidem',
        description: 'As senhas não coincidem. Por favor, verifique e tente novamente.',
        variant: 'destructive',
      });
      return;
    }

    if (password.length < 6) {
      toast({
        title: 'Senha muito curta',
        description: 'A senha deve ter pelo menos 6 caracteres.',
        variant: 'destructive',
      });
      return;
    }

    if (phone.length < 14) {
      toast({
        title: 'Telefone inválido',
        description: 'Por favor, insira um número de telefone válido com DDD.',
        variant: 'destructive',
      });
      return;
    }

    // Se todas as validações passarem, não fazemos nada aqui
    // O botão de BypassSignupButton é que vai fazer o cadastro
  };

  return (
    <div className="min-h-screen flex">
      {/* Lado esquerdo - Formulário */}
      <div className="w-full md:w-1/2 bg-white p-8 flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">
          <div className="flex items-center mb-8">
            <Link to="/" className="flex items-center text-gray-600 hover:text-diva-pink transition-colors">
              <ArrowLeft className="h-4 w-4 mr-1" />
              Voltar para página inicial
            </Link>
          </div>

          <div className="flex items-center gap-2 mb-8">
            <AgendeDivaLogo />
            <h1 className="text-xl font-bold text-gray-800">AgendeDiva</h1>
          </div>

          <h2 className="text-2xl font-bold text-gray-900 mb-2">Crie sua conta</h2>
          <p className="text-gray-600 mb-6">
            Preencha seus dados para começar seu período de teste gratuito de 15 dias
          </p>

          {errorMessage && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Erro no cadastro</AlertTitle>
              <AlertDescription>
                {errorMessage}
              </AlertDescription>
            </Alert>
          )}
          
          <form className="space-y-4" onSubmit={handleSignup}>
            <div>
              <Label htmlFor="name">Seu nome completo</Label>
              <Input
                id="name"
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Digite seu nome"
                className="mt-1"
              />
            </div>

            <div>
              <Label htmlFor="email">Email</Label>
              <div className="relative">
                <Input
                  id="email"
                  type="email"
                  required
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="seu@email.com"
                  className={`mt-1 ${
                    isEmailValid === false ? "border-red-500 pr-10" : 
                    isEmailValid === true ? "border-green-500 pr-10" : ""
                  }`}
                />
                {isEmailValid === false && email.length > 0 && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-red-500 mt-1">
                    <AlertCircle className="h-4 w-4" />
                  </div>
                )}
                {isEmailValid === true && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-green-500 mt-1">
                    <Check className="h-4 w-4" />
                  </div>
                )}
              </div>
              {isEmailValid === false && email.length > 0 ? (
                <p className="text-xs text-red-500 mt-1">Email inválido. Use um formato válido como exemplo@dominio.com</p>
              ) : (
                <p className="text-xs text-gray-500 mt-1">Este email será usado para acessar sua conta</p>
              )}
            </div>

            <div>
              <Label htmlFor="phone">Telefone</Label>
              <Input
                id="phone"
                type="tel"
                required
                value={phone}
                onChange={handlePhoneChange}
                placeholder="(21) 99999-9999"
                className="mt-1"
              />
              <p className="text-xs text-gray-500 mt-1">Informe seu telefone com DDD</p>
            </div>

            <div>
              <Label htmlFor="password">Senha</Label>
              <Input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mínimo 8 caracteres"
                className="mt-1"
              />
              <p className="text-xs text-gray-500 mt-1">Use pelo menos 8 caracteres com letras e números</p>
            </div>

            <div>
              <Label htmlFor="confirmPassword">Confirmar Senha</Label>
              <Input
                id="confirmPassword"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Digite sua senha novamente"
                className="mt-1"
              />
            </div>

            <div>
              <Label htmlFor="businessName">Nome do seu negócio</Label>
              <Input
                id="businessName"
                type="text"
                value={businessName}
                onChange={handleBusinessNameChange}
                placeholder="Ex: Studio Ana Makeup"
                className="mt-1"
              />
            </div>

            <div>
              <Label htmlFor="linkName">Seu link personalizado</Label>
              <div className="flex items-center mt-1">
                <div className="bg-gray-100 px-3 py-2 text-gray-600 border border-r-0 border-gray-300 rounded-l-md text-sm">
                  agendediva.com/
                </div>
                <Input
                  id="linkName"
                  type="text"
                  value={linkName}
                  readOnly
                  className="rounded-l-none bg-gray-50"
                  placeholder="Gerado automaticamente"
                />
              </div>
              <p className="text-xs text-gray-500 mt-1">Este link é gerado com base no nome do seu negócio</p>
            </div>

            <div className="flex items-start pt-2">
              <Checkbox 
                id="terms" 
                checked={acceptedTerms}
                onCheckedChange={(checked) => setAcceptedTerms(checked as boolean)}
                required
                className="mt-1 mr-2"
              />
              <Label htmlFor="terms" className="text-xs font-normal">
                Concordo com os{" "}
                <Link to="/terms" className="text-diva-purple hover:text-diva-pink underline">
                  Termos de Serviço
                </Link>{" "}
                e{" "}
                <Link to="/privacy" className="text-diva-purple hover:text-diva-pink underline">
                  Política de Privacidade
                </Link>
              </Label>
            </div>

            <BypassSignupButton 
              email={email}
              password={password}
              name={name}
              businessName={businessName}
              phone={phone}
              disabled={!acceptedTerms || password !== confirmPassword || !isValidEmail(email)}
            />

            <div className="text-center text-sm text-gray-600 mt-4">
              Já tem uma conta?{" "}
              <Link to="/login" className="text-diva-purple hover:text-diva-pink font-medium">
                Faça login
              </Link>
            </div>
          </form>
        </div>
      </div>

      {/* Lado direito - Plano e benefícios */}
      <div className="hidden md:block md:w-1/2 bg-gradient-to-br from-diva-pink to-diva-purple text-white">
        <div className="h-full flex items-center justify-center p-8">
          <div className="max-w-lg mx-auto">
            <h2 className="text-3xl font-bold mb-2 text-center">Experiência gratuita</h2>
            <h3 className="text-2xl font-bold mb-8 text-center">por 15 dias</h3>

            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-8 text-center">
              <div className="text-sm font-medium">Após o período gratuito</div>
              <div className="text-2xl font-bold">R$19,90<span className="text-sm font-normal">/mês</span></div>
            </div>

            <h4 className="text-lg font-medium mb-4">O que você terá acesso:</h4>
            
            <ul className="space-y-3">
              <li className="flex items-start">
                <div className="bg-white/20 rounded-full p-1 mr-3 mt-0.5">
                  <Check className="h-4 w-4" />
                </div>
                <span>Link personalizado para seu negócio</span>
              </li>
              <li className="flex items-start">
                <div className="bg-white/20 rounded-full p-1 mr-3 mt-0.5">
                  <Check className="h-4 w-4" />
                </div>
                <span>Sistema completo de agendamentos</span>
              </li>
              <li className="flex items-start">
                <div className="bg-white/20 rounded-full p-1 mr-3 mt-0.5">
                  <Check className="h-4 w-4" />
                </div>
                <span>Dashboard administrativo exclusivo</span>
              </li>
              <li className="flex items-start">
                <div className="bg-white/20 rounded-full p-1 mr-3 mt-0.5">
                  <Check className="h-4 w-4" />
                </div>
                <span>Gestão de clientes e histórico</span>
              </li>
              <li className="flex items-start">
                <div className="bg-white/20 rounded-full p-1 mr-3 mt-0.5">
                  <Check className="h-4 w-4" />
                </div>
                <span>Controle financeiro simplificado</span>
              </li>
              <li className="flex items-start">
                <div className="bg-white/20 rounded-full p-1 mr-3 mt-0.5">
                  <Check className="h-4 w-4" />
                </div>
                <span>Lembretes automáticos para clientes</span>
              </li>
            </ul>

            <div className="text-sm text-center mt-8">
              Sem taxas de instalação ou configuração.<br />
              Cancele quando quiser.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
