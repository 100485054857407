import React, { useState } from 'react';
import { Appointment } from '@/types/appointment';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { 
  Calendar, 
  Clock, 
  CheckCircle, 
  X, 
  CheckCheck, 
  User, 
  Phone, 
  Mail, 
  MessageSquare,
  ChevronDown,
  ChevronUp,
  MapPin,
  Loader2
} from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { cn } from '@/lib/utils';
import { toast } from 'sonner';
import { NotificationDialog } from './NotificationDialog';

interface AppointmentCardProps {
  appointment: Appointment;
  onUpdateStatus: (id: string, status: 'confirmed' | 'cancelled' | 'completed') => Promise<void>;
}

export function AppointmentCard({ appointment, onUpdateStatus }: AppointmentCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  
  const statusColors = {
    pending: 'bg-yellow-100 text-yellow-800 border-yellow-200',
    confirmed: 'bg-green-100 text-green-800 border-green-200',
    cancelled: 'bg-gray-100 text-gray-800 border-gray-200',
    completed: 'bg-blue-100 text-blue-800 border-blue-200'
  };
  
  const statusText = {
    pending: 'Pendente',
    confirmed: 'Confirmado',
    cancelled: 'Cancelado',
    completed: 'Concluído'
  };

  const statusIcons = {
    pending: <span className="w-1.5 h-1.5 rounded-full bg-yellow-500 mr-1.5"></span>,
    confirmed: <span className="w-1.5 h-1.5 rounded-full bg-green-500 mr-1.5"></span>,
    cancelled: <span className="w-1.5 h-1.5 rounded-full bg-gray-500 mr-1.5"></span>,
    completed: <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mr-1.5"></span>
  };

  const borderColors = {
    pending: 'border-l-yellow-400',
    confirmed: 'border-l-green-400',
    cancelled: 'border-l-gray-400',
    completed: 'border-l-blue-400'
  };
  
  const handleStatusUpdate = async (status: 'confirmed' | 'cancelled' | 'completed') => {
    if (status === 'confirmed' && !showConfirmDialog) {
      setShowConfirmDialog(true);
      return;
    }

    setIsLoading(true);
    try {
      await onUpdateStatus(appointment.id, status);
      setShowConfirmDialog(false);
      
      // Se o status for confirmado, mostrar diálogo de notificação
      if (status === 'confirmed') {
        setShowNotificationDialog(true);
      }
      
      // Feedback visual de sucesso
      toast.success(`Agendamento ${
        status === 'confirmed' ? 'confirmado' : 
        status === 'cancelled' ? 'cancelado' : 'concluído'
      } com sucesso!`);
    } catch (error) {
      toast.error('Erro ao atualizar o agendamento');
    } finally {
      setIsLoading(false);
    }
  };
  
  const appointmentDate = new Date(appointment.date);
  const formattedDate = format(appointmentDate, "dd 'de' MMMM", { locale: ptBR });
  const dayOfWeek = format(appointmentDate, "EEEE", { locale: ptBR });
  const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  
  const openWhatsApp = (e: React.MouseEvent) => {
    e.stopPropagation();
    const phoneNumber = appointment.client_phone.replace(/\D/g, '');
    window.open(`https://api.whatsapp.com/send?phone=55${phoneNumber}`, '_blank');
  };
  
  const openEmail = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (appointment.client_email) {
      window.open(`mailto:${appointment.client_email}`, '_blank');
    }
  };
  
  const makeCall = (e: React.MouseEvent) => {
    e.stopPropagation();
    const phoneNumber = appointment.client_phone.replace(/\D/g, '');
    window.open(`tel:+55${phoneNumber}`, '_blank');
  };

  return (
    <>
      <div 
        className={cn(
          "bg-white rounded-lg border border-l-4 transition-all duration-200 ease-in-out overflow-hidden",
          borderColors[appointment.status],
          isExpanded ? 'shadow-md' : 'shadow-sm hover:shadow-md'
        )}
        onClick={toggleExpand}
      >
        {/* Conteúdo Principal */}
        <div className="p-4">
          <div className="flex items-center gap-3">
            {/* Avatar e Nome */}
            <div className="flex items-center gap-3 min-w-0">
              <div className="w-8 h-8 rounded-full bg-diva-pink/10 flex items-center justify-center flex-shrink-0">
                <User className="h-4 w-4 text-diva-pink" />
              </div>
              <h4 className="font-medium text-gray-900 truncate">{appointment.client_name}</h4>
            </div>

            {/* Data e Hora */}
            <div className="flex items-center gap-3 text-sm text-gray-500 flex-shrink-0">
              <div className="flex items-center gap-1">
                <Calendar className="h-3.5 w-3.5" />
                <span>{capitalizedDayOfWeek}, {formattedDate}</span>
              </div>
              <div className="flex items-center gap-1">
                <Clock className="h-3.5 w-3.5" />
                <span>{appointment.time_slot}</span>
              </div>
            </div>

            {/* Serviço */}
            <div className="text-sm text-gray-500 flex-shrink-0">
              {appointment.service?.name || 'Serviço não especificado'}
            </div>

            {/* Status e Preço */}
            <div className="flex items-center gap-3 ml-auto">
              <Badge variant="outline" className={statusColors[appointment.status]}>
                {statusIcons[appointment.status]}
                {statusText[appointment.status]}
              </Badge>
              {appointment.service?.price && (
                <span className="text-sm font-medium text-gray-900 min-w-[80px] text-right">
                  {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(appointment.service.price)}
                </span>
              )}

              {/* Botões de Ação */}
              {appointment.status === 'pending' && (
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-red-600 hover:bg-red-50 hover:text-red-700 p-0 h-8 w-8"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusUpdate('cancelled');
                    }}
                    disabled={isLoading}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className={cn(
                      "text-green-600 hover:bg-green-50 hover:text-green-700 p-0 h-8 w-8",
                      showConfirmDialog && "bg-green-50"
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusUpdate('confirmed');
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <CheckCircle className="h-4 w-4" />
                    )}
                  </Button>
                </div>
              )}

              {appointment.status === 'confirmed' && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-blue-600 hover:bg-blue-50 hover:text-blue-700 p-0 h-8 w-8"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatusUpdate('completed');
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <CheckCheck className="h-4 w-4" />
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Detalhes Expandidos */}
        {isExpanded && (
          <div className="border-t border-gray-100 bg-gray-50 p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Contatos */}
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm">
                  <Mail className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-600">{appointment.client_email}</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <MessageSquare className="h-4 w-4 text-gray-400" />
                  <span className="text-gray-600">{appointment.client_phone}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-6 px-2 text-green-600 hover:text-green-700 hover:bg-green-50"
                    onClick={openWhatsApp}
                  >
                    WhatsApp
                  </Button>
                </div>
              </div>
            </div>

            {appointment.notes && (
              <div className="mt-4 pt-4 border-t border-gray-200">
                <p className="text-sm text-gray-600">{appointment.notes}</p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Dialog de Confirmação */}
      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50" onClick={() => setShowConfirmDialog(false)}>
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4" onClick={e => e.stopPropagation()}>
            <h3 className="text-lg font-semibold mb-2">Confirmar Agendamento</h3>
            <p className="text-gray-600 text-sm mb-4">
              Você está confirmando o agendamento de {appointment.client_name} para {capitalizedDayOfWeek}, {formattedDate} às {appointment.time_slot}.
            </p>
            <div className="flex justify-end gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setShowConfirmDialog(false)}
              >
                Cancelar
              </Button>
              <Button
                size="sm"
                className="bg-green-600 hover:bg-green-700"
                onClick={() => handleStatusUpdate('confirmed')}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center">
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    Confirmando...
                  </div>
                ) : (
                  <>
                    <CheckCircle className="h-4 w-4 mr-1" />
                    Confirmar
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Dialog de Notificação */}
      <NotificationDialog
        appointment={appointment}
        open={showNotificationDialog}
        onOpenChange={setShowNotificationDialog}
      />
    </>
  );
} 