import React from 'react';
import LegalPageLayout from '@/components/LegalPageLayout';

const RefundPolicy = () => {
  return (
    <LegalPageLayout title="Política de Reembolso" lastUpdated="5 de Abril de 2024">
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            1. Introdução
          </h2>
          <p>
            Esta Política de Reembolso descreve quando e como os clientes podem solicitar reembolso por serviços contratados na plataforma AgendeDiva. Compreendemos que podem surgir situações em que um reembolso seja necessário, e esta política visa esclarecer nossos procedimentos para garantir transparência e confiança.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            2. Elegibilidade para Reembolso
          </h2>
          <p>
            O AgendeDiva processa solicitações de reembolso nas seguintes circunstâncias:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>
              <span className="font-medium">Período de Avaliação Inicial:</span> Oferecemos um reembolso total para assinaturas canceladas dentro dos primeiros 7 dias após a contratação (aplicável apenas para novos usuários em seu primeiro plano).
            </li>
            <li>
              <span className="font-medium">Problemas Técnicos:</span> Se você experienciar falhas técnicas significativas que impossibilitem o uso adequado da plataforma e nossa equipe de suporte não conseguir resolver o problema em tempo hábil.
            </li>
            <li>
              <span className="font-medium">Cobrança Duplicada:</span> Se você foi cobrado mais de uma vez pelo mesmo serviço de forma incorreta.
            </li>
            <li>
              <span className="font-medium">Indisponibilidade do Serviço:</span> Se o AgendeDiva estiver indisponível por um período contínuo superior a 24 horas (excluindo manutenções programadas previamente anunciadas).
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            3. Situações Não Elegíveis para Reembolso
          </h2>
          <p>
            Reembolsos geralmente não são concedidos nas seguintes situações:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Cancelamento após o período de avaliação inicial de 7 dias para novos usuários.</li>
            <li>Insatisfação com funcionalidades que estão claramente descritas em nossos materiais de marketing ou termos de serviço.</li>
            <li>Falta de uso da plataforma durante o período contratado.</li>
            <li>Violação dos nossos <a href="/termos-de-servico" className="text-diva-pink hover:underline">Termos de Serviço</a>.</li>
            <li>Upgrades ou downgrades de planos que já foram processados e utilizados.</li>
            <li>Cancelamento de planos anuais após o período inicial de avaliação, exceto em casos excepcionais a serem analisados individualmente.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            4. Processo de Solicitação de Reembolso
          </h2>
          <p>
            Para solicitar um reembolso, siga os passos abaixo:
          </p>
          <ol className="list-decimal pl-6 space-y-2 mt-2">
            <li>Acesse sua conta no AgendeDiva.</li>
            <li>Navegue até "Configurações" e depois "Faturamento".</li>
            <li>Selecione a opção "Solicitar Reembolso".</li>
            <li>Preencha o formulário de solicitação, descrevendo o motivo do pedido de reembolso.</li>
            <li>Envie a solicitação e aguarde o retorno da nossa equipe.</li>
          </ol>
          <p className="mt-3">
            Alternativamente, você pode enviar um e-mail para <a href="mailto:financeiro@agendediva.com.br" className="text-diva-pink hover:underline">financeiro@agendediva.com.br</a> com as seguintes informações:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Assunto: Solicitação de Reembolso - [Seu Nome]</li>
            <li>Seu nome completo e e-mail cadastrado</li>
            <li>Data da compra</li>
            <li>Valor pago</li>
            <li>Motivo detalhado da solicitação de reembolso</li>
            <li>Comprovante de pagamento (se disponível)</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            5. Prazos para Processamento
          </h2>
          <p>
            Após o recebimento da sua solicitação:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Nossa equipe analisará o pedido em até 5 dias úteis.</li>
            <li>Você receberá uma notificação por e-mail sobre a decisão.</li>
            <li>Se aprovado, o reembolso será processado em até 10 dias úteis.</li>
            <li>O tempo para o valor aparecer em sua conta depende da instituição financeira e do método de pagamento utilizado, podendo variar de 5 a 30 dias.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            6. Formas de Reembolso
          </h2>
          <p>
            O reembolso será processado da seguinte forma:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Para pagamentos com cartão de crédito: o valor será estornado no cartão utilizado na compra.</li>
            <li>Para pagamentos via boleto ou PIX: o valor será reembolsado através de transferência bancária para a conta indicada pelo cliente no momento da solicitação.</li>
            <li>Para pagamentos via PayPal ou outros métodos eletrônicos: o valor será reembolsado para a conta utilizada na transação original.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            7. Cancelamento de Assinaturas
          </h2>
          <p>
            É importante diferenciar o cancelamento de assinatura do pedido de reembolso:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>O cancelamento interrompe as cobranças futuras, mas não resulta automaticamente em reembolso do período já pago.</li>
            <li>Para planos mensais: o cancelamento é efetivo no final do ciclo de faturamento atual.</li>
            <li>Para planos anuais: o serviço permanecerá disponível até o fim do período contratado, mesmo após o cancelamento.</li>
          </ul>
          <p className="mt-3">
            Para cancelar sua assinatura sem solicitar reembolso, acesse "Configurações" e depois "Faturamento" em sua conta e selecione "Cancelar Assinatura".
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            8. Alterações na Política de Reembolso
          </h2>
          <p>
            O AgendeDiva reserva-se o direito de modificar esta política a qualquer momento. Alterações entrarão em vigor assim que publicadas em nosso site. Recomendamos que os usuários revisem periodicamente esta política para estarem cientes de quaisquer mudanças.
          </p>
        </section>
        
        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            9. Contato
          </h2>
          <p>
            Se você tiver dúvidas sobre nossa Política de Reembolso, entre em contato conosco através do e-mail <a href="mailto:financeiro@agendediva.com.br" className="text-diva-pink hover:underline">financeiro@agendediva.com.br</a>.
          </p>
        </section>
      </div>
    </LegalPageLayout>
  );
};

export default RefundPolicy; 