import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Calendar, BarChart2, Clock, Users } from "lucide-react";

const DashboardPreview = () => {
  return (
    <section className="section-padding bg-white">
      <div className="container mx-auto">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-diva-dark mb-4">
            Visão completa do seu negócio
          </h2>
          <p className="text-diva-dark/70 text-lg">
            O painel administrativo do AgendeDiva foi desenhado especialmente para profissionais da beleza,
            com todas as ferramentas que você precisa para gerenciar seu negócio.
          </p>
        </div>

        <div className="max-w-5xl mx-auto">
          <Tabs defaultValue="agendamentos" className="w-full">
            <TabsList className="grid grid-cols-4 mb-8">
              <TabsTrigger value="agendamentos" className="flex items-center">
                <Calendar className="h-4 w-4 mr-2" /> Agendamentos
              </TabsTrigger>
              <TabsTrigger value="clientes" className="flex items-center">
                <Users className="h-4 w-4 mr-2" /> Clientes
              </TabsTrigger>
              <TabsTrigger value="financeiro" className="flex items-center">
                <BarChart2 className="h-4 w-4 mr-2" /> Financeiro
              </TabsTrigger>
              <TabsTrigger value="servicos" className="flex items-center">
                <Clock className="h-4 w-4 mr-2" /> Serviços
              </TabsTrigger>
            </TabsList>
            
            {/* Agendamentos */}
            <TabsContent value="agendamentos" className="mt-0">
              <div className="bg-white rounded-lg shadow-xl border border-gray-100 overflow-hidden">
                <div className="bg-gradient-to-r from-diva-pink to-diva-purple p-4 text-white flex justify-between items-center">
                  <h3 className="font-medium">Dashboard de Agendamentos</h3>
                  <span className="text-sm">Studio Beleza da Ana</span>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-3 gap-4 mb-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Agendamentos hoje</p>
                      <p className="text-3xl font-bold text-diva-dark">8</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Próximo horário</p>
                      <p className="text-xl font-bold text-diva-dark">14:30</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Taxa de ocupação</p>
                      <p className="text-xl font-bold text-diva-dark">85%</p>
                    </div>
                  </div>
                  
                  <div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cliente</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serviço</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Horário</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-10 w-10 rounded-full bg-diva-pink-light flex items-center justify-center text-diva-pink font-medium">MC</div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">Marina Costa</div>
                                <div className="text-sm text-gray-500">(11) 98765-4321</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Extensão de Cílios
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            14:30 - 16:00
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              Confirmado
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-10 w-10 rounded-full bg-diva-purple-light flex items-center justify-center text-diva-purple font-medium">PL</div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">Paula Lima</div>
                                <div className="text-sm text-gray-500">(11) 91234-5678</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Depilação Completa
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            16:30 - 17:30
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              Pendente
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabsContent>
            
            {/* Financeiro */}
            <TabsContent value="financeiro">
              <div className="bg-white rounded-lg shadow-xl border border-gray-100 overflow-hidden">
                <div className="bg-gradient-to-r from-diva-pink to-diva-purple p-4 text-white flex justify-between items-center">
                  <h3 className="font-medium">Dashboard Financeiro</h3>
                  <span className="text-sm">Studio Beleza da Ana</span>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-3 gap-4 mb-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Faturamento do mês</p>
                      <p className="text-3xl font-bold text-diva-dark">R$ 3.250,00</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Ticket médio</p>
                      <p className="text-xl font-bold text-diva-dark">R$ 145,00</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Crescimento</p>
                      <p className="text-xl font-bold text-green-600">+12%</p>
                    </div>
                  </div>
                  
                  <div className="h-64 border rounded-lg bg-gray-50 flex items-center justify-center">
                    <p className="text-gray-400">Gráfico de faturamento (simulação)</p>
                  </div>
                </div>
              </div>
            </TabsContent>
            
            {/* Clientes */}
            <TabsContent value="clientes">
              <div className="bg-white rounded-lg shadow-xl border border-gray-100 overflow-hidden">
                <div className="bg-gradient-to-r from-diva-pink to-diva-purple p-4 text-white flex justify-between items-center">
                  <h3 className="font-medium">Gestão de Clientes</h3>
                  <span className="text-sm">Studio Beleza da Ana</span>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-3 gap-4 mb-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Total de clientes</p>
                      <p className="text-3xl font-bold text-diva-dark">124</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Novos clientes (mês)</p>
                      <p className="text-xl font-bold text-diva-dark">16</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Taxa de retorno</p>
                      <p className="text-xl font-bold text-diva-dark">72%</p>
                    </div>
                  </div>
                  
                  <div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cliente</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contato</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Última visita</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total gasto</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-10 w-10 rounded-full bg-diva-pink-light flex items-center justify-center text-diva-pink font-medium">MC</div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">Marina Costa</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            (11) 98765-4321
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            15/08/2023
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                            R$ 620,00
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-10 w-10 rounded-full bg-diva-purple-light flex items-center justify-center text-diva-purple font-medium">PL</div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">Paula Lima</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            (11) 91234-5678
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            22/08/2023
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                            R$ 450,00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabsContent>
            
            {/* Serviços */}
            <TabsContent value="servicos">
              <div className="bg-white rounded-lg shadow-xl border border-gray-100 overflow-hidden">
                <div className="bg-gradient-to-r from-diva-pink to-diva-purple p-4 text-white flex justify-between items-center">
                  <h3 className="font-medium">Gestão de Serviços</h3>
                  <span className="text-sm">Studio Beleza da Ana</span>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-3 gap-4 mb-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Total de serviços</p>
                      <p className="text-3xl font-bold text-diva-dark">12</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Mais procurado</p>
                      <p className="text-lg font-bold text-diva-dark truncate">Extensão de Cílios</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm text-gray-500">Tempo médio</p>
                      <p className="text-xl font-bold text-diva-dark">1h15min</p>
                    </div>
                  </div>
                  
                  <div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serviço</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duração</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Preço</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">Extensão de Cílios</div>
                            <div className="text-sm text-gray-500">Volume Brasileiro</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            1h30min
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            R$ 180,00
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              Ativo
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">Design de Sobrancelhas</div>
                            <div className="text-sm text-gray-500">Com Henna</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            45min
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            R$ 80,00
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              Ativo
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default DashboardPreview;
