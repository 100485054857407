import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import AgendeDivaLogo from "@/components/AgendeDivaLogo";
import { authService } from "@/services/authService";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      toast({
        title: "Erro na validação",
        description: "As senhas não coincidem.",
        variant: "destructive",
      });
      return;
    }

    if (password.length < 6) {
      toast({
        title: "Erro na validação",
        description: "A senha deve ter pelo menos 6 caracteres.",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);

    try {
      await authService.updatePassword(password);
      toast({
        title: "Senha atualizada com sucesso!",
        description: "Você já pode fazer login com sua nova senha.",
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Erro ao atualizar senha",
        description: "Não foi possível atualizar sua senha. Tente novamente.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Left side - Form */}
      <div className="w-full md:w-1/2 p-6 md:p-10 flex items-center justify-center">
        <div className="w-full max-w-md">
          <div className="flex items-center gap-2 mb-10">
            <AgendeDivaLogo />
            <h1 className="text-2xl font-bold text-diva-dark font-serif">AgendeDiva</h1>
          </div>

          <div className="mb-10">
            <h1 className="text-3xl font-bold font-serif text-diva-dark mb-2">
              Crie uma nova senha
            </h1>
            <p className="text-diva-dark/70">
              Digite e confirme sua nova senha para recuperar o acesso à sua conta
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="password">Nova senha</Label>
              <Input
                id="password"
                type="password"
                placeholder="••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="border-diva-pink/20 focus:border-diva-pink"
              />
              <p className="text-sm text-diva-dark/70">
                Mínimo de 6 caracteres
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="confirmPassword">Confirme a nova senha</Label>
              <Input
                id="confirmPassword"
                type="password"
                placeholder="••••••"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="border-diva-pink/20 focus:border-diva-pink"
              />
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-diva-pink to-diva-purple"
              disabled={isLoading}
            >
              {isLoading ? "Atualizando..." : "Atualizar senha"}
            </Button>
          </form>
        </div>
      </div>

      {/* Right side - Image */}
      <div className="hidden md:block md:w-1/2 bg-gradient-to-br from-diva-pink to-diva-purple relative overflow-hidden">
        <div className="absolute inset-0 flex items-center justify-center p-10">
          <div className="bg-white/5 backdrop-blur-sm rounded-[20px] p-8 w-[500px] text-center shadow-lg">
            <h2 className="text-4xl font-bold text-white mb-4 font-serif">
              Quase lá!
            </h2>
            <p className="text-white text-lg mb-6">
              Crie uma senha forte para manter sua conta segura e continue gerenciando seus
              agendamentos com tranquilidade.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword; 