import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Mail, MessageSquare, CheckCheck, X, Send, User, Calendar, Clock, Scissors, MapPin, Loader2 } from "lucide-react";
import { Appointment } from "@/types/appointment";
import { notificationService } from "@/services/notificationService";
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Badge } from './ui/badge';
import { toast } from 'sonner';

interface NotificationDialogProps {
  appointment: Appointment;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function NotificationDialog({ appointment, open, onOpenChange }: NotificationDialogProps) {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  
  const formattedDate = format(parseISO(appointment.date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
  
  const handleWhatsApp = async () => {
    setIsSending(true);
    await notificationService.sendWhatsAppConfirmation(appointment);
    setIsSent(true);
    setIsSending(false);
    setTimeout(() => onOpenChange(false), 1500);
  };

  const handleEmail = async () => {
    setIsSending(true);
    await notificationService.sendEmailConfirmation(appointment);
    setIsSent(true);
    setIsSending(false);
    setTimeout(() => onOpenChange(false), 1500);
  };

  const handleBoth = async () => {
    setIsSending(true);
    await notificationService.sendWhatsAppConfirmation(appointment);
    await notificationService.sendEmailConfirmation(appointment);
    setIsSent(true);
    setIsSending(false);
    setTimeout(() => onOpenChange(false), 1500);
  };

  const handleSkip = () => {
    onOpenChange(false);
  };

  if (isSent) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-md max-w-[95vw] rounded-lg mx-auto">
          <div className="py-8 sm:py-10 flex flex-col items-center justify-center">
            <div className="w-14 h-14 sm:w-16 sm:h-16 bg-green-100 rounded-full flex items-center justify-center mb-3 sm:mb-4">
              <Send className="h-7 w-7 sm:h-8 sm:w-8 text-green-600" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-center mb-2">Notificação Enviada!</h2>
            <p className="text-sm text-gray-500 text-center px-2">
              O cliente foi notificado sobre a confirmação do agendamento.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md max-w-[95vw] rounded-lg mx-auto">
        <DialogHeader className="space-y-2">
          <DialogTitle className="text-lg sm:text-xl">Enviar notificação</DialogTitle>
          <DialogDescription className="text-xs sm:text-sm">
            O agendamento foi confirmado. Como deseja notificar o cliente?
          </DialogDescription>
        </DialogHeader>
        
        {/* Resumo do agendamento */}
        <div className="bg-gray-50 p-3 sm:p-4 rounded-lg my-2 border border-gray-100">
          <div className="flex items-center mb-2 sm:mb-3">
            <Badge variant="outline" className="bg-green-100 text-green-800 border-green-200 text-xs">
              <span className="w-1.5 h-1.5 rounded-full bg-green-600 mr-1.5"></span>
              Confirmado
            </Badge>
          </div>
          
          <div className="space-y-1.5 sm:space-y-2">
            <div className="flex items-center text-xs sm:text-sm">
              <User className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-diva-pink mr-1.5 sm:mr-2 flex-shrink-0" />
              <span className="font-medium truncate">{appointment.client_name}</span>
            </div>
            <div className="flex items-center text-xs sm:text-sm">
              <Calendar className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-diva-pink mr-1.5 sm:mr-2 flex-shrink-0" />
              <span>{formattedDate}</span>
            </div>
            <div className="flex items-center text-xs sm:text-sm">
              <Clock className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-diva-pink mr-1.5 sm:mr-2 flex-shrink-0" />
              <span>{appointment.time_slot}</span>
            </div>
            <div className="flex items-start text-xs sm:text-sm">
              <Scissors className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-diva-pink mr-1.5 sm:mr-2 mt-0.5 flex-shrink-0" />
              <span className="truncate">
                {appointment.service?.name || 'Serviço não especificado'}
                {appointment.service?.price && (
                  <span className="ml-1 text-diva-pink font-medium">
                    (R$ {appointment.service.price.toFixed(2)})
                  </span>
                )}
              </span>
            </div>
            {appointment.notes && (
              <div className="text-xs text-gray-500 bg-white p-2 rounded border border-gray-100 mt-2 max-h-16 overflow-y-auto">
                {appointment.notes}
              </div>
            )}
          </div>
        </div>
        
        <div className="grid grid-cols-1 gap-2 sm:gap-3 py-2">
          <Button
            onClick={handleWhatsApp}
            className="bg-green-600 hover:bg-green-700 text-white w-full flex items-center justify-center py-1.5 sm:py-2 h-auto"
            disabled={isSending}
          >
            {isSending ? 
              <div className="flex items-center"><span className="animate-spin h-3.5 w-3.5 sm:h-4 sm:w-4 mr-2 border-2 border-white border-t-transparent rounded-full"></span><span className="text-xs sm:text-sm">Enviando...</span></div> : 
              <><MessageSquare className="mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4" /><span className="text-xs sm:text-sm">WhatsApp</span></>
            }
          </Button>
          
          <Button
            onClick={handleEmail}
            className="bg-blue-600 hover:bg-blue-700 text-white w-full flex items-center justify-center py-1.5 sm:py-2 h-auto"
            disabled={isSending || !appointment.client_email}
          >
            {isSending ? 
              <div className="flex items-center"><span className="animate-spin h-3.5 w-3.5 sm:h-4 sm:w-4 mr-2 border-2 border-white border-t-transparent rounded-full"></span><span className="text-xs sm:text-sm">Enviando...</span></div> : 
              <><Mail className="mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4" /><span className="text-xs sm:text-sm">E-mail</span></>
            }
          </Button>
          
          <Button
            onClick={handleBoth}
            className="bg-gradient-to-r from-diva-pink to-diva-purple hover:from-diva-pink/90 hover:to-diva-purple/90 text-white w-full flex items-center justify-center py-1.5 sm:py-2 h-auto"
            disabled={isSending || !appointment.client_email}
          >
            {isSending ? 
              <div className="flex items-center"><span className="animate-spin h-3.5 w-3.5 sm:h-4 sm:w-4 mr-2 border-2 border-white border-t-transparent rounded-full"></span><span className="text-xs sm:text-sm">Enviando...</span></div> : 
              <><CheckCheck className="mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4" /><span className="text-xs sm:text-sm">Ambos</span></>
            }
          </Button>
        </div>
        
        <DialogFooter>
          <Button
            onClick={handleSkip}
            variant="outline"
            size="sm"
            className="w-full sm:w-auto flex items-center justify-center text-xs sm:text-sm"
            disabled={isSending}
          >
            <X className="mr-2 h-3.5 w-3.5" />
            Pular
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 