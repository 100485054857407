import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import HeroSection from "@/components/HeroSection";
import FeatureSection from "@/components/FeatureSection";
import TestimonialSection from "@/components/TestimonialSection";
import TeamSection from "@/components/TeamSection";
import CTASection from "@/components/CTASection";
import DashboardPreview from "@/components/DashboardPreview";

const LandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main className="flex-grow">
        <HeroSection />
        <FeatureSection />
        <DashboardPreview />
        <TestimonialSection />
        <TeamSection />
        <CTASection />
      </main>
      
      <Footer />
    </div>
  );
};

export default LandingPage;
