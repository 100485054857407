import { supabase } from '@/lib/supabase';
import { ProfileSettings, ProfileSettingsInput, BusinessSettings, BusinessSettingsInput } from '@/types/settings';

export const settingsService = {
  async getProfile(userId: string): Promise<ProfileSettings | null> {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  },

  async updateProfile(userId: string, profile: Partial<ProfileSettingsInput>): Promise<ProfileSettings> {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .update(profile)
        .eq('id', userId)
        .select()
        .single();

      if (error) {
        console.error('Erro ao atualizar perfil:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      throw error;
    }
  },

  async getBusiness(professionalId: string): Promise<BusinessSettings | null> {
    try {
      const { data, error } = await supabase
        .from('business_settings')
        .select('*')
        .eq('professional_id', professionalId)
        .single();

      if (error) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  },

  async getBusinessByCustomUrl(customUrl: string): Promise<BusinessSettings | null> {
    try {
      const { data, error } = await supabase
        .from('business_settings')
        .select('*')
        .eq('custom_url', customUrl)
        .single();

      if (error) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  },

  async update(professionalId: string, settings: BusinessSettingsInput): Promise<BusinessSettings> {
    try {
      console.log('Enviando atualização para o banco de dados:', settings);
      
      const { data, error } = await supabase
        .from('business_settings')
        .update(settings)
        .eq('professional_id', professionalId)
        .select()
        .single();

      if (error) {
        console.error('Erro ao atualizar configurações do negócio:', error);
        throw error;
      }

      console.log('Configurações atualizadas com sucesso:', data);
      return data;
    } catch (error) {
      console.error('Erro ao atualizar configurações do negócio:', error);
      throw error;
    }
  },

  async upsertBusiness(professionalId: string, business: BusinessSettingsInput): Promise<BusinessSettings> {
    try {
      const { data, error } = await supabase
        .from('business_settings')
        .upsert({
          professional_id: professionalId,
          ...business
        })
        .select()
        .single();

      if (error) {
        console.error('Erro ao atualizar configurações do negócio:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Erro ao atualizar configurações do negócio:', error);
      throw error;
    }
  }
}; 