import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Card } from '@/components/ui/card';
import '../styles/financial.css'; // Importando o CSS externo 

export default function Financial() {
  const { user } = useAuth();
  const [currentWeek, setCurrentWeek] = useState([]);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simular um tempo de carregamento para mostrar as animações
    const timer = setTimeout(() => {
      generateWeekData();
      setLoading(false);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);

  const generateWeekData = () => {
    const days = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
    const data = days.map((day, index) => {
      // Valores aleatórios simulados para o gráfico
      const heightMultiplier = index === 1 || index === 3 ? 1.5 : 1; // Ter e Qui com valores maiores
      const value = Math.floor((Math.random() * 100 + 50) * heightMultiplier);
      return { day, value };
    });
    
    // Destacar o dia atual
    const today = new Date().getDay();
    // Ajustar para nosso array (0=Dom na JS padrão, mas 6=Dom no nosso array)
    const adjustedToday = today === 0 ? 6 : today - 1;
    
    setCurrentWeek(data.map((item, index) => ({
      ...item,
      isCurrent: index === adjustedToday
    })));
  };

  // Calcular altura máxima para as barras
  const maxValue = currentWeek.length > 0 
    ? Math.max(...currentWeek.map(d => d.value)) 
    : 200;

  if (loading) {
    return (
      <div className="p-6 max-w-7xl mx-auto">
        <div className="flex flex-col space-y-6">
          <h1 className="text-2xl font-bold text-gray-800">Financeiro</h1>
          <p className="text-base text-gray-600">Carregando dados financeiros...</p>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {[1, 2, 3].map((i) => (
              <Card key={i} className="p-6 bg-white border border-gray-100 shadow-sm animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-24 mb-3"></div>
                <div className="h-8 bg-gray-200 rounded w-32"></div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="flex flex-col space-y-6">
        <h1 className="text-2xl font-bold text-gray-800">Financeiro</h1>
        <p className="text-base text-gray-600">Visualize o desempenho financeiro do seu negócio</p>
        
        {/* Cards de resumo */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Card de Faturamento */}
          <Card className="p-6 bg-white border border-gray-100 shadow-sm financial-card">
            <h2 className="text-gray-600 mb-2 text-sm">Faturamento do mês</h2>
            <p className="text-4xl font-bold financial-value">R$ 3.250,00</p>
          </Card>
          
          {/* Card de Ticket Médio */}
          <Card className="p-6 bg-white border border-gray-100 shadow-sm financial-card">
            <h2 className="text-gray-600 mb-2 text-sm">Ticket médio</h2>
            <p className="text-4xl font-bold financial-value">R$ 145,00</p>
          </Card>
          
          {/* Card de Crescimento */}
          <Card className="p-6 bg-white border border-gray-100 shadow-sm financial-card">
            <h2 className="text-gray-600 mb-2 text-sm">Crescimento</h2>
            <p className="text-4xl font-bold text-green-500">+12%</p>
          </Card>
        </div>
        
        {/* Gráfico de barras semanal */}
        <Card className="p-6 bg-white border border-gray-100 shadow-sm overflow-hidden financial-card">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">Faturamento semanal</h2>
          
          <div className="relative h-64 w-full financial-bar-chart">
            {/* Barras do gráfico */}
            <div className="absolute inset-x-0 bottom-10 top-0 flex items-end justify-around px-4">
              {currentWeek.map((day, index) => (
                <div 
                  key={index} 
                  className="group relative flex flex-col items-center"
                  onMouseEnter={() => setHoveredDay(index)}
                  onMouseLeave={() => setHoveredDay(null)}
                  style={{ animationDelay: `${index * 0.08}s` }}
                >
                  {/* Barra do gráfico */}
                  <div 
                    className="w-14 financial-chart-bar"
                    style={{ 
                      height: `${(day.value / maxValue) * 100}%`,
                      animationDelay: `${index * 0.08}s`
                    }}
                  ></div>
                  
                  {/* Tooltip de valor */}
                  {hoveredDay === index && (
                    <div className="financial-tooltip">
                      R$ {day.value}
                    </div>
                  )}
                </div>
              ))}
            </div>
            
            {/* Labels dos dias da semana */}
            <div className="absolute bottom-0 inset-x-0 h-10 flex items-center justify-around">
              {currentWeek.map((day, index) => (
                <div 
                  key={index} 
                  className="text-sm font-medium text-gray-600"
                  onMouseEnter={() => setHoveredDay(index)}
                  onMouseLeave={() => setHoveredDay(null)}
                >
                  {day.day}
                </div>
              ))}
            </div>
          </div>
        </Card>
        
        {/* Gráfico detalhado - similar ao da imagem */}
        <Card className="p-6 bg-white border border-gray-100 shadow-sm financial-card overflow-hidden">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-800">Detalhamento por dia da semana</h2>
            
            {hoveredDay !== null && (
              <div className="text-sm">
                <span className="text-pink-500 font-semibold">R$ {currentWeek[hoveredDay]?.value}</span> 
                <span className="text-gray-500 ml-1">• {currentWeek[hoveredDay]?.day}</span>
              </div>
            )}
          </div>
          
          <div className="relative h-64 w-full overflow-x-auto">
            <div className="financial-chart-container min-w-[500px]">
              {/* Eixo Y com valores */}
              <div className="absolute left-0 top-0 bottom-10 w-14 flex flex-col justify-between">
                {[0, 1, 2, 3, 4].map((i) => (
                  <div key={i} className="flex items-center h-6">
                    <span className="financial-axis-label text-right pr-2">
                      R$ {(200 - i * 50)}
                    </span>
                  </div>
                ))}
              </div>
              
              {/* Linhas de grade horizontais */}
              <div className="absolute left-14 right-0 top-0 bottom-10">
                {[0, 1, 2, 3, 4].map((i) => (
                  <div 
                    key={i} 
                    className="absolute w-full border-t border-gray-200" 
                    style={{ top: `${i * 25}%`, opacity: 0.5 }}
                  ></div>
                ))}
                
                {/* Área para barras do gráfico */}
                <div className="absolute inset-0 flex items-end justify-around px-4">
                  {currentWeek.map((day, index) => (
                    <div 
                      key={index} 
                      className="relative flex flex-col items-center"
                      onMouseEnter={() => setHoveredDay(index)}
                      onMouseLeave={() => setHoveredDay(null)}
                    >
                      {/* Valor acima da barra */}
                      {hoveredDay === index && (
                        <div className="financial-chart-value">
                          R$ {day.value}
                        </div>
                      )}
                      
                      {/* Barra do gráfico */}
                      <div 
                        className="w-12 financial-chart-bar"
                        style={{ 
                          height: `${(day.value / 200) * 100}%`,
                          animationDelay: `${index * 0.08}s`
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Labels dos dias da semana */}
              <div className="absolute bottom-0 left-14 right-0 h-10 flex items-center justify-around px-4">
                {currentWeek.map((day, index) => (
                  <div 
                    key={index} 
                    className="text-sm font-medium text-gray-600"
                    onMouseEnter={() => setHoveredDay(index)}
                    onMouseLeave={() => setHoveredDay(null)}
                  >
                    {day.day}
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* Controles de navegação */}
          <div className="flex justify-between items-center mt-4">
            <button className="financial-nav-button p-1">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button className="financial-nav-button p-1">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </Card>
        
        {/* Seção de histórico mensal */}
        <Card className="p-6 bg-white border border-gray-100 shadow-sm financial-card">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-800">Histórico Mensal</h2>
            <div className="text-sm text-gray-500">Em desenvolvimento</div>
          </div>
          
          <div className="h-20 flex items-center justify-center border border-dashed border-gray-300 rounded-md">
            <p className="text-sm text-gray-500">Histórico mensal em desenvolvimento</p>
          </div>
        </Card>
      </div>
    </div>
  );
} 