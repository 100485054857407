import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'sonner';
import { Appointment } from '@/types/appointment';
import { supabase } from '@/lib/supabase';

// Interface para os dados do profissional
interface Professional {
  name: string;
  business_name: string;
  address: string;
  phone: string;
}

// Serviço para gerenciar notificações para clientes
export const notificationService = {
  // Enviar notificação de confirmação via WhatsApp
  async sendWhatsAppConfirmation(appointment: Appointment): Promise<boolean> {
    try {
      // Buscar informações do profissional
      const { data: professional, error } = await supabase
        .from('profiles')
        .select('name, business_name, address, phone')
        .eq('id', appointment.professional_id)
        .single();

      if (error) {
        console.error('Erro ao buscar dados do profissional:', error);
        throw error;
      }

      // Formatar a data para exibição
      const formattedDate = format(parseISO(appointment.date), "dd 'de' MMMM", { locale: ptBR });
      const dayOfWeek = format(parseISO(appointment.date), 'EEEE', { locale: ptBR });
      const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      
      // Serviço formatado para exibição
      const serviceName = appointment.service?.name || 'Não especificado';
      
      // Montar a mensagem de confirmação
      const message = `Olá ${appointment.client_name}! 👋\n\n` +
        `Seu agendamento foi confirmado para ${capitalizedDayOfWeek}, ${formattedDate} às ${appointment.time_slot}.\n\n` +
        `Serviço: ${serviceName}\n` +
        `Profissional: ${professional.business_name || professional.name}\n` +
        `Local: ${professional.address || 'A confirmar'}\n\n` +
        `Valor: R$ ${appointment.price.toFixed(2)}\n\n` +
        `Agradecemos a preferência! 🙏`;

      // Formata o número de telefone removendo caracteres especiais
      const phone = appointment.client_phone.replace(/\D/g, '');
      
      // Abre o WhatsApp Web com a mensagem pré-preenchida
      window.open(
        `https://wa.me/55${phone}?text=${encodeURIComponent(message)}`,
        '_blank'
      );
      
      // Registrar a notificação enviada
      await this.logNotification({
        appointment_id: appointment.id,
        type: 'whatsapp',
        recipient: appointment.client_phone,
        status: 'sent'
      });
      
      return true;
    } catch (error) {
      console.error('Erro ao enviar WhatsApp:', error);
      toast.error('Erro ao enviar mensagem por WhatsApp');
      return false;
    }
  },

  // Enviar notificação de confirmação via E-mail
  async sendEmailConfirmation(appointment: Appointment): Promise<boolean> {
    try {
      // Verificar se o cliente tem e-mail cadastrado
      if (!appointment.client_email) {
        console.warn('Cliente não possui e-mail cadastrado');
        return false;
      }

      // Buscar informações do profissional
      const { data: professional, error } = await supabase
        .from('profiles')
        .select('name, business_name, address, phone')
        .eq('id', appointment.professional_id)
        .single();

      if (error) {
        console.error('Erro ao buscar dados do profissional:', error);
        throw error;
      }

      // Formatar a data para exibição
      const formattedDate = format(parseISO(appointment.date), "dd 'de' MMMM", { locale: ptBR });
      const dayOfWeek = format(parseISO(appointment.date), 'EEEE', { locale: ptBR });
      const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      
      // Serviço formatado para exibição
      const serviceName = appointment.service?.name || 'Não especificado';
      
      // Montar o assunto do e-mail
      const subject = `Confirmação de Agendamento - ${serviceName}`;
      
      // Montar o corpo do e-mail
      const body = `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
          <h2 style="color: #333;">Confirmação de Agendamento</h2>
          
          <p>Olá ${appointment.client_name},</p>
          
          <p>Seu agendamento foi confirmado com sucesso!</p>
          
          <div style="background-color: #f5f5f5; padding: 20px; border-radius: 8px; margin: 20px 0;">
            <h3 style="margin-top: 0;">Detalhes do Agendamento:</h3>
            <p><strong>Data:</strong> ${capitalizedDayOfWeek}, ${formattedDate}</p>
            <p><strong>Horário:</strong> ${appointment.time_slot}</p>
            <p><strong>Serviço:</strong> ${serviceName}</p>
            <p><strong>Profissional:</strong> ${professional.business_name || professional.name}</p>
            <p><strong>Local:</strong> ${professional.address || 'A confirmar'}</p>
            <p><strong>Valor:</strong> R$ ${appointment.price.toFixed(2)}</p>
          </div>
          
          <p>Agradecemos a preferência!</p>
          
          <p style="color: #666; font-size: 14px;">
            Em caso de dúvidas, entre em contato conosco.
          </p>
        </div>
      `;
      
      // Abre o cliente de email padrão com o email pré-preenchido
      window.location.href = `mailto:${appointment.client_email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      
      // Registrar a notificação enviada
      await this.logNotification({
        appointment_id: appointment.id,
        type: 'email',
        recipient: appointment.client_email,
        status: 'sent'
      });
      
      // Mostrar notificação de sucesso
      toast.success('E-mail enviado com sucesso');
      
      return true;
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
      toast.error('Erro ao enviar e-mail de confirmação');
      return false;
    }
  },

  // Registrar notificação no banco de dados
  async logNotification(notification: {
    appointment_id: string;
    type: 'email' | 'whatsapp' | 'sms';
    recipient: string;
    status: 'sent' | 'failed';
    message?: string;
  }): Promise<void> {
    try {
      const { error } = await supabase
        .from('profiles_notifications')
        .insert([{
          ...notification,
          sent_at: new Date().toISOString()
        }]);

      if (error) {
        console.error('Erro ao registrar notificação:', error);
      }
    } catch (error) {
      console.error('Erro ao registrar notificação:', error);
    }
  }
}; 