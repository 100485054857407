import React from 'react';
import LegalPageLayout from '@/components/LegalPageLayout';

const CookiePolicy = () => {
  return (
    <LegalPageLayout title="Política de Cookies" lastUpdated="5 de Abril de 2024">
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            1. O que são Cookies?
          </h2>
          <p>
            Cookies são pequenos arquivos de texto que são armazenados no seu navegador ou dispositivo quando você visita o AgendeDiva. Eles permitem que nosso site reconheça seu dispositivo e lembre-se de certas informações sobre sua visita, como suas preferências e ações no site.
          </p>
          <p className="mt-3">
            Os cookies são amplamente utilizados para fazer os sites funcionarem de maneira mais eficiente, bem como para fornecer informações aos proprietários do site.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            2. Como Utilizamos os Cookies
          </h2>
          <p>
            O AgendeDiva utiliza cookies para melhorar sua experiência em nossa plataforma. Utilizamos diferentes tipos de cookies para os seguintes propósitos:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>
              <span className="font-medium">Cookies Estritamente Necessários:</span> Essenciais para que você navegue em nosso site e utilize suas funcionalidades, como acesso a áreas seguras. Sem esses cookies, certos serviços não podem ser fornecidos.
            </li>
            <li>
              <span className="font-medium">Cookies de Funcionalidade:</span> Permitem que o site lembre de escolhas que você faz (como seu nome de usuário, idioma ou região) e ofereça recursos aprimorados e mais personalizados.
            </li>
            <li>
              <span className="font-medium">Cookies de Desempenho:</span> Coletam informações sobre como você usa nosso site, quais páginas visita e se ocorrem erros. Esses cookies não coletam informações que identifiquem você diretamente e são usados apenas para melhorar o funcionamento do site.
            </li>
            <li>
              <span className="font-medium">Cookies de Direcionamento ou Publicidade:</span> Podem ser definidos através do nosso site por nossos parceiros de publicidade. Eles podem ser usados por essas empresas para construir um perfil de seus interesses e mostrar anúncios relevantes em outros sites.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            3. Cookies de Terceiros
          </h2>
          <p>
            Alguns cookies são colocados por serviços terceiros que aparecem em nossas páginas. Trabalhamos com terceiros que colocam cookies em nosso site para:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Fornecer análises sobre como o AgendeDiva é usado (como o Google Analytics);</li>
            <li>Permitir que você compartilhe nossas páginas em redes sociais como Facebook e Twitter;</li>
            <li>Oferecer recursos de login através de plataformas de mídia social;</li>
            <li>Monitorar a eficácia de nossas campanhas de marketing.</li>
          </ul>
          <p className="mt-3">
            Esses cookies são estabelecidos por domínios diferentes do nosso e, portanto, são considerados cookies de terceiros. Não temos controle sobre como esses terceiros usam seus dados.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            4. Cookies que Utilizamos
          </h2>
          <p>
            A seguir está uma lista dos principais cookies que utilizamos e o que fazemos com eles:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>
              <span className="font-medium">Session Cookie:</span> Armazena informações sobre sua sessão atual no AgendeDiva, como estado de login e preferências do site.
            </li>
            <li>
              <span className="font-medium">Preference Cookie:</span> Lembra suas preferências, como idioma e região, para melhorar sua experiência.
            </li>
            <li>
              <span className="font-medium">Analytics Cookie:</span> Coleta informações anônimas sobre como você usa o site para que possamos melhorá-lo.
            </li>
            <li>
              <span className="font-medium">Authentication Cookie:</span> Gerencia sua autenticação e mantém você conectado.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            5. Como Gerenciar Cookies
          </h2>
          <p>
            A maioria dos navegadores permite que você controle os cookies através das configurações de preferências. Você pode:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Bloquear todos os cookies;</li>
            <li>Permitir apenas cookies "confiáveis";</li>
            <li>Configurar o navegador para excluir cookies cada vez que você fecha o navegador;</li>
            <li>Navegar em modo "incógnito" ou "privado", que exclui cookies quando você fecha o navegador.</li>
          </ul>
          <p className="mt-3">
            Para saber mais sobre como gerenciar cookies no seu navegador, consulte a documentação específica:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>
              <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer" className="text-diva-pink hover:underline">Google Chrome</a>
            </li>
            <li>
              <a href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam" target="_blank" rel="noopener noreferrer" className="text-diva-pink hover:underline">Mozilla Firefox</a>
            </li>
            <li>
              <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer" className="text-diva-pink hover:underline">Safari</a>
            </li>
            <li>
              <a href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener noreferrer" className="text-diva-pink hover:underline">Microsoft Edge</a>
            </li>
          </ul>
          <p className="mt-3">
            Note que restringir cookies pode impactar sua experiência e funcionalidade do AgendeDiva. Por exemplo, você pode não conseguir acessar áreas seguras do site ou suas preferências podem não ser salvas.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            6. Alterações na Política de Cookies
          </h2>
          <p>
            Podemos atualizar esta Política de Cookies periodicamente para refletir mudanças na forma como usamos cookies ou por outras razões operacionais, legais ou regulatórias. Quaisquer alterações serão publicadas nesta página com a data da "última atualização" atualizada.
          </p>
          <p className="mt-3">
            Recomendamos que você revise esta política regularmente para estar informado sobre como estamos usando cookies e tecnologias relacionadas.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            7. Contato
          </h2>
          <p>
            Se você tiver dúvidas sobre nossa Política de Cookies, entre em contato conosco através do e-mail <a href="mailto:contato@agendediva.com.br" className="text-diva-pink hover:underline">contato@agendediva.com.br</a>.
          </p>
        </section>
      </div>
    </LegalPageLayout>
  );
};

export default CookiePolicy; 