import React, { useState, useEffect } from 'react';
import { Calendar } from '@/components/ui/calendar';
import { ptBR } from 'date-fns/locale';
import { format, isSameDay } from 'date-fns';
import { Appointment } from '@/types/appointment';
import { appointmentService } from '@/services/appointmentService';
import { Loader2, Info, User, Clock, Calendar as CalendarIcon, CheckCircle, X, CheckCheck, Mail, MessageSquare } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';
import { Button } from './ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';
import { Badge } from './ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Avatar, AvatarFallback } from './ui/avatar';
import { toast } from 'sonner';
import { notificationService } from '@/services/notificationService';
import { NotificationDialog } from './NotificationDialog';
import { AppointmentCard } from './AppointmentCard';

interface AppointmentCalendarProps {
  onSelectDate?: (date: Date) => void;
}

export function AppointmentCalendar({ onSelectDate }: AppointmentCalendarProps) {
  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [pendingAppointments, setPendingAppointments] = useState<Appointment[]>([]);
  const [daysWithAppointments, setDaysWithAppointments] = useState<Date[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [monthAppointments, setMonthAppointments] = useState<Appointment[]>([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [dailySummary, setDailySummary] = useState<{
    total: number;
    pending: number;
    confirmed: number;
    cancelled: number;
    completed: number;
    revenue: number;
  }>({ total: 0, pending: 0, confirmed: 0, cancelled: 0, completed: 0, revenue: 0 });

  // Buscar todos os agendamentos pendentes
  useEffect(() => {
    if (!user) return;

    const fetchPendingAppointments = async () => {
      try {
        const pending = await appointmentService.getPendingAppointments(user.id);
        setPendingAppointments(pending);
      } catch (error) {
        console.error('Erro ao buscar agendamentos pendentes:', error);
        toast.error('Não foi possível carregar os agendamentos pendentes');
      }
    };

    fetchPendingAppointments();
  }, [user]);

  // Buscar agendamentos do mês atual
  useEffect(() => {
    if (!user) return;

    const currentMonth = selectedDate.getMonth();
    const currentYear = selectedDate.getFullYear();
    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);

    const fetchMonthAppointments = async () => {
      setIsLoading(true);
      try {
        // Idealmente, implementar um método para buscar agendamentos por período
        const allAppointments = await appointmentService.getAppointments(user.id);
        
        // Filtrar apenas os agendamentos do mês selecionado
        const appointmentsInMonth = allAppointments.filter(appointment => {
          const appointmentDate = new Date(appointment.date);
          return appointmentDate >= firstDay && appointmentDate <= lastDay;
        });
        
        setMonthAppointments(appointmentsInMonth);
        
        // Extrair dias distintos com agendamentos
        const uniqueDays = [...new Set(
          appointmentsInMonth.map(appointment => format(new Date(appointment.date), 'yyyy-MM-dd'))
        )].map(dateString => new Date(dateString));
        
        setDaysWithAppointments(uniqueDays);
      } catch (error) {
        console.error('Erro ao buscar agendamentos do mês:', error);
        toast.error('Não foi possível carregar seus agendamentos');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMonthAppointments();
  }, [user, selectedDate]);

  // Buscar agendamentos do dia selecionado
  useEffect(() => {
    if (!user || !selectedDate) return;

    const fetchDayAppointments = async () => {
      setIsLoading(true);
      try {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const dayAppointments = await appointmentService.getAppointmentsByDate(user.id, formattedDate);
        setAppointments(dayAppointments);
        
        // Calcular resumo diário
        const summary = {
          total: dayAppointments.length,
          pending: dayAppointments.filter(a => a.status === 'pending').length,
          confirmed: dayAppointments.filter(a => a.status === 'confirmed').length,
          cancelled: dayAppointments.filter(a => a.status === 'cancelled').length,
          completed: dayAppointments.filter(a => a.status === 'completed').length,
          revenue: dayAppointments.reduce((sum, app) => {
            // Somar receita apenas de agendamentos confirmados ou concluídos
            if (app.status === 'confirmed' || app.status === 'completed') {
              return sum + (app.service?.price || 0);
            }
            return sum;
          }, 0)
        };
        
        setDailySummary(summary);
        
        // Chamar callback se fornecido
        if (onSelectDate) {
          onSelectDate(selectedDate);
        }
      } catch (error) {
        console.error('Erro ao buscar agendamentos do dia:', error);
        toast.error('Não foi possível carregar seus agendamentos');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDayAppointments();
  }, [user, selectedDate, onSelectDate]);

  // Atualizar agendamentos pendentes quando houver mudanças
  useEffect(() => {
    if (!user) return;

    const updatePendingAppointments = async () => {
      try {
        const pending = await appointmentService.getPendingAppointments(user.id);
        setPendingAppointments(pending);
      } catch (error) {
        console.error('Erro ao atualizar agendamentos pendentes:', error);
      }
    };

    updatePendingAppointments();
  }, [user, appointments, monthAppointments]);

  // Função para renderizar conteúdo personalizado nos dias do calendário
  const renderDay = (day: Date) => {
    const isToday = isSameDay(day, new Date());
    const isSelected = selectedDate ? isSameDay(day, selectedDate) : false;
    
    // Verificar se há agendamentos neste dia
    const hasAppointments = daysWithAppointments.some(d => isSameDay(d, day));
    
    // Contar agendamentos por status
    const appointmentsOnThisDay = monthAppointments.filter(app => 
      isSameDay(new Date(app.date), day)
    );
    
    const pendingCount = appointmentsOnThisDay.filter(a => a.status === 'pending').length;
    const confirmedCount = appointmentsOnThisDay.filter(a => a.status === 'confirmed').length;
    
    return (
      <div className="relative w-full h-full flex flex-col items-center justify-center">
        <span 
          className={`
            relative z-10 flex items-center justify-center rounded-full w-8 h-8
            ${isSelected ? 'text-white' : isToday ? 'text-diva-pink font-bold' : 'text-gray-700'}
          `}
        >
          {day.getDate()}
        </span>
        
        {hasAppointments && (
          <div className="flex space-x-1 mt-1">
            {pendingCount > 0 && (
              <div className="w-1.5 h-1.5 rounded-full bg-yellow-500"></div>
            )}
            {confirmedCount > 0 && (
              <div className="w-1.5 h-1.5 rounded-full bg-green-500"></div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Atualizar status do agendamento
  const updateAppointmentStatus = async (id: string, status: 'confirmed' | 'cancelled' | 'completed') => {
    try {
      await appointmentService.updateAppointmentStatus(id, status);
      
      // Buscar o agendamento atualizado para ter os dados completos
      const updatedAppointment = appointments.find(app => app.id === id) || 
                                pendingAppointments.find(app => app.id === id);
      
      // Atualizar a lista de agendamentos do dia
      setAppointments(prev => 
        prev.map(app => app.id === id ? { ...app, status } : app)
      );

      // Atualizar a lista de agendamentos pendentes
      setPendingAppointments(prev => prev.filter(app => app.id !== id));
      
      // Se o status for "confirmado", abrir dialog para notificações
      if (status === 'confirmed' && updatedAppointment) {
        setSelectedAppointment(updatedAppointment);
        setNotificationOpen(true);
      }
      
      toast.success(`Agendamento ${
        status === 'confirmed' ? 'confirmado' : 
        status === 'cancelled' ? 'cancelado' : 'concluído'
      } com sucesso!`);

      // Atualizar os agendamentos do mês
      setMonthAppointments(prev => 
        prev.map(app => app.id === id ? { ...app, status } : app)
      );

    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error('Erro ao atualizar o agendamento');
    }
  };

  // Filtrar agendamentos com base no status selecionado
  const filteredAppointments = () => {
    if (statusFilter === 'all') {
      return appointments;
    }
    return appointments.filter(app => app.status === statusFilter);
  };

  return (
    <div className="w-full space-y-4">
      {/* Cabeçalho com Filtros */}
      <div className="bg-white rounded-lg shadow-sm p-4 flex flex-col sm:flex-row gap-3 items-center justify-between">
        <div className="flex items-center gap-2 w-full sm:w-auto">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => setStatusFilter('all')}
            className={statusFilter === 'all' ? 'bg-gray-100' : ''}
          >
            Todos
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => setStatusFilter('pending')}
            className={statusFilter === 'pending' ? 'bg-yellow-100 text-yellow-800' : ''}
          >
            <span className="w-1.5 h-1.5 rounded-full bg-yellow-500 mr-1.5"></span>
            Pendentes
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => setStatusFilter('confirmed')}
            className={statusFilter === 'confirmed' ? 'bg-green-100 text-green-800' : ''}
          >
            <span className="w-1.5 h-1.5 rounded-full bg-green-500 mr-1.5"></span>
            Confirmados
          </Button>
        </div>

        {/* Resumo do Dia - Visível apenas em desktop */}
        <div className="hidden lg:flex items-center gap-4">
          <div className="flex items-center gap-2">
            <div className="text-sm text-gray-500">Total:</div>
            <div className="font-medium">{dailySummary.total}</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-sm text-gray-500">Receita:</div>
            <div className="font-medium text-green-600">
              {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dailySummary.revenue)}
            </div>
          </div>
        </div>
      </div>

      {/* Seção de Agendamentos Pendentes */}
      {pendingAppointments.length > 0 && (
        <div className="bg-white rounded-lg shadow-sm border border-yellow-200 p-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Badge variant="outline" className="bg-yellow-100 text-yellow-800 border-yellow-200">
                <span className="w-1.5 h-1.5 rounded-full bg-yellow-500 mr-1.5"></span>
                Agendamentos Pendentes
              </Badge>
              <span className="ml-2 text-sm text-gray-500">
                {pendingAppointments.length} {pendingAppointments.length === 1 ? 'agendamento' : 'agendamentos'} aguardando confirmação
              </span>
            </div>
          </div>
          
          <div className="space-y-3">
            {pendingAppointments.map(appointment => (
              <AppointmentCard
                key={appointment.id}
                appointment={appointment}
                onUpdateStatus={updateAppointmentStatus}
              />
            ))}
          </div>
        </div>
      )}

      {/* Layout Principal */}
      <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
        {/* Calendário - Ocupa toda a largura em mobile */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <Calendar
              mode="single"
              selected={selectedDate}
              onSelect={(date) => date && setSelectedDate(date)}
              locale={ptBR}
              className="rounded-md"
              components={{
                Day: ({ date }) => renderDay(date)
              }}
              classNames={{
                day_selected: "bg-diva-pink hover:bg-diva-pink",
                day_today: "bg-diva-pink/10 text-diva-pink font-bold",
                cell: "h-9 w-9 text-center p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
                day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
                nav_button: "border border-input bg-background hover:bg-accent hover:text-accent-foreground h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
                nav_button_previous: "absolute left-1",
                nav_button_next: "absolute right-1",
                caption: "relative flex items-center justify-center pt-1 px-8"
              }}
            />

            {/* Legenda do Calendário */}
            <div className="mt-4 pt-3 border-t border-gray-100 flex justify-center space-x-6">
              <div className="flex items-center">
                <div className="w-2 h-2 rounded-full bg-yellow-500 mr-1.5"></div>
                <span className="text-xs text-gray-600">Pendentes</span>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 rounded-full bg-green-500 mr-1.5"></div>
                <span className="text-xs text-gray-600">Confirmados</span>
              </div>
            </div>
          </div>
        </div>

        {/* Lista de Agendamentos */}
        <div className="lg:col-span-5">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Agendamentos de {format(selectedDate, "dd 'de' MMMM", { locale: ptBR })}
              </h3>
            </div>

            {isLoading ? (
              <div className="flex items-center justify-center h-32">
                <Loader2 className="h-8 w-8 animate-spin text-diva-pink" />
              </div>
            ) : filteredAppointments().length > 0 ? (
              <div className="space-y-3 max-h-[calc(100vh-400px)] overflow-y-auto">
                {filteredAppointments()
                  .sort((a, b) => a.time_slot.localeCompare(b.time_slot))
                  .map(appointment => (
                    <AppointmentCard
                      key={appointment.id}
                      appointment={appointment}
                      onUpdateStatus={updateAppointmentStatus}
                    />
                  ))
                }
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-32 text-gray-500">
                <Info className="h-8 w-8 mb-2 text-gray-400" />
                <p className="text-center">Nenhum agendamento encontrado para esta data.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Dialog de Notificação */}
      {selectedAppointment && (
        <NotificationDialog
          open={notificationOpen}
          onOpenChange={setNotificationOpen}
          appointment={selectedAppointment}
        />
      )}
    </div>
  );
} 