import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Tutorials = () => {
  const [selectedCategory, setSelectedCategory] = useState('iniciantes');
  
  const categories = [
    { id: 'iniciantes', name: 'Para Iniciantes' },
    { id: 'agenda', name: 'Sistema de Agenda' },
    { id: 'clientes', name: 'Gestão de Clientes' },
    { id: 'financeiro', name: 'Controle Financeiro' },
    { id: 'personalizar', name: 'Personalizando seu Link' }
  ];
  
  const tutorialVideos = {
    iniciantes: [
      {
        id: 1,
        title: 'Primeiros passos com o AgendeDiva',
        description: 'Aprenda como criar sua conta e configurar seu perfil profissional no AgendeDiva.',
        duration: '3:45',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder1'
      },
      {
        id: 2,
        title: 'Adicionando seus serviços',
        description: 'Saiba como cadastrar os serviços que você oferece, definir preços e duração.',
        duration: '4:20',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder2'
      },
      {
        id: 3,
        title: 'Configurando sua disponibilidade',
        description: 'Aprenda a definir seus dias e horários de atendimento para agendamentos.',
        duration: '5:10',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder3'
      }
    ],
    agenda: [
      {
        id: 4,
        title: 'Como gerenciar agendamentos',
        description: 'Aprenda a criar, editar e excluir agendamentos manualmente ou através do link.',
        duration: '6:30',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder4'
      },
      {
        id: 5,
        title: 'Configurando notificações automáticas',
        description: 'Configure as mensagens automáticas que seus clientes receberão via WhatsApp.',
        duration: '4:15',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder5'
      },
      {
        id: 6,
        title: 'Gerenciando bloqueios e folgas',
        description: 'Saiba como bloquear horários para compromissos pessoais ou folgas.',
        duration: '3:50',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder6'
      }
    ],
    clientes: [
      {
        id: 7,
        title: 'Cadastrando clientes',
        description: 'Como adicionar e gerenciar suas informações de clientes no sistema.',
        duration: '4:40',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder7'
      },
      {
        id: 8,
        title: 'Histórico de atendimentos',
        description: 'Aprenda a visualizar e gerenciar o histórico de serviços de cada cliente.',
        duration: '3:25',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder8'
      },
      {
        id: 9,
        title: 'Enviando mensagens personalizadas',
        description: 'Como enviar mensagens e promoções para seus clientes diretamente pelo sistema.',
        duration: '5:35',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder9'
      }
    ],
    financeiro: [
      {
        id: 10,
        title: 'Controle financeiro básico',
        description: 'Aprenda a registrar suas receitas e despesas no sistema do AgendeDiva.',
        duration: '7:15',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder10'
      },
      {
        id: 11,
        title: 'Relatórios financeiros',
        description: 'Como gerar e interpretar relatórios de desempenho financeiro do seu negócio.',
        duration: '6:45',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder11'
      },
      {
        id: 12,
        title: 'Recebimentos e pagamentos online',
        description: 'Configure sua conta para receber pagamentos via PIX e cartão através do link.',
        duration: '8:20',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder12'
      }
    ],
    personalizar: [
      {
        id: 13,
        title: 'Personalizando seu link',
        description: 'Como personalizar a aparência do seu link de agendamento com suas cores e logo.',
        duration: '5:50',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder13'
      },
      {
        id: 14,
        title: 'Adicionando informações do seu negócio',
        description: 'Aprenda a adicionar descrição, fotos e localização ao seu perfil público.',
        duration: '4:30',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder14'
      },
      {
        id: 15,
        title: 'Divulgando seu link',
        description: 'Estratégias para compartilhar seu link de agendamento e conseguir mais clientes.',
        duration: '6:15',
        thumbnail: '/video-placeholder.svg',
        videoEmbedId: 'placeholder15'
      }
    ]
  };
  
  // Função para renderizar o player de vídeo (usando a imagem de placeholder)
  const renderVideoPlayer = (videoId: string) => {
    return (
      <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
        <img 
          src="/video-placeholder.svg" 
          alt="Thumbnail de vídeo tutorial" 
          className="w-full h-full object-cover"
        />
      </div>
    );
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <div className="container mx-auto px-4">
          {/* Header com gradiente */}
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
              Tutoriais AgendeDiva
            </h1>
            <p className="text-lg text-gray-600 max-w-xl mx-auto">
              Aprenda a utilizar todos os recursos do AgendeDiva com nossos vídeos tutoriais.
            </p>
          </div>
          
          {/* Categorias de tutoriais */}
          <div className="flex flex-wrap justify-center gap-2 md:gap-4 mb-10">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-4 py-2 md:px-6 md:py-2 rounded-full text-sm md:text-base font-medium transition-all ${
                  selectedCategory === category.id
                    ? 'bg-gradient-to-r from-diva-pink to-diva-purple text-white shadow-md'
                    : 'bg-white text-gray-700 hover:bg-gray-100 shadow-sm'
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
          
          {/* Grid de vídeos */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {tutorialVideos[selectedCategory as keyof typeof tutorialVideos].map((video) => (
              <div key={video.id} className="bg-white rounded-xl shadow-md overflow-hidden transition-all hover:shadow-lg">
                <div className="relative">
                  {renderVideoPlayer(video.videoEmbedId)}
                  <div className="absolute bottom-2 right-2 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded">
                    {video.duration}
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-medium text-diva-dark mb-2">{video.title}</h3>
                  <p className="text-gray-600 text-sm mb-3">{video.description}</p>
                  <button className="w-full py-2 bg-gradient-to-r from-diva-pink to-diva-purple text-white text-sm rounded-md transition-all hover:opacity-90">
                    Assistir Tutorial
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          {/* Seção de recursos adicionais */}
          <div className="mt-16 bg-white rounded-xl shadow-md overflow-hidden">
            <div className="h-2 bg-gradient-to-r from-diva-pink to-diva-purple"></div>
            <div className="p-6 md:p-8">
              <h2 className="text-2xl font-bold text-center text-diva-dark mb-6">
                Recursos Adicionais
              </h2>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="text-center p-4 border border-gray-100 rounded-lg">
                  <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-diva-purple" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-diva-dark mb-2">
                    Guias Passo a Passo
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Documentação detalhada com instruções passo a passo para todas as funcionalidades.
                  </p>
                  <button className="mt-4 px-4 py-2 border border-diva-purple text-diva-purple text-sm rounded-md hover:bg-purple-50 transition-colors">
                    Acessar Guias
                  </button>
                </div>
                
                <div className="text-center p-4 border border-gray-100 rounded-lg">
                  <div className="w-16 h-16 bg-pink-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-diva-pink" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-diva-dark mb-2">
                    Perguntas Frequentes
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Respostas para as dúvidas mais comuns sobre a plataforma AgendeDiva.
                  </p>
                  <button 
                    onClick={() => window.location.href = '/support'}
                    className="mt-4 px-4 py-2 border border-diva-pink text-diva-pink text-sm rounded-md hover:bg-pink-50 transition-colors"
                  >
                    Ver Perguntas
                  </button>
                </div>
                
                <div className="text-center p-4 border border-gray-100 rounded-lg">
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-diva-dark mb-2">
                    Suporte Personalizado
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Precisa de ajuda específica? Nossa equipe está pronta para te ajudar.
                  </p>
                  <button 
                    onClick={() => window.location.href = '/support#contact'}
                    className="mt-4 px-4 py-2 border border-blue-500 text-blue-500 text-sm rounded-md hover:bg-blue-50 transition-colors"
                  >
                    Contatar Suporte
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Tutorials; 