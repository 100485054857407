import { useEffect, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { appointmentService } from '@/services/appointmentService';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import {
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from '@/components/ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Calendar,
  Search,
  Plus,
  List,
  PlusCircle,
  Settings,
  Clock,
  CheckCircle,
  Users,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { toast } from '@/components/ui/use-toast';
import { Link } from 'react-router-dom';
import { AppointmentCalendar } from '@/components/AppointmentCalendar';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Appointment } from '@/types/appointment';

interface UserMetadata {
  name?: string;
  email?: string;
  avatar_url?: string;
}

export default function Appointments() {
  const { user } = useAuth();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('calendar');
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    if (user) {
      // Carregar agendamentos
      const loadAppointments = async () => {
        try {
          const data = await appointmentService.getAppointments(user.id);
          setAppointments(data);
        } catch (error) {
          console.error('Erro ao carregar agendamentos:', error);
          toast({
            title: 'Erro ao carregar agendamentos',
            description: 'Não foi possível carregar seus agendamentos. Tente novamente.',
            variant: 'destructive',
          });
        }
      };
      
      loadAppointments();
    }
  }, [user]);
  
  // Calcular estatísticas
  const getTodayAppointments = () => {
    const today = new Date().toISOString().split('T')[0];
    return appointments.filter(app => app.date === today).length;
  };
  
  const getNextAppointment = () => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];
    const currentTime = format(now, 'HH:mm');
    
    const todayUpcomingAppointments = appointments
      .filter(app => app.date === today && app.time_slot > currentTime && app.status !== 'cancelled')
      .sort((a, b) => a.time_slot.localeCompare(b.time_slot));
      
    if (todayUpcomingAppointments.length > 0) {
      return todayUpcomingAppointments[0].time_slot;
    }
    
    return '--:--';
  };
  
  const getUpcomingAppointments = () => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    
    return appointments.filter(app => {
      // Agendamentos futuros (hoje ou data posterior) e não cancelados
      return (app.date > formattedToday || 
              (app.date === formattedToday && app.time_slot > format(today, 'HH:mm'))) &&
              app.status !== 'cancelled';
    }).length;
  };
  
  // Filtrar agendamentos para a tabela
  const getFilteredAppointments = () => {
    let filtered = [...appointments];
    
    // Aplicar filtro de busca
    if (searchTerm) {
      const search = searchTerm.toLowerCase();
      filtered = filtered.filter(app => 
        app.client_name?.toLowerCase().includes(search) ||
        app.client_phone?.toLowerCase().includes(search) ||
        app.service?.name?.toLowerCase().includes(search)
      );
    }
    
    // Aplicar filtro de status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(app => app.status === filterStatus);
    }
    
    // Ordenar por data e hora
    filtered.sort((a, b) => {
      const dateComparison = a.date.localeCompare(b.date);
      if (dateComparison !== 0) return dateComparison;
      return a.time_slot.localeCompare(b.time_slot);
    });
    
    return filtered;
  };
  
  // Renderizar visualização de calendário
  const renderCalendarView = () => (
    <div className="bg-white border border-gray-100 shadow-sm rounded-lg">
      <AppointmentCalendar />
    </div>
  );
  
  // Renderizar visualização de lista
  const renderListView = () => (
    <>
      {/* Cards de estatísticas */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        <Card className="bg-gradient-to-br from-diva-pink/10 to-diva-purple/10 shadow-sm border-0 overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40"></div>
          <CardContent className="p-6 relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-diva-purple font-medium text-sm">Agendamentos hoje</h3>
              <Clock className="h-5 w-5 text-diva-pink opacity-80" />
            </div>
            <p className="text-4xl font-bold bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
              {getTodayAppointments()}
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gradient-to-br from-diva-pink/10 to-diva-purple/10 shadow-sm border-0 overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40"></div>
          <CardContent className="p-6 relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-diva-purple font-medium text-sm">Próximo horário</h3>
              <Clock className="h-5 w-5 text-diva-pink opacity-80" />
            </div>
            <p className="text-4xl font-bold bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
              {getNextAppointment()}
            </p>
          </CardContent>
        </Card>
        
        <Card className="bg-gradient-to-br from-diva-pink/10 to-diva-purple/10 shadow-sm border-0 overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40"></div>
          <CardContent className="p-6 relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-diva-purple font-medium text-sm">Agendamentos futuros</h3>
              <Users className="h-5 w-5 text-diva-pink opacity-80" />
            </div>
            <p className="text-4xl font-bold bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent">
              {getUpcomingAppointments()}
            </p>
          </CardContent>
        </Card>
      </div>
      
      {/* Filtros */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-diva-mid h-4 w-4" />
          <Input
            type="text"
            placeholder="Buscar agendamentos..."
            className="pl-10 border-diva-pink/20 focus:border-diva-pink"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <Select value={filterStatus} onValueChange={setFilterStatus}>
          <SelectTrigger className="w-full sm:w-40 border-diva-pink/20">
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">Todos</SelectItem>
            <SelectItem value="pending">Pendentes</SelectItem>
            <SelectItem value="confirmed">Confirmados</SelectItem>
            <SelectItem value="cancelled">Cancelados</SelectItem>
            <SelectItem value="completed">Concluídos</SelectItem>
          </SelectContent>
        </Select>
      </div>
      
      {/* Tabela de agendamentos */}
      <Card className="border border-gray-100 shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader className="bg-gradient-to-r from-diva-pink/5 to-diva-purple/5">
              <TableRow>
                <TableHead className="text-diva-dark font-medium">Data</TableHead>
                <TableHead className="text-diva-dark font-medium">Horário</TableHead>
                <TableHead className="text-diva-dark font-medium">Cliente</TableHead>
                <TableHead className="text-diva-dark font-medium">Serviço</TableHead>
                <TableHead className="text-diva-dark font-medium">Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getFilteredAppointments().length > 0 ? (
                getFilteredAppointments().map((appointment) => (
                  <TableRow key={appointment.id} className="hover:bg-gray-50">
                    <TableCell>
                      {format(new Date(appointment.date), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        <Clock className="h-3 w-3 mr-1.5 text-diva-pink" />
                        {appointment.time_slot}
                      </div>
                    </TableCell>
                    <TableCell className="font-medium text-gray-700">{appointment.client_name}</TableCell>
                    <TableCell>{appointment.service?.name || 'Não especificado'}</TableCell>
                    <TableCell>
                      <span className={cn(
                        "text-xs px-2.5 py-1 rounded-full flex items-center w-fit",
                        appointment.status === 'pending' && "bg-yellow-100 text-yellow-800 border border-yellow-200",
                        appointment.status === 'confirmed' && "bg-green-100 text-green-800 border border-green-200",
                        appointment.status === 'cancelled' && "bg-gray-100 text-gray-800 border border-gray-200",
                        appointment.status === 'completed' && "bg-blue-100 text-blue-800 border border-blue-200",
                      )}>
                        {appointment.status === 'pending' && (
                          <>
                            <span className="w-1.5 h-1.5 rounded-full bg-yellow-500 mr-1.5"></span>
                            Pendente
                          </>
                        )}
                        {appointment.status === 'confirmed' && (
                          <>
                            <span className="w-1.5 h-1.5 rounded-full bg-green-500 mr-1.5"></span>
                            Confirmado
                          </>
                        )}
                        {appointment.status === 'cancelled' && (
                          <>
                            <span className="w-1.5 h-1.5 rounded-full bg-gray-500 mr-1.5"></span>
                            Cancelado
                          </>
                        )}
                        {appointment.status === 'completed' && (
                          <>
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mr-1.5"></span>
                            Concluído
                          </>
                        )}
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-8 text-gray-500">
                    Nenhum agendamento encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Card>
    </>
  );

  return (
    <div className="container mx-auto py-8 px-4 space-y-8">
      {/* Cabeçalho */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 sm:mb-6">
        <h1 className="text-2xl font-bold tracking-tight bg-gradient-to-r from-diva-pink to-diva-purple bg-clip-text text-transparent mb-2 sm:mb-0">
          Agenda
        </h1>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-3">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => setActiveTab('list')} 
            className={activeTab === 'list' ? 'bg-diva-pink/10 border-diva-pink/30 text-diva-pink' : 'border-diva-pink/20 hover:bg-diva-pink/5 hover:text-diva-pink hover:border-diva-pink/30'}
          >
            <List className="h-4 w-4 mr-2" />
            Lista
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => setActiveTab('calendar')} 
            className={activeTab === 'calendar' ? 'bg-diva-pink/10 border-diva-pink/30 text-diva-pink' : 'border-diva-pink/20 hover:bg-diva-pink/5 hover:text-diva-pink hover:border-diva-pink/30'}
          >
            <Calendar className="h-4 w-4 mr-2" />
            Calendário
          </Button>
        </div>
      </div>

      {/* Conteúdo da Aba Selecionada */}
      {activeTab === 'calendar' ? renderCalendarView() : renderListView()}
    </div>
  );
} 