import React from 'react';
import LegalPageLayout from '@/components/LegalPageLayout';

const PrivacyPolicy = () => {
  return (
    <LegalPageLayout title="Política de Privacidade" lastUpdated="5 de Abril de 2024">
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            1. Introdução
          </h2>
          <p>
            O AgendeDiva está comprometido em proteger sua privacidade. Esta Política de Privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações pessoais quando você utiliza nossa plataforma.
          </p>
          <p className="mt-3">
            Ao utilizar o AgendeDiva, você concorda com as práticas descritas nesta política. Recomendamos que você leia este documento na íntegra para entender nossos procedimentos relacionados aos seus dados pessoais.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            2. Informações que Coletamos
          </h2>
          <p>
            Coletamos diferentes tipos de informações para fornecer e melhorar nossos serviços:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>
              <span className="font-medium">Informações de Registro:</span> Nome completo, endereço de e-mail, número de telefone, senha (armazenada de forma criptografada).
            </li>
            <li>
              <span className="font-medium">Informações do Negócio:</span> Nome do negócio, categoria de serviços, endereço comercial, horário de funcionamento, descrição, logo.
            </li>
            <li>
              <span className="font-medium">Informações de Clientes:</span> Dados dos clientes cadastrados por você, como nome, e-mail, telefone e histórico de serviços.
            </li>
            <li>
              <span className="font-medium">Informações de Pagamento:</span> Registros de transações processadas através da plataforma (não armazenamos dados completos de cartões de crédito).
            </li>
            <li>
              <span className="font-medium">Informações de Uso:</span> Dados sobre como você interage com nossa plataforma, incluindo acesso, horários, funcionalidades utilizadas e dispositivos.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            3. Como Usamos suas Informações
          </h2>
          <p>
            Utilizamos as informações coletadas para os seguintes propósitos:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Fornecer, manter e melhorar nossos serviços;</li>
            <li>Processar transações e enviar notificações relacionadas à sua conta;</li>
            <li>Personalizar sua experiência e fornecer conteúdo relevante;</li>
            <li>Analisar como os usuários utilizam a plataforma para aprimorar nossas funcionalidades;</li>
            <li>Comunicar-se com você sobre atualizações, ofertas e informações relacionadas aos serviços;</li>
            <li>Detectar, prevenir e resolver problemas técnicos, fraudes ou atividades ilegais;</li>
            <li>Cumprir obrigações legais e regulatórias.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            4. Compartilhamento de Informações
          </h2>
          <p>
            O AgendeDiva não vende, aluga ou compartilha suas informações pessoais com terceiros para fins de marketing. Podemos compartilhar suas informações nas seguintes circunstâncias:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>
              <span className="font-medium">Prestadores de Serviços:</span> Trabalhamos com empresas terceirizadas para nos ajudar a operar, melhorar e fornecer nossos serviços (como processadores de pagamento e serviços de hospedagem).
            </li>
            <li>
              <span className="font-medium">Requisitos Legais:</span> Quando acreditamos de boa-fé que o acesso, uso, preservação ou divulgação das informações é necessário para cumprir uma obrigação legal, processo legal ou solicitação governamental.
            </li>
            <li>
              <span className="font-medium">Proteção de Direitos:</span> Para proteger os direitos, a propriedade ou a segurança do AgendeDiva, de nossos usuários ou do público em geral.
            </li>
            <li>
              <span className="font-medium">Com seu Consentimento:</span> Em outras circunstâncias, quando tivermos seu consentimento explícito para compartilhar informações.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            5. Segurança das Informações
          </h2>
          <p>
            Implementamos medidas técnicas e organizacionais apropriadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. Essas medidas incluem:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Criptografia de dados sensíveis, incluindo senhas e informações de pagamento;</li>
            <li>Acesso restrito a informações pessoais apenas a funcionários autorizados;</li>
            <li>Revisões regulares de nossas práticas de coleta, armazenamento e processamento;</li>
            <li>Proteções físicas e digitais para salvaguardar os servidores onde as informações são armazenadas.</li>
          </ul>
          <p className="mt-3">
            Embora nos esforcemos para proteger suas informações, nenhum método de transmissão pela internet ou método de armazenamento eletrônico é 100% seguro. Portanto, não podemos garantir sua segurança absoluta.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            6. Seus Direitos de Privacidade
          </h2>
          <p>
            Dependendo de sua localização, você pode ter os seguintes direitos relacionados aos seus dados pessoais:
          </p>
          <ul className="list-disc pl-6 space-y-2 mt-2">
            <li>Acessar e obter uma cópia das suas informações pessoais;</li>
            <li>Corrigir informações inexatas ou incompletas;</li>
            <li>Excluir suas informações pessoais em determinadas circunstâncias;</li>
            <li>Restringir ou opor-se ao processamento de seus dados;</li>
            <li>Transferir suas informações para outro serviço (portabilidade);</li>
            <li>Retirar o consentimento a qualquer momento para processamentos baseados em consentimento.</li>
          </ul>
          <p className="mt-3">
            Para exercer qualquer um desses direitos, entre em contato conosco através do e-mail <a href="mailto:contato@agendediva.com.br" className="text-diva-pink hover:underline">contato@agendediva.com.br</a>. Atenderemos sua solicitação de acordo com as leis aplicáveis.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            7. Retenção de Dados
          </h2>
          <p>
            Manteremos suas informações pessoais pelo tempo necessário para cumprir os propósitos descritos nesta política, a menos que seja exigido ou permitido por lei um período de retenção mais longo. Quando não tivermos mais necessidade legítima de processar suas informações, nós as excluiremos ou anonimizaremos.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            8. Proteção de Dados de Menores
          </h2>
          <p>
            O AgendeDiva não se destina a pessoas menores de 18 anos. Não coletamos intencionalmente informações pessoais de crianças. Se você é pai, mãe ou responsável legal e acredita que seu filho nos forneceu informações pessoais, entre em contato conosco para que possamos tomar as medidas apropriadas.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            9. Alterações nesta Política
          </h2>
          <p>
            Podemos atualizar nossa Política de Privacidade periodicamente. Publicaremos quaisquer alterações em nosso site e atualizaremos a data da "última atualização" no topo desta página. Recomendamos que você revise esta política regularmente para estar ciente de quaisquer alterações.
          </p>
          <p className="mt-3">
            Alterações significativas serão notificadas por e-mail ou por um aviso em nosso site antes que as alterações entrem em vigor.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-diva-pink to-diva-purple mb-4">
            10. Contato
          </h2>
          <p>
            Se você tiver dúvidas, preocupações ou solicitações relacionadas a esta Política de Privacidade ou ao tratamento de seus dados pessoais, entre em contato conosco pelo e-mail <a href="mailto:contato@agendediva.com.br" className="text-diva-pink hover:underline">contato@agendediva.com.br</a>.
          </p>
        </section>
      </div>
    </LegalPageLayout>
  );
};

export default PrivacyPolicy; 