import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Formata um número de telefone para o padrão brasileiro (21) 99999-9999
 * @param value Valor do telefone a ser formatado
 * @returns Telefone formatado
 */
export function formatPhoneNumber(value: string): string {
  // Remove tudo que não for número
  const numbers = value.replace(/\D/g, '');
  
  if (!numbers) return '';
  
  // Aplica a máscara conforme os dígitos vão sendo inseridos
  if (numbers.length <= 2) {
    return `(${numbers}`;
  } else if (numbers.length <= 3) {
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
  } else if (numbers.length <= 7) {
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)} ${numbers.slice(3)}`;
  } else if (numbers.length <= 11) {
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)} ${numbers.slice(3, 7)}-${numbers.slice(7)}`;
  } else {
    // Limita a 11 dígitos (DDD + 9 dígitos)
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 3)} ${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`;
  }
}
